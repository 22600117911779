.custom-width {
  /* max-width: 1100px !important; */
  /* max-width: 60% !important; */
  /* width: 1060px !important; */
  /* min-width: 1100px !important; */
  min-width: 430px !important;
}
/* CustomModal.css */
#fixedModelHeight {
  /* height: 533px !important; */
  /* overflow: scroll;
  width: 100%; */
  /* width: 1100px !important; */
  width: auto;
  padding: 5px;
}

.loginform-password {
  padding-right: 30px !important;
}
.loginform-password::-ms-reveal,
.loginform-password::-ms-clear {
  display: none;
}
.loginform-showpassword {
  position: absolute;
  background-color: transparent;
  border: none;
  right: 3.4375rem;
  top: 10px;
  z-index: 10;
}

.custom-stepper {
  margin-left: -12%;
  margin-right: -12%;
}

.custom-stepper-body {
  padding-left: 3% !important;
  padding-right: 3% !important;
}

.custom-modal-header {
  padding-left: 3% !important;
  padding-right: 3% !important;
}

.custom-modal-footer {
  padding-left: 3% !important;
  padding-right: 2% !important;
}

.forgot-password {
  color: rgb(0, 63, 153);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  background: transparent;
}

.forgot-password:hover {
  text-decoration: none;
  border-bottom-color: transparent;
}

.forgot-password:active,
.forgot-password:focus {
  outline: none;
  position: relative;
  text-decoration: none;
  border-bottom-color: transparent;
}
