.favorite-card {
  margin-top: 20px;
  width: 100%;
  padding: 10px !important;
}
#favorite-card-id {
  height: auto; /* Allow content to display fully by default */
  overflow: hidden;
  transition: height 0.3s ease; /* Smooth height transition */
}

#favorite-card-id:not(.expanded) {
  height: 0; /* Collapse when not expanded */
}

.favorite-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  padding: 10px;
  margin-bottom: 16px !important;
  border-bottom: 1px solid #e9e9f2;
  margin: 0px 0px;
  margin-left: -6px !important;
}

.favorite-card .card-header-icon {
  font-size: 1.5625rem !important;
}

.favorite-card-content {
  padding: 0px !important;
  margin: 0px !important;
  margin-left: -20px !important;
}
.favorite-content-row {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0px 15px 15px 15px;
  font-size: 1rem;
  /* margin: 8px 0px; */
  color: #545353;
}
.favorite-content-row:hover {
  color: #fc4c02 !important;
}
/* dialog */
#FavoriteDlg .e-dlg-header-content {
  margin: 20px 32px !important;
  border-bottom: 1px solid #e9e9f2 !important;
  border-radius: 0px !important;
  margin-bottom: 0px !important;
}
#FavoriteDlg .e-dlg-header-content + .e-dlg-content {
  padding: 24px !important;
}

#FavoriteDlg .e-dlg-content {
  display: flex;
  overflow: auto;
}

#FavoriteDlg .dlg-headerIcon {
  font-size: 22px;
  color: #535b86;
  position: relative;
  top: 2px;
  margin-right: 8px;
}
#FavoriteDlg .e-dlg-header {
  font-weight: 500 !important;
}

#FavoriteDlg {
  max-width: 980px;
  overflow: auto !important;
  max-height: 730px !important;
}
#FavoriteDlg .fa-plane-up {
  transform: rotate(51.02deg);
}
