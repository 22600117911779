#grid-employeebanklist_gridcontrol {
  border-width: 0px;
}

.bank-editbtn .identity-dlg-btn.e-btn:active,
.bank-deletebtn .identity-dlg-btn.e-btn:active {
  border: none !important;
  background: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.12) !important;
}

.identity-dlg-btn-editbtn,
.identity-dlg-btn-deletebtn {
  margin-right: 10px;
  color: #fc4c02;
}

.identity-dlg-btn:hover .identity-dlg-btn-editbtn,
.identity-dlg-btn:hover .identity-dlg-btn-deletebtn {
  color: #fefefe;
}

.identity-dlg-btn[disabled]:hover {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26) !important;
}

.identity-dlg-btn[disabled]:hover .identity-dlg-btn-editbtn,
.identity-dlg-btn[disabled]:hover .identity-dlg-btn-deletebtn {
  color: #fc4c02 !important;
}
/* Suhail - layout fix - 20250203 - start */
#empbanktree {
  height: 100%;
  display: flex;
  flex-direction: column;
}
/* Suhail - layout fix - 20250203 - end */
#empbanktree .e-toolbar-items .bank-list-start {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

#empbanktree .e-toolbar-items .bank-list-start .bank-btn {
  display: flex;
  gap: 12px;
}

#empbanktree .e-toolbar-items .bank-list-start .bank-btn .seperator-bank {
  height: 30px;
  border: 1px solid #e9e9f2;
  margin-top: 5px;
  opacity: 0.7;
}

#empbanktree .e-toolbar-items .bank-list-start .bank-btn .bank-filterItem {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

#empbanktree
  .e-toolbar-items
  .bank-list-start
  .bank-btn
  .bank-filterItem
  .e-input-group.e-control-wrapper {
  height: 24px;
  width: 105px !important;
  border-bottom: none;
  color: #363636;
  font-weight: 500;
}

#grid-employeebanklist .e-gridheader {
  margin-top: 10px;
  color: #fc4c01;
  border-top: 0px;
  font-size: 1rem !important;
}

#grid-employeebanklist .e-rowcell {
  padding: 8px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  font-size: 0.875rem !important;
}

#grid-employeebanklist .e-headercelldiv {
  font-size: 0.875rem !important;
}

#grid-employeebanklist .e-gridheader .e-headercontent .e-columnheader {
  position: relative;
  left: 10px;
}

#grid-employeebanklist
  .e-gridheader
  .e-headercontent
  #grid-employeebanklist_gridcontrolcolgroup
  .e-drag-intent {
  width: 13px !important;
}

#grid-employeebanklist
  .e-gridcontent
  .e-content
  #content-grid-employeebanklist_gridcontrolcolgroup
  .e-drag-intent {
  width: 13px !important;
}

#grid-employeebanklist .e-gridcontent .e-content {
  padding: 0px !important;
  overflow: auto;
  scrollbar-width: thin !important;
}

#grid-employeebanklist .e-gridcontent .e-content tbody tr {
  height: 48px;
}

#grid-employeebanklist .e-gridcontent .e-content tbody tr[aria-expanded] {
  /* height: 67px; */
  position: relative;
  right: 10px;
  background: #fff9f6;
}

#grid-employeebanklist
  .e-gridcontent
  .e-content
  tbody
  tr[aria-expanded='true']
  td
  .e-treecolumn-container
  .e-treecell {
  color: #fc4c01;
  font-weight: 500 !important;
}

#grid-employeebanklist
  .e-gridcontent
  .e-content
  tbody
  tr[aria-expanded]
  td
  .e-icons.e-treegridcollapse {
  margin-right: 10px;
  /* transform: rotate(90deg); */
}

#grid-employeebanklist
  .e-gridcontent
  .e-content
  tbody
  tr[aria-expanded]
  td
  .e-icons.e-treegridexpand {
  margin-right: 10px;
  transform: rotate(180deg);
  color: #fc4c01;
}

#grid-employeebanklist
  .e-gridcontent
  .e-content
  tbody
  tr[aria-expanded]
  td
  .e-icons.e-treegridexpand:hover::before {
  color: #fc4c01;
}

#grid-employeebanklist
  .e-gridcontent
  .e-content
  tbody
  tr[aria-expanded]
  td
  .e-icons.e-treegridcollapse::before,
#grid-employeebanklist
  .e-gridcontent
  .e-content
  tbody
  tr[aria-expanded]
  td
  .e-icons.e-treegridexpand::before {
  content: '\e36a';
  font-size: 1rem;
}

#grid-employeebanklist
  .e-gridcontent
  .e-content
  tbody
  tr[aria-expanded]
  .e-rowdragdrop
  .e-icons.e-icon-rowdragicon {
  display: none !important;
}

#grid-employeebanklist
  .e-gridcontent
  .e-content
  tbody
  tr
  td
  div.e-icons.e-rowcelldrag.e-icon-rowdragicon:before {
  content: '' !important;
}

#grid-employeebanklist .e-gridcontent .e-content tbody tr td div.priorityVal {
  width: 21px;
  height: 21px;
  border: 1px solid #fc4c01;
  border-radius: 10px;
  background: #fff9f6;
  color: #fc4c01;
  font-weight: 500;
}

#grid-employeebanklist
  .e-gridcontent
  .e-content
  tr.e-row:not([aria-expanded])
  > td.e-rowdragdrop.e-dropbottom,
#grid-employeebanklist
  .e-gridcontent
  .e-content
  tr.e-row:not([aria-expanded]):has(td.e-rowdragdrop.e-dropbottom)
  td.e-rowcell {
  border-bottom: 1px solid #fc4c02 !important;
}

#grid-employeebanklist
  .e-gridcontent
  .e-content
  tr.e-row
  > td.e-rowdragdrop.e-childborder,
#grid-employeebanklist
  .e-gridcontent
  .e-content
  tr.e-row:has(td.e-rowdragdrop.e-childborder)
  td.e-rowcell {
  border-bottom: 1px solid #fc4c02 !important;
  border-top: 1px solid #fc4c02 !important;
}

#grid-employeebanklist
  .e-gridcontent
  .e-content
  tr.e-row:has(td.e-rowdragdrop.e-childborder)
  td.e-rowcell:last-child {
  border-right: 1px solid #fc4c01 !important;
}

#grid-employeebanklist
  .e-gridcontent
  .e-content
  tr.e-row
  > td.e-rowdragdrop.e-droptop,
#grid-employeebanklist
  .e-gridcontent
  .e-content
  tr.e-row:has(td.e-rowdragdrop.e-droptop)
  td.e-rowcell {
  border-top: 1px solid #fc4c01 !important;
}

#grid-employeebanklist
  #grid-employeebanklist_gridcontrol
  .e-cloneproperties.e-draganddrop
  tr
  td.e-rowdragdrop.e-rowdragdropcell
  div.e-icons.e-rowcelldrag::before {
  display: none !important;
}

#defaultDialog-employeebankadd .e-dlg-header-content {
  border-bottom: 1px solid #e9e9e9;
}

#defaultDialog-employeebankadd .e-dlg-header-content .e-dlg-header {
  display: flex;
  align-items: center;
}

#defaultDialog-employeebankadd
  .e-dlg-header-content
  .e-dlg-header
  .fa-building-columns.dlg-headerIcon {
  padding-bottom: 5px;
  color: #535b86;
}

#defaultDialog-employeebankadd .e-dlg-content {
  padding-top: 18px !important;
}

#defaultDialog-employeebankadd .e-footer-content .e-btn:active {
  border: none !important;
}

#defaultDialog-employeebankadd .e-footer-content .e-control.e-btn {
  height: 45px !important;
  border-radius: 50px;
  padding: 16px 20px !important;
  opacity: 1 !important;
  color: #000000 !important;
}

#defaultDialog-employeebankadd .e-footer-content .e-control.e-btn:hover {
  background: #e9e9f2 !important;
  color: #000000 !important;
}

#defaultDialog-employeebankadd .e-footer-content .e-control.e-btn.e-primary {
  background: #fc4c01 !important;
  color: #ffffff !important;
}

#defaultDialog-employeebankadd
  .e-footer-content
  .e-control.e-btn.e-primary:hover {
  box-shadow: 0px 5px 4px 0px #fc4c0152;
  background: #fc4c01 !important;
  color: #ffffff !important;
}

#defaultDialog-employeebankadd {
  min-height: 560px !important;
}

@media screen and (max-width: 1460px) {
  #defaultDialog-employeebankadd {
    width: 65vw !important;
    height: 29vh !important;
  }
}

@media screen and (max-width: 1240px) {
  #defaultDialog-employeebankadd {
    width: 65vw !important;
    height: 29vh !important;
  }
}

@media screen and (max-width: 1120px) {
  #defaultDialog-employeebankadd {
    width: 80vw !important;
    height: 29vh !important;
  }
}

@media screen and (max-width: 1024px) {
  #defaultDialog-employeebankadd {
    width: 75vw !important;
    height: 29vh !important;
  }
}

@media screen and (max-width: 768px) {
  #defaultDialog-employeebankadd {
    width: 80vw !important;
    height: 29vh !important;
  }
}
