.PeriodSelector {
  margin-left: unset;
  float: left !important;
  width: 100%;
}
/* Suhail - layout fix - 20250203 - start */
.emp_payheadgridparent {
  height: calc(100% - 40px);
}
/* Suhail - layout fix - 20250203 - end */
.e-grid .e-groupcaption {
  font-size: 0.875rem !important;
  font-weight: 500;
}
/* .e-grid td.e-selectionbackground {
    background-color: #00b7ea !important;
} */
#Emp_PayHeadsGrid .e-groupcaption {
  color: #363636 !important;
}
#Emp_PayHeadsGrid .e-groupcaption:hover {
  color: #fc4c02 !important;
} /* Default styles for collapsed rows */
#Emp_PayHeadsGrid .e-recordpluscollapse,
#Emp_PayHeadsGrid .e-groupcaption {
  background-color: white;
  border-color: #e9e9f2 !important;
}

/* Styles for expanded rows */
#Emp_PayHeadsGrid .e-recordplusexpand + .e-groupcaption,
#Emp_PayHeadsGrid .e-recordplusexpand + .e-groupcaption + td {
  background-color: #fff9f6;
  color: #fc4c02;
}
.e-grid .e-indentcell {
  background-color: transparent;
}

.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
#Emp_PayHeadsGrid_dialogEdit_wrapper {
  padding: 15px;
}
#Emp_PayHeadsGrid_dialogEdit_wrapper .e-dlg-header-content {
  margin: 0px 12px !important;
  border-bottom: 2px solid #e9e9f2 !important;
  border-radius: 0px !important;
  margin-bottom: 12px !important;
}

/* --------------------------------------------------------------------------------------------------------- */

#Search-Panel #selectorsearchmain {
  border: none !important;
}
#Emp_PayHeadsGrid {
  border-color: #fff !important;
  /* margin-bottom: 15px; */
  height: 100%;
  display: flex;
  flex-direction: column;
}
#Emp_PayHeadsGrid .e-toolbar {
  border-top: none !important;
  background-color: transparent !important;
  height: 0px !important;
  width: fit-content !important;
  border: 1px solid #fff;
}
/* Replace cross icon with "CLEAR" */

#Emp_PayHeadsGrid .e-input-group .e-clear-icon::before,
#Emp_PayHeadsGrid .e-input-group.e-control-wrapper .e-clear-icon::before {
  content: 'CLEAR' !important;
  display: inline-block !important;
  font-size: 0.875rem !important;
  color: #363636 !important;
  background: none !important;
  width: auto !important;
  height: auto !important;
  text-align: center !important;
  line-height: 1 !important;
  margin-right: 12px !important;
  margin-bottom: 2px !important;
}

#Emp_PayHeadsGrid .e-ddl.e-input-group input[readonly] ~ .e-clear-icon,
#Emp_PayHeadsGrid .e-float-input input[readonly] ~ .e-clear-icon,
#Emp_PayHeadsGrid .e-float-input.e-input-group input[readonly] ~ .e-clear-icon {
  background: none !important;
  width: auto !important;
  height: auto !important;
}
#Emp_PayHeadsGrid .e-table tr:hover .e-indentcell::before,
#Emp_PayHeadsGrid .e-table tr:hover .fa-trash,
#Emp_PayHeadsGrid td.e-active .fa-trash,
#Emp_PayHeadsGrid tr[aria-selected='true'] .e-indentcell::before {
  visibility: visible;
}
#Emp_PayHeadsGrid tr[aria-selected='true'] .e-indentcell {
  background: #fff9f6 !important;
}

#Emp_PayHeadsGrid .e-table tr:hover .e-indentcell {
  visibility: visible;
  background: #fff9f6 !important;
}

#Emp_PayHeadsGrid tr:hover .e-groupcaption,
#Emp_PayHeadsGrid tr:hover .e-groupcaption {
  background-color: #fff9f6 !important;
}

#Emp_PayHeadsGrid tr:hover td.e-recordpluscollapse,
#Emp_PayHeadsGrid tr:hover td.e-recordplusexpand {
  background-color: #fff9f6 !important;
}
#Emp_PayHeadsGrid tr:hover .e-icon-grightarrow::before,
#Emp_PayHeadsGrid tr:hover .e-icon-grightarrow::before {
  color: #fc4c02;
}
#Emp_PayHeadsGrid tr.e-row:hover {
  background-color: transparent !important;
}
#Emp_PayHeadsGrid .e-toolbar .e-toolbar-items .e-toolbar-item.e-overlay {
  opacity: 1 !important;
  background-color: #fff !important;
}
#Emp_PayHeadsGrid .e-toolbar .e-toolbar-items .e-toolbar-item {
  border: 1px solid #fc4c02 !important;
  border-radius: 50px !important;
  margin: 0px 0.2rem;
  padding: 0.5rem;
}
#Emp_PayHeadsGrid.e-wrap .e-gridheader .e-sortnumber {
  display: none !important;
}
#Emp_PayHeadsGrid
  .e-gridheader
  .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop):not(
    .e-icon-reorderuparrow
  ):not(.e-icon-reorderdownarrow),
#Emp_PayHeadsGrid th.e-headercell[aria-sort='ascending'] .e-headertext {
  color: #fc4c02 !important;
}
#Emp_PayHeadsGrid .e-toolbar .e-toolbar-items .e-toolbar-item:hover,
#Emp_PayHeadsGrid
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-item:hover
  .e-tbar-btn {
  background-color: #fc4c02 !important;
}
#Emp_PayHeadsGrid
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-item:hover
  .e-tbar-btn
  .e-tbar-btn-text,
#Emp_PayHeadsGrid
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-item:hover
  .e-tbar-btn.e-btn
  .e-icons.e-btn-icon {
  color: #fff !important;
}
#Emp_PayHeadsGrid .e-toolbar .e-toolbar-item .e-tbar-btn {
  background-color: transparent !important;
  color: #fc4c02 !important;
  margin: 0px !important;
}
#Emp_PayHeadsGrid .e-toolbar .e-toolbar-item .e-tbar-btn .e-tbar-btn-text {
  font-size: 0.875rem !important;
}
#Emp_PayHeadsGrid .e-gridheader {
  border-top: none !important;
  color: #fc4c02 !important;
  margin-top: 12px;
}
#Emp_PayHeadsGrid .e-gridheader .e-sortfilter .e-headercelldiv {
  font-size: 0.9rem !important;
}
#Emp_PayHeadsGrid .e-groupcaption {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
#Emp_PayHeadsGrid .e-recordplusexpand {
  border-bottom: 1px solid #e9e9f2 !important;
  background-color: #fff9f6 !important;
  border-left: 1px solid #fc4c02 !important;
}
#Emp_PayHeadsGrid .e-rowcell {
  padding: 8px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  font-size: 0.875rem !important;
}
#Emp_PayHeadsGrid .e-indentcell {
  position: relative;
  color: transparent;
  padding-left: 0;
  text-align: center;
  /* visibility: hidden; */
  border-radius: 4px;
  height: 40px;
  cursor: pointer;
  border-radius: 0px;
  width: 40px;
  border-right: 0px !important;
  border-top: 1px solid #e9e9f2 !important;
  border-left: 1px solid #f7c3ad;
  font-size: 0.875rem;
}
#Emp_PayHeadsGrid .e-icon-grightarrow::before,
#Emp_PayHeadsGrid .e-icon-grightarrow::before {
  color: #363636;
}
/* Replace cross icon with "CLEAR" */

#Emp_PayHeadsGrid_dialogEdit_wrapper .e-input-group .e-clear-icon::before,
#Emp_PayHeadsGrid_dialogEdit_wrapper
  .e-input-group.e-control-wrapper
  .e-clear-icon::before {
  content: 'CLEAR' !important;
  display: inline-block !important;
  font-size: 0.875rem !important;
  color: #363636 !important;
  background: none !important;
  width: auto !important;
  height: auto !important;
  text-align: center !important;
  line-height: 1 !important;
  margin-right: 12px !important;
  margin-bottom: 2px !important;
}

#Emp_PayHeadsGrid_dialogEdit_wrapper
  .e-ddl.e-input-group
  input[readonly]
  ~ .e-clear-icon,
#Emp_PayHeadsGrid_dialogEdit_wrapper
  .e-float-input
  input[readonly]
  ~ .e-clear-icon,
#Emp_PayHeadsGrid_dialogEdit_wrapper
  .e-float-input.e-input-group
  input[readonly]
  ~ .e-clear-icon {
  background: none !important;
  width: auto !important;
  height: auto !important;
}

#Emp_PayHeadsGrid .fa-trash {
  font-size: 16px;
  cursor: pointer;
  visibility: hidden;
}
#Emp_PayHeadsGrid .e-indentcell::before {
  content: '\f00c';
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900;
  color: #fff;
  position: absolute;
  background-color: #fc4c02;
  padding: 0px 4px;
  border-radius: 50px;
  visibility: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#Emp_PayHeadsGrid .e-gridcontent {
  height: 100%;
}
#Emp_PayHeadsGrid .e-content {
  padding: 0px !important;
  border-radius: 0px !important;
  scrollbar-width: thin !important;
}
.custom-switch-div {
  display: flex;
  align-items: center;
  padding-top: 8px;
}
.custom-switch-div .switch-text {
  font-size: 0.875rem;
  display: inline-block;
  margin-left: 10px;
}
.e-switch-wrapper {
  position: relative !important;
  height: 26px !important;
  width: 46px !important;
}
.e-switch-wrapper .e-switch-handle.e-switch-active {
  background-color: #fff !important;
}
.e-switch-wrapper .e-switch-inner.e-switch-active .e-switch-on {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-image: url('../../../public/images/check.png');
  background-repeat: no-repeat;
  background-position: 8px;
  background-size: 16px 16px;
  opacity: 1 !important;
  color: transparent;
}
.e-switch-wrapper .e-switch-inner.e-switch-active .e-switch-on {
  background-color: #fc4c02;
}
.e-switch-wrapper .e-switch-off {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-image: url('../../../public/images/cross.png');
  background-repeat: no-repeat;
  background-position: 24px 8px;
  background-size: 10px 10px;
  background-color: #9e9999 !important;
  color: transparent;
}
#payheadid_popup .e-list-item {
  line-height: 42px !important;
}
#payheadid_popup .e-list-item:hover {
  background-color: #fbf4ee;
  color: #fc4c02;
}
#payheadid_popup .NewOptionClass {
  padding: 3px 12px 3px 0px !important;
  line-height: 0px !important;
  color: #fc4c02 !important;
}
#payheadid_popup .NewOptionClass:hover {
  color: #fff !important;
}

/* #Emp_PayHeadsGrid_dialogEdit_wrapper .save-button {
  background-color: #fc4c02;
  border: none;
  color: #fff;
  padding: 13px 24px;
  border-radius: 20px;
  cursor: pointer;
}
#Emp_PayHeadsGrid_dialogEdit_wrapper .save-button:hover {
  background-color: #e64a19;
}
#Emp_PayHeadsGrid_dialogEdit_wrapper .cancel-button {
  background-color: transparent;
  color: #333;
  padding: 14px 18px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
}
#Emp_PayHeadsGrid_dialogEdit_wrapper .cancel-button:hover {
  background-color: #ddd;
} */

#Emp_PayHeadsGrid_dialogEdit_wrapper .e-footer-content .e-control.e-btn {
  height: 45px !important;
  border-radius: 50px;
  padding: 16px 20px !important;
  opacity: 1 !important;
  color: #000000 !important;
  font-weight: 400;
}

#Emp_PayHeadsGrid_dialogEdit_wrapper .e-footer-content .e-control.e-btn:hover {
  background: #e9e9f2 !important;
  color: #000000 !important;
}

#Emp_PayHeadsGrid_dialogEdit_wrapper
  .e-footer-content
  .e-control.e-btn.e-primary {
  background: #fc4c01 !important;
  color: #ffffff !important;
  font-weight: 400;
}

#Emp_PayHeadsGrid_dialogEdit_wrapper
  .e-footer-content
  .e-control.e-btn.e-primary:hover {
  box-shadow: 0px 5px 4px 0px #fc4c0152;
  background: #fc4c01 !important;
  color: #ffffff !important;
}
