#divCompany {
  display: flex;
  flex-wrap: wrap;
}

#divCompany .row {
  padding-top: 8px;
  padding-bottom: 8px;
}

#divCompany .basicinfo-add {
  width: 100%;
}

#divCompany .basicinfo-edit {
  width: 100%;
}

#divCompany .settinginfo {
  width: 50%;
  padding-left: 20px;
}

#divCompany .panelheight {
  display: flex;
  width: 100%;
  max-height: calc(100vh - 162px);
  overflow-y: auto;
  overflow-x: hidden;
  /* scrollbar-width: none; */
  scrollbar-width: thin;
}

#divCompany .e-footer-content-temp {
  padding: 8px;
  width: 100%;
  text-align: right;
}

#divCompany .divgrid {
  display: grid;
  grid-template-columns: repeat(2, 50%);
}

@media screen and (max-width: 768px) {
  #divCompany .divgrid {
    grid-template-columns: repeat(1, 100%);
  }
}

#divCompany .dateformat {
  grid-row: span 2;
}
