/* General Card Styling */
#divResourcelinkCard {
  border: none !important;
  border-radius: 6px !important;
  background-color: #ffffff !important;
  box-shadow: none !important;
  padding: 6px !important;
}

#divResourcelinkCard
  .e-accordion
  .e-acrdn-item
  .e-acrdn-header
  .e-acrdn-header-icon {
  color: #fefefe !important;
  font-size: 1.5rem !important;
  position: relative !important;
  top: 3px !important;
  right: 6px !important;
}
#divResourcelinkCard .e-accordion .e-acrdn-item.e-select > .e-acrdn-header {
  border-bottom: none !important;
}

#divResourcelinkCard
  .e-accordion
  .e-acrdn-item.e-select.e-acrdn-item
  > .e-acrdn-header:hover
  .e-icons {
  color: #fefefe !important;
}

#divResourcelinkCard .e-card-content {
  height: 285px !important;
  overflow: auto !important;
  scrollbar-width: thin !important;
  padding: 12px !important;
  padding-left: 16px !important;
}

#divResourcelinkCard .e-card-header {
  border-bottom: 1px solid #e0e0e0;
  padding: 18px 5px;
  color: #363636;
  font-size: 18px !important;
  font-weight: bold;
  display: flex !important;
  align-items: center !important;
  justify-content: start;
  /* width: 90%;
  margin: 0px 0px 0px 12px; */
}

#divResourcelinkCard .card-header-icon {
  font-size: 1.125rem !important;
}

#divresourcelinkcard-grid {
  height: auto;
  /* Allow content to display fully by default */
  overflow: hidden;
  transition: height 0.3s ease;
  /* Smooth height transition */
}

#divresourcelinkcard-grid:not(.expanded) {
  height: 0;
  /* Collapse when not expanded */
}

#divResourcelinkCard .e-card-header {
  padding: 23px 8px 23px 20px !important;
  margin-bottom: 10px !important;
  border-bottom: 1px solid #e0e0e0 !important;
}

/* #divResourcelinkCard
  .e-accordion
  .e-acrdn-item.e-select.e-acrdn-item
  > .e-acrdn-header {
  border-radius: 8px 8px 0 0 !important;
} */
#divResourcelinkCard .e-accordion .e-acrdn-item .e-acrdn-header .e-toggle-icon {
  top: 10px !important;
}

#divResourcelinkCard
  .e-accordion
  .e-acrdn-item.e-select.e-acrdn-item
  > .e-acrdn-header:hover
  .e-toggle-icon
  .e-icons.e-tgl-collapse-icon {
  color: #fff !important;
}

/* Accordion Styling */
#divResourcelinkCard .e-accordion .e-acrdn-item {
  border: none !important;
  margin: 8px auto !important;
  border-radius: 8px !important;
}

/* Header styles for accordion items */
#divResourcelinkCard .e-accordion .e-acrdn-header {
  font-size: 14px !important;
  font-weight: bold !important;
  padding: 16px 25px !important;
  color: #fff !important;
  cursor: pointer !important;
  border-radius: 8px !important;
  margin-bottom: -8px !important;
  margin-top: 18px !important;
}

#divResourcelinkCard .e-accordion .e-acrdn-header:hover {
  color: #fff !important;
}

#divResourcelinkCard
  .e-accordion
  .e-acrdn-item
  .e-acrdn-header
  .e-acrdn-header-content,
#divResourcelinkCard .e-accordion .e-acrdn-item .e-toggle-icon {
  color: #fefefe !important;
  font-size: 1rem;
  font-weight: 400;
}

#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(1) .e-acrdn-header {
  margin-top: 0px !important;
}

#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(1) .e-acrdn-header,
#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(4) .e-acrdn-header,
#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(7) .e-acrdn-header,
#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(10) .e-acrdn-header {
  background-color: #1abf9b !important;
  /* Green for Health and Safety */
}

#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(2) .e-acrdn-header,
#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(5) .e-acrdn-header,
#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(8) .e-acrdn-header,
#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(11) .e-acrdn-header {
  background-color: #61b0ff !important;
  /* Blue for Policy Updates */
}

#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(3) .e-acrdn-header,
#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(6) .e-acrdn-header,
#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(9) .e-acrdn-header,
#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(12) .e-acrdn-header {
  background-color: #fcb318 !important;
  /* Orange for Services */
}

#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(1).e-select.e-active
  > .e-acrdn-header,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(4).e-select.e-active
  > .e-acrdn-header,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(7).e-select.e-active
  > .e-acrdn-header,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(10).e-select.e-active
  > .e-acrdn-header {
  background-color: #1abf9b !important;
}

#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(2).e-select.e-active
  > .e-acrdn-header,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(5).e-select.e-active
  > .e-acrdn-header,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(8).e-select.e-active
  > .e-acrdn-header,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(11).e-select.e-active
  > .e-acrdn-header {
  background-color: #61b0ff !important;
}

#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(3).e-select.e-active
  > .e-acrdn-header,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(6).e-select.e-active
  > .e-acrdn-header,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(9).e-select.e-active
  > .e-acrdn-header,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(12).e-select.e-active
  > .e-acrdn-header {
  background-color: #fcb318 !important;
}

#divResourcelinkCard .e-accordion .e-acrdn-content {
  padding: 16px !important;
  border-radius: 0 0 8px 8px !important;
}

/* Content Section Styling */
#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(1) .e-acrdn-content,
#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(4) .e-acrdn-content,
#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(7) .e-acrdn-content,
#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(10) .e-acrdn-content {
  background-color: #1abf9b !important;
}

#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(2) .e-acrdn-content,
#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(5) .e-acrdn-content,
#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(8) .e-acrdn-content,
#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(11) .e-acrdn-content {
  background-color: #61b0ff !important;
}

#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(3) .e-acrdn-content,
#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(6) .e-acrdn-content,
#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(9) .e-acrdn-content,
#divResourcelinkCard .e-accordion .e-acrdn-item:nth-child(12) .e-acrdn-content {
  background-color: #fcb318 !important;
}

#divResourcelinkCard
  .e-accordion
  .e-acrdn-item
  .e-acrdn-panel
  .e-acrdn-content
  > * {
  width: 100% !important;
}

#divResourcelinkCard .e-accordion .e-acrdn-content ul {
  margin: 0 !important;
  padding: 0 !important;
  list-style: none !important;
}

#divResourcelinkCard .e-accordion .e-acrdn-content ul li {
  margin-bottom: 12px !important;
  /* background-color: #fefefe !important; */
  border-radius: 8px !important;
  display: flex !important;
  align-items: center !important;
  padding: 14px !important;
}

#divResourcelinkCard .e-accordion .e-acrdn-content ul li::before {
  content: '•';
  margin-right: 10px;
  font-size: 30px;
  line-height: 0;
  vertical-align: middle;
}

#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(1)
  .e-acrdn-content
  ul
  li,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(4)
  .e-acrdn-content
  ul
  li,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(7)
  .e-acrdn-content
  ul
  li,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(10)
  .e-acrdn-content
  ul
  li {
  color: #1abf9b !important;
  background-color: #fff !important;
}

#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(2)
  .e-acrdn-content
  ul
  li,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(5)
  .e-acrdn-content
  ul
  li,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(8)
  .e-acrdn-content
  ul
  li,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(11)
  .e-acrdn-content
  ul
  li {
  color: #61b0ff !important;
  background-color: #fff !important;
}

#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(3)
  .e-acrdn-content
  ul
  li,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(6)
  .e-acrdn-content
  ul
  li,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(9)
  .e-acrdn-content
  ul
  li,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(12)
  .e-acrdn-content
  ul
  li {
  color: #fcb318 !important;
  background-color: #fff !important;
}

/* Links Styling */
#divResourcelinkCard .e-accordion .e-acrdn-content ul li a {
  font-size: 14px !important;
  /* color: #007bff !important; */
  text-decoration: none !important;
}

#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(1)
  .e-acrdn-content
  ul
  li
  a,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(4)
  .e-acrdn-content
  ul
  li
  a,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(7)
  .e-acrdn-content
  ul
  li
  a,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(10)
  .e-acrdn-content
  ul
  li
  a {
  color: #1abf9b !important;
}

#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(2)
  .e-acrdn-content
  ul
  li
  a,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(5)
  .e-acrdn-content
  ul
  li
  a,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(8)
  .e-acrdn-content
  ul
  li
  a,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(11)
  .e-acrdn-content
  ul
  li
  a {
  color: #61b0ff !important;
}

#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(3)
  .e-acrdn-content
  ul
  li
  a,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(6)
  .e-acrdn-content
  ul
  li
  a,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(9)
  .e-acrdn-content
  ul
  li
  a,
#divResourcelinkCard
  .e-accordion
  .e-acrdn-item:nth-child(12)
  .e-acrdn-content
  ul
  li
  a {
  color: #fcb318 !important;
}

#divResourcelinkCard .e-accordion .e-acrdn-content ul li a:hover {
  text-decoration: underline !important;
}

/* Tooltip Styling */
#divResourcelinkCard .e-tooltip-wrap .e-tooltip {
  font-size: 12px !important;
  color: #333 !important;
}

#divResourcelinkCard .e-accordion .e-acrdn-item .e-acrdn-panel {
  margin-top: -2px !important;
}
