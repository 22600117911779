.non-selectedEmp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.non-selectedEmp1 {
  max-width: 70%;
  height: auto !important;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(29%, -50%);
}
.non-selectedEmp2 {
  max-width: 30%;
  height: auto !important;
  bottom: 0px;
  position: absolute;
}
#profilebiodataviewer-tabcomponent {
  transition: height 0.3s ease !important;
}
.div {
  width: 100%;
  margin: auto;
}
.main-infi-div {
  width: 80%;
}
#Profile-Card {
  border: 1px solid #e3e3e3;
  border-bottom: none !important;
  border-radius: 10px 10px 0px 0px;
  /* padding-top: 0px; */
  /* padding-bottom: 0px !important; */
  /* margin-top: 2px 10px; */
  /* margin-bottom: 0px !important; */
  width: 100%;
  background-color: #ffffff;
  transition: all 0.3s ease;
  display: flex;
  /* min-height: 168px; */
  position: relative;
  flex-direction: column;
}

#Profile-Card:not(.expanded) {
  height: auto;
  /* overflow: hidden;
  min-height: 60px; */ /* Suhail - layout fix - 20250203 */
}
div#Image-hover {
  /* width: 12%; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-info-div {
  width: 100%;
}
#Profile-Card.expanded {
  height: auto;
}
#Profile-Card.expanded .pcard-details {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  height: 60px;
  overflow: hidden;
}
#Profile-Card:not(.expanded) .pcard-details {
  display: none !important;
}
/* Horizontal scrolling grid */
.pcard-details {
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 8px;
  scrollbar-width: thin;
  scrollbar-color: #ccc transparent;
  max-width: 100%;
  align-items: center;
}

/* Individual card styling */
.pcard-details div {
  padding: 7px;
  border-radius: 5px;
  min-width: 145px;
  max-width: 180px;
  text-align: center;
  background: #fcfcff;
  border: 1px solid #e3e3f1;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  word-wrap: break-word;
  min-height: 45px;
  max-height: 55px;
}

.pcard-details div strong {
  font-size: 0.775rem;
  color: #4f4e4e;
  text-transform: uppercase;
}
.pcard-details .datamenu-age {
  min-width: auto !important;
  padding: 8px 16px !important;
}
.pcard-details div p {
  font-size: 0.895rem;
  margin-bottom: 0;
  font-weight: 500;
  color: #535b86;
}
/* See More button inside the row */
.view-more-btn {
  background-color: #fefefe;
  color: #fc4c02;
  border: none;
  transition: background-color 0.3s ease;
  text-align: center;
  font-weight: 300;
  font-size: 0.885rem;
  width: 120px;
  padding: 6px;
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .pcard-details {
    flex-wrap: wrap;
    overflow-x: visible;
    justify-content: center;
  }
}

#Profile-Card .pcard-header {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 16px;
  /* margin-top: -32px; */
  margin-bottom: 0px !important;
  padding: 10px;
  padding-bottom: 0px;
}

#Profile-Card .user-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid #ddd;
  flex-shrink: 0;
  transition: all 0.3s ease;
}
#Profile-Card .user-image.large {
  width: 6.25rem; /* Suhail - layout fix - 20250203 */
  height: 6.25rem; /* Suhail - layout fix - 20250203 */
}
#Profile-Card .user-image.small {
  width: 4.375rem;
  height: 4.375rem;
}
#Profile-Card .imgEdit-pen {
  position: absolute;
  border: 2px solid #938b8b;
  bottom: 5px;
  right: 5px;
  border-radius: 50%;
  display: none;
  color: #938b8b;
  cursor: pointer;
  background: #fefefe;
}
#Image-hover:hover .imgEdit-pen {
  display: block;
}
#Profile-Card .imgEdit-pen:hover {
  color: #fc4c02;
  border: 2px solid #fc4c02;
}
#Profile-Card .imgEdit-pen.large {
  padding: 6px;
  font-size: 1rem;
}
#Profile-Card .imgEdit-pen.small {
  padding: 4px;
  font-size: 0.875rem;
}
#Profile-Card .user-infocard {
  display: flex;
  align-items: flex-start;
  padding: 10px;
}
.user-infocard {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 10px;
  padding-bottom: 0px !important;
}
.user-namecard {
  font-size: 1.2rem;
  font-weight: 900;
  color: #363636;
  text-transform: capitalize;
  line-height: 27px;
}
.occupation {
  padding: 4px 10px;
  border-radius: 20px;
  color: #fc4c02;
  /* margin-top: 0px; */
  /* margin-left: 10px; */
  background: #fff9f6;
}
.emocode-class {
  color: #535b86;
  /* margin-top: 4px; */
  margin-bottom: 2px;
}

#DetailsDialog {
  width: 390px !important;
  max-width: 600px !important;
  min-height: 241px !important;
  left: unset !important;
  right: 12px !important;
  padding: 0px !important;
  top: 54px !important;
}
#DetailsDialog:after {
  content: '';
  position: absolute;
  right: 5%;
  top: -12px;
  width: 15px;
  height: 0px;
  border-style: solid;
  border-width: 0px 20px 20px 20px;
  border-color: transparent transparent #fff transparent;
  z-index: 9999;
}
#DetailsDialog .e-dlg-content {
  width: 100% !important;
  padding: 0px !important;
}
#DetailsDialog .content-details-dialog {
  width: 100% !important;
  padding: 10px !important;
  border: none;
}
#DetailsDialog .e-dlg-content div {
  padding: 8px;
  border-radius: 5px;
  width: 100% !important;
  text-align: center;
  background: #f8f8f8;
  font-weight: 300;
}
#DetailsDialog .detail-item {
  margin-bottom: 10px !important;
}

#DetailsDialog .detail-label {
  font-weight: bold !important;
  display: block !important;
  margin-bottom: 4px !important;
  color: #333 !important;
}

#DetailsDialog .detail-value {
  color: #555 !important;
}
@media (max-width: 768px) {
  .user-infocard {
    flex-direction: column;
    align-items: center;
  }

  .pcard-details div {
    width: 100%;
  }
}
#Profile-Card .pcard-actions {
  display: flex;
  gap: 10px;
  margin-left: auto;
  position: absolute;
  right: 18px;
  top: 18px;
}

#Profile-Card .pcard-actions button {
  background: none;
  border: none;
  font-size: 1.2em;
  color: #535b86;
  cursor: pointer;
  transition: color 0.3s ease;
}
/* Suhail - layout fix - 20250203 - start */
#basic_card .people-Section-Container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
#AllPerson {
  width: 100%;
  max-height: 100%;
  flex: 1 !important;
  overflow: auto;
  margin: auto;
  /* margin-top: -15px !important; */
}
#AllPerson > .dataviewer-tabcomponent {
  height: calc(100% - 2rem) !important;
  display: flex;
  flex-direction: column;
}
#AllPerson > .dataviewer-tabcomponent .e-content {
  flex: 1;
  max-height: 100%;
  overflow: auto;
}
#tabC {
  height: 100%;
}
#tabC .dataviewer-tabcomponent {
  height: 100% !important;
}
#tabC .dataviewer-tabcomponent > .e-content {
  height: calc(100% - 52px);
}
#AllPerson #e-content-dataviewer-tabcomponent_0 div:not([class]) {
  height: 100%;
}
#AllPerson .e-content .e-item div:not([class]) {
  height: 100%;
}
/* Suhail - layout fix - 20250203 - end */
#AllPerson .e-tab .e-tab-header {
  width: 100% !important;
  box-sizing: border-box;
  border-top: none !important;
  border-radius: 0px 0px 10px 10px;
  padding-bottom: 0px !important;
  min-height: 45px;
  background: #fefefe;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  width: 100% !important;
  position: relative;
  padding: 0px !important;
  margin-top: 0px !important;
}
#AllPerson .e-tab .e-tab-header .e-toolbar-items {
  margin: 5px 10px !important;
  margin-right: auto;
  min-height: unset !important;
}
#AllPerson .e-tab .e-tab-header:hover {
  box-shadow: none !important;
}
/* #AllPerson .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
  width: 95px !important;
} */
#AllPerson .e-tab .e-content {
  border-radius: 10px;
  width: 100%;
  background-color: #ffffff;
  transition: all 0.3s ease;
  display: flex;
  position: relative;
  flex-direction: column;
}
/* Suhail - layout fix - 20250203 - start */
#AllPerson .e-tab .e-content .e-item {
  height: 100%;
  overflow: auto;
}
/* Suhail - layout fix - 20250203 - end */
#AllPerson .e-tab .e-tab-header .e-toolbar-item .e-tab-text {
  font-size: 0.915rem !important;
}
#AllPerson .e-tab .e-tab-header .e-toolbar-item.e-active {
  background-color: transparent !important;
  border-top: 1px solid transparent !important;
  border-left: 2px solid transparent !important;
  border-right: 2px solid transparent !important;
  border-radius: 5px 5px 0px 0px !important;
  position: relative;
}

#AllPerson .e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text {
  color: #fc4c02 !important;
  /* padding: 15px 4px; */
}
#AllPerson .e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fc4c02;
}

#AllPerson .e-tab .e-tab-header .e-toolbar-item:not(.e-active) {
  border-bottom: none !important;
  border-radius: 0px !important;
}
#AllPerson .tabicon {
  margin-right: 10px;
  display: inline-block;
}
.content-basic-card {
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  background-color: #ffffff;
  /* height: calc(100vh - 300px); */
  height: 100%;
  padding-top: 10px !important;
}
#AllPerson .e-tab .e-content {
  transition: height 0.3s ease, border 0.3s ease;
}
#AllPerson .e-card-resize-container .row .col-12 {
  padding: 0px !important;
}
#tabC .e-tab .e-tab-header {
  background-color: transparent;
  width: 100% !important;
  box-shadow: none !important;
  border: none !important;
}
#tabC .e-tab .e-tab-header:hover {
  box-shadow: none !important;
}
#tabC .e-tab.e-focused > .e-tab-header .e-toolbar-item .e-tab-wrap:focus {
  background: transparent !important;
}
#tabC .e-tab .e-tab-header .e-toolbar-item.e-active {
  /* background-color: #fc4c02 !important;
  border-top: 2px solid #fc4c02 !important;
  border-left: 2px solid #fc4c02 !important;
  border-right: 2px solid #fc4c02 !important;
  border-radius: 5px 5px 0px 0px !important; */
  border-bottom: 2px solid #fc4c02 !important;
  padding-bottom: 38px;
}
#tabC .e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text {
  color: #fc4c02 !important;
}
#tabC .e-tab .e-tab-header .e-toolbar-item:not(.e-active) {
  border-bottom: 2px solid #ddd !important;
  border-radius: 5px 5px 0px 0px !important;
  padding-bottom: 40px;
}
#tabC .e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover,
#tabC
  .e-tab
  .e-tab-header
  .e-toolbar-item.e-active
  .e-tab-wrap:hover
  .e-tab-text {
  background-color: transparent !important;
  color: #fc4c02 !important;
}
#tabC .e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text::after {
  content: '';
  position: absolute;
  bottom: -0px;
  left: 0%;
  transform: translateX(-0%);
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 0px solid transparent;
}
.basic-profile-card .basic_card {
  margin-top: -3px !important;
  /* height: calc(100vh - 300px) !important; */
  height: 100% !important;
  border-radius: 10px !important;
}

/* #AllPerson .e-content.e-lib.e-touch {
  height: auto !important;
} */
.ImageUploadDialog {
  width: 100%;
  height: auto;
  padding: 20px;
}

/* Styling for the dialog containing the image editor */
#profile-dialog {
  width: 38vw !important;
  height: 60vh !important;
  max-width: 90vw;
  max-height: 80vh;
  overflow: hidden;
  padding: 0;
  /* position: relative;  */
}
#profile-dialog .e-footer-content {
  display: unset !important;
  padding: unset !important;
  padding: 10px !important;
  padding-top: 0px !important;
}

#profile-dialog .e-dlg-header-content {
  padding: 30px 8px 16px 8px !important;
  margin: 0px 1.2rem;
}

#profile-dialog .e-dlg-content {
  width: 100%;
  height: 100%;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

#profile-dialog .image-editor-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

#profile-dialog #ej2-image-editor_0_canvasWrapper {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

#profile-dialog #ej2-image-editor_0_lowerCanvas,
#profile-dialog #ej2-image-editor_0_upperCanvas {
  width: 100% !important;
  height: 100% !important;
  border: none;
  max-width: 100%;
  max-height: 100%;
  background-color: transparent;
}

#profile-dialog .e-textarea {
  width: 100% !important;
  height: auto !important;
  resize: none;
  display: block;
  background: transparent;
}

#profile-dialog .imgEditorbtns {
  background-color: #ddd;
  border-radius: 30px;
  text-transform: capitalize;
  box-shadow: none;
  padding: 15px !important;
}
#profile-dialog .custom-img-open,
#profile-dialog .custom-img-reset,
#profile-dialog .custom-img-rotate,
#profile-dialog .custom-img-zoomin,
#profile-dialog .custom-img-zoomout {
  width: 40px !important;
  height: 40px !important;
  line-height: 18px !important;
  background-color: #efe6e6 !important;
}

#profile-dialog .e-dlg-footer {
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgeditor-dialog-footer {
  width: 100% !important;
  display: flex;
  justify-content: space-between; /* Adjust as needed */
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}
.pofile-dialog .button-container {
  display: flex;
  gap: 10px;
}
.begin-buttons {
  width: 70%;
  display: flex;
}
.last-buttons {
  height: 30%;
  display: flex;
}
#profile-dialog .button-container.show {
  display: flex;
  animation: slideInRight 0.5s ease-out forwards;
}

#profile-dialog .button-container.hide {
  display: none;
  animation: slideOutLeft 0.5s ease-out forwards;
}

#profile-dialog .e-image-editor .e-toolbar {
  display: none !important;
}

#profile-dialog .e-img-editor-profile,
#profile-dialog .e-img-editor-sample .control-wrapper {
  position: relative;
}

#profile-dialog .e-img-editor-profile .e-profile.large {
  width: 160px;
  height: 160px;
  border-radius: 50%;
}

#profile-dialog .e-img-editor-profile .e-profile.small {
  width: 80px;
  height: 80px;
}

#profile-dialog .e-custom-wrapper {
  position: relative;
}

#profile-dialog .e-custom-edit.large {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fc4c02;
  top: calc(100% - 60px);
  left: calc(100% - 45px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
}
#profile-dialog .e-custom-edit.small {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fc4c02;
  top: calc(100% - 36px);
  left: calc(100% - 30px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
}
#custom-edit .e-custom-icon.large::before {
  content: '\f303';
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  font-size: 1rem;
  color: white;
  left: 10px;
  top: 10px;
  line-height: initial;
  position: absolute;
}
#custom-edit .e-custom-icon.small::before {
  content: '\f303';
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  font-size: 1rem;
  color: white;
  left: 8px;
  top: 8px;
  line-height: initial;
  position: absolute;
}

#profile-dialog .tailwind .e-custom-icon::before,
#profile-dialog .tailwind-dark .e-custom-icon::before {
  top: 6px;
}

#custom-edit:hover {
  cursor: pointer;
  background-color: #e85a1c;
  transform: translateY(-5px);
}

#profile-dialog .img-canvas.large {
  border-radius: 50%;
  border: 1px solid #ddd;
  max-width: 160px;
  max-height: 160px;
}

#profile-dialog .img-canvas.small {
  border-radius: 50%;
  max-width: 80px;
  max-height: 80px;
}

#profile-dialog .e-img-custom-open {
  float: left;
  margin-left: 0 !important;
}

#profile-dialog .e-img-custom-reset {
  float: left;
}

#profile-dialog .e-profile .e-custom-img,
#profile-dialog .e-profile .e-custom-file {
  display: none;
}

#profile-dialog .e-bigger #profile-dialog .e-dlg-header {
  font-size: 1rem !important;
}

#profile-dialog .e-dlg-content > div {
  height: 100%;
  width: 100%;
}
button.e-control.e-btn.e-lib.imgEditorbtns.custom-img-apply.e-primary {
  background: #fc4c02 !important;
}
#profile-dialog .e-footer-content .e-btn {
  padding: 8px !important;
  font-size: 0.875rem !important;
}

/* ------------        Dialog  view More            -------------------------- */

div#viewdialog_dialog-content {
  padding: 0px !important;
}
#viewdialog {
  width: 28vw !important;
  height: 35vh !important;
  max-width: 90vw;
  max-height: 80vh;
  padding: 0;
  left: unset !important;
  right: 10% !important;
  top: 24vh !important;
}
#viewdialog:after {
  content: '';
  position: absolute;
  left: 60%;
  right: 30%;

  top: -12px;
  width: 15px;
  height: 0px;
  border-style: solid;
  border-width: 0px 20px 20px 20px;
  border-color: transparent transparent #fff transparent;
  z-index: 9999;
}
@media (max-width: 1750px) {
  #viewdialog {
    top: 28vh !important;
  }
  #viewdialog:after {
    left: 70%;
  }
}

@media (max-width: 1600px) {
  #viewdialog {
    top: 28vh !important;
    right: 40px !important;
  }
  #viewdialog:after {
    left: 70%;
  }
}

.viewdialog-header {
  display: flex;
  align-items: center;
  gap: 10px;
}
#viewdialog .e-dlg-header-content {
  margin: 0px 18px;
  border-bottom: 1px solid #ddd !important;
  border-radius: 0px !important;
  padding: 18px 4px;
}
#viewdialog .e-dlg-content {
  height: calc(100vh - 580px) !important;
  overflow: auto;
  position: relative;
}
.viewuser-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.viewdialog-content {
  /* padding: 10px 0; */
  overflow: auto;
}

.viewcontent-row {
  display: flex;
  padding: 20px 10px;
  /* height: calc(100vh - 740px); */
}

.viewcontent-row div {
  text-align: left;
  padding: 10px;
  width: 150px;
  height: auto;
  border-radius: 10px;
}
.viewcontent-row div strong,
.viewcontent-row div span {
  display: block;
  text-align: center;
  line-height: 20px;
  letter-spacing: 1px;
  color: #8a8888;
  font-size: 1rem;
}
.viewdialog-note {
  background-color: #fff7f5;
  padding: 20px 22px;
  font-size: 1rem;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.viewdialog-note p {
  margin: 0;
  color: #ff6b6b;
}
.viewMore-btn {
  padding: 8px 12px;
  border: 1px solid #fc4c02;
  box-shadow: none;
  color: #fc4c02;
  background-color: #fefefe;
  position: absolute;
  bottom: 12%;
  right: 2%;
  border-radius: 12px;
}
