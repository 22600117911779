#calendarSeclector .calendar-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  padding: 0px 16px;
  font-family: 'Roboto', sans-serif !important;
}

#calendarSeclector .calendar-yearrow {
  width: fit-content;
  margin-left: auto;
}

#calendarSeclector .today-button {
  padding: 8px 16px;
  border: 1px solid #fc4c02;
  border-radius: 9999px;
  color: #fc4c02;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
}
.paySlip-container.fromDialogCss {
  height: calc(100% - 37px);
}
#calendarSeclector .today-button.highlighted {
  background-color: #fff9f6;
}

#calendarSeclector .today-button:hover {
  background-color: #fff9f6;
}

#calendarSeclector .selectors {
  display: flex;
  align-items: center;
  gap: 8px;
}

#calendarSeclector .nav-button {
  color: #6b7280;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px 8px;
  transition: all 0.3s ease;
}

#calendarSeclector .nav-button:hover {
  color: #000;
}

#calendarSeclector .current {
  font-weight: 500;
  color: #374151;
}

#calendarSeclector .month-list {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: center;
}

#calendarSeclector .month {
  cursor: pointer;
  padding: 4px 8px;
  transition: all 0.3s ease;
}

#calendarSeclector .month:hover {
  background-color: #f3f4f6;
  border-radius: 4px;
}

#calendarSeclector .highlighted-month {
  font-weight: 500;
  text-decoration: underline;
  color: #f97316;
}

#calendarSeclector .calendar-mode {
  display: flex;
  gap: 12px;
}

#calendarSeclector .calendar-yearmode {
  position: absolute;
  right: 0px;
}

#calendarSeclector .modeType {
  border: none;
  color: #918b8b;
  box-shadow: none;
  padding: 5px;
  background-color: transparent;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
}

.modeType.active {
  color: #fc4c02 !important;
}

#ScheduleDiv {
  margin-top: 10px;
  height: calc(100% - 46px) !important; /* Suhail - layout fix - 20250203 */
}

/* Suhail - layout fix - 20250203 - start */
#ScheduleDiv .e-schedule-table.e-outer-table tr:first-child td {
  height: 1.875rem;
}
/* Suhail - layout fix - 20250203 - end */
.e-disable-weekend {
  pointer-events: none;
  /* Light grey to indicate disabled */
  /* opacity: 0.5; */
}

.highlight-holiday {
  background-color: #316164 !important;
  color: #fff !important;
  font-weight: bold;
  pointer-events: none;
  font-weight: 600 !important;
  position: relative;
}
/* #ScheduleDiv
  td.e-work-cells.e-work-days.highlight-holiday
  .e-date-header.e-navigate {
  width: 3rem;
  height: 1.7rem;
  margin: auto;
  background: #fc4c02;
  color: #fff;
  border-radius: 2px;
} */
#ScheduleDiv .e-schedule {
  border: none !important;
  height: 100% !important;
}

#ScheduleDiv .e-schedule .e-table-container {
  height: 100%;
}

#ScheduleDiv .e-schedule .e-table-container table {
  height: 100%;
}

#ScheduleDiv .e-schedule .e-month-view .e-date-header-wrap table td {
  text-align: center !important;
  border-color: #e9e9f2 !important;
  padding-bottom: 10px !important;
}

#ScheduleDiv .e-schedule .e-month-view .e-date-header {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  position: relative;
  top: 28px;
}

#ScheduleDiv .e-schedule .e-month-view .e-current-date .e-date-header {
  background: #fc4c02;
  border-radius: 50%;
  color: #fff;
  padding: 12px !important;
  margin: auto !important;
  box-sizing: border-box;
}

#ScheduleDiv .e-schedule .e-month-view .e-work-cells {
  background: #f3f4f9;
  height: unset !important;
}

#ScheduleDiv .e-schedule .e-month-view .e-work-days {
  background: #fff;
}

#ScheduleDiv .e-schedule .e-month-view .e-content-wrap {
  /* height: calc(100vh - 524px); */
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
#ScheduleDiv .fromDialogCss .e-month-view .e-content-wrap {
  height: calc(100vh - 465px);
}
.custom-selected-cell {
  background-color: rgb(193 253 220 / 30%) !important;
  border: 1px solid #72d1a1 !important;
  border-radius: 3px;
}

/* Hover effect for the entire header, but not affecting the background */
.e-schedule .e-month-view .e-date-header:hover {
  text-decoration: underline;
}

#calendarSeclector {
  position: relative !important;
  overflow: auto;
  scrollbar-width: thin;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* leave dlg */
.fa-plane-up {
  transform: rotate(51.02deg);
}

#dlgLeaveApplication {
  padding: 20px !important;
  padding-bottom: 0px !important;
}

#dlgLeaveApplication .e-dlg-header-content {
  border-bottom: 1px solid #e9e9f2;
  margin-bottom: 10px;
}

#dlgLeaveApplication label {
  font-size: 1rem;
}

#dlgLeaveApplication .lvbalance {
  border: none;
  width: 35%;
  font-size: 1rem;
  color: #fc4c02;
  text-align: end;
}

#dlgLeaveApplication .lvRequested {
  border: none;
  font-size: 1rem;
  color: #fc4c02;
}

.e-float-input,
.e-float-input.e-control-wrapper {
  padding-top: 6px !important;
}

#dlgLeaveApplication .cancel-button {
  background-color: transparent;
  color: #363636;
  padding: 14px 24px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
  border: none;
  margin-right: 5px;
}

#dlgLeaveApplication .cancel-button:hover {
  background-color: #ddd;
}

#dlgLeaveApplication .save-button {
  background-color: #fc4c02;
  border: none;
  color: #fff;
  padding: 13px 24px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
}

/* Leave Summary styles */

.leavetab-summary-row {
  display: flex;
  align-items: center;
  background-color: #fff7f4;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
}

/* Individual category styles */
.leavetab-category {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.leavetab-color-indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
}

.leavetab-sick .leavetab-color-indicator {
  background-color: #5dade2;
}

.leavetab-unpaid .leavetab-color-indicator {
  background-color: #f5b041;
}

.leavetab-half .leavetab-color-indicator {
  background-color: #76d7c4;
}

.leavetab-other .leavetab-color-indicator {
  background-color: #af7ac5;
}

/* Total leave styles */
.leavetab-total {
  margin-left: auto;
}

.leavetab-label {
  color: #363636;
}
.leavetab-total-count {
  margin-left: 5px;
  color: #fc4c02;
}
