/* Base Styles */
#dashboardcenterpanel .sample_container.card_sample {
  margin: auto;
  min-height: 400px;
}

#dashboardcenterpanel .text-border-radius {
  width: 95%;
  height: auto;
  border-radius: 5px;
  border: 1px solid #dddad9;
  margin: auto;
  display: block;
}

/* #dashboardcenterpanel .text-border-radius:hover {
  border: 1px solid #fc4c02;
} */

/* Card Customizations */
#dashboardcenterpanel .sample_container.card_sample .e-custom-card {
  position: relative;
  overflow: visible;
  transition: 0.2s;
  border-width: 1px;
  padding: 1px;
}

#dashboardcenterpanel
  .sample_container.card_sample
  .e-custom-card.bordermargincolor {
  border-top: 80px solid gray;
}

#dashboardcenterpanel .e-custom-card.e-card:hover {
  border-width: 1px;
  padding: 1px;
}

#dashboardcenterpanel .e-custom-card :nth-child(2) .e-card-header-title.name {
  margin-top: 20px;
}

#dashboardcenterpanel .sample_container p.avatar-content {
  line-height: 20px;
  font-family: 'Roboto', sans-serif;
}

#dashboardcenterpanel
  .sample_container.card_sample
  .e-custom-card
  .e-card-header {
  justify-content: left;
}

/* #dashboardcenterpanel .sample_container.card_sample .e-custom-card .e-avatar {
  font-size: 40px;
  position: absolute;
  top: calc(0% - 1.5em);
  left: calc(50% - 1.5em);
  box-shadow: 0 16px 28px -8px rgba(0, 0, 0, 0.36),
    0 4px 15px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
} */

#dashboardcenterpanel
  .sample_container.card_sample
  .e-card.e-custom-card
  :nth-child(3) {
  padding: 5px 0px 10px 0px;
}

#dashboardcenterpanel
  .sample_container.card_sample
  .e-custom-card.e-card
  .e-card-header
  .e-card-header-caption
  .e-card-header-title {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  color: rgba(83, 101, 132, 0.65);
  text-shadow: 0 0 0.1px;
}

#dashboardcenterpanel
  .sample_container.card_sample
  .e-custom-card.e-card
  .e-card-header
  .e-card-header-caption
  .e-card-sub-title {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: rgba(86, 90, 97, 0.65);
  text-shadow: 0 0 0.1px;
}

.sample_container.card_sample .e-custom-card.e-card .e-card-content-searchbar {
  padding-bottom: 25px;
  word-spacing: 1px;
  box-sizing: border-box;
}

#dashboardcenterpanel .sample_container.card_sample .avatar-content {
  color: rgb(94, 94, 94);
  margin: 0 auto;
  text-align: center;
  border: none;
  padding: 0;
  font-size: 14px;
}

#dashboardcenterpanel .avatar-content {
  margin-bottom: 0;
}

#dashboardcenterpanel .sample_container.card_sample .sample_container .name {
  margin-top: 10px;
}

#dashboardcenterpanel .sample_container.card_sample .arutiintro {
  /* padding: 10px !important; */
  border-radius: 6px !important;
  padding-bottom: 12px !important;
  box-shadow: none !important;
  margin-bottom: 25px;
}

#dashboardcenterpanel .sample_container.card_sample .arutiintro video {
  text-align: center;
  height: auto;
  width: 100%;
}

#dashboardcenterpanel .sample_container.card_sample .arutiintro .arutilogo {
  height: 18px;
}

#dashboardcenterpanel
  .e-outline.e-input-group.e-input-focus:hover:not(.e-success):not(
    .e-warning
  ):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
.e-outline.e-input-group.e-input-focus.e-control-wrapper:hover:not(
    .e-success
  ):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
.e-outline.e-float-input.e-input-focus.e-control-wrapper:hover:not(
    .e-success
  ):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
.e-outline.e-float-input.e-input-focus:hover:not(.e-success):not(
    .e-warning
  ):not(.e-error):not(.e-disabled):not(.e-float-icon-left),
.e-outline.e-input-group.e-input-focus.e-float-icon-left:not(.e-success):not(
    .e-warning
  ):not(.e-error):not(.e-disabled),
.e-outline.e-input-group.e-input-focus.e-control-wrapper.e-float-icon-left:not(
    .e-success
  ):not(.e-warning):not(.e-error):not(.e-disabled),
.e-outline.e-float-input.e-input-focus.e-float-icon-left:not(.e-success):not(
    .e-warning
  ):not(.e-error):not(.e-disabled),
.e-outline.e-float-input.e-input-focus.e-control-wrapper.e-float-icon-left:not(
    .e-success
  ):not(.e-warning):not(.e-error):not(.e-disabled),
.e-outline.e-float-input.e-input-focus:hover:not(.e-input-group):not(
    .e-success
  ):not(.e-warning):not(.e-error):not(.e-disabled),
.e-outline.e-float-input.e-input-focus.e-control-wrapper:hover:not(
    .e-input-group
  ):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-outline.e-input-group.e-float-icon-left.e-input-focus:hover:not(
    .e-success
  ):not(.e-warning):not(.e-error),
.e-outline.e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:hover:not(
    .e-success
  ):not(.e-warning):not(.e-error),
.e-outline.e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(
    .e-error
  ):not(.e-disabled),
.e-outline.e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(
    .e-warning
  ):not(.e-error):not(.e-disabled),
.e-outline.e-float-input.e-input-focus:not(.e-success):not(.e-warning):not(
    .e-error
  ):not(.e-disabled),
.e-outline.e-float-input.e-control-wrapper.e-input-focus:not(.e-success):not(
    .e-warning
  ):not(.e-error):not(.e-disabled),
.e-outline.e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(
    .e-warning
  ):not(.e-error):not(.e-disabled),
.e-outline.e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:not(
    .e-success
  ):not(.e-warning):not(.e-error):not(.e-disabled) {
  border-color: #d3d3d36b;
  box-shadow: 1px 0px 1px 1px #d3d3d36b;
}

/* search panel css */
#dashboardcenterpanel .post-container {
  width: 100%;
  height: auto;
  margin-bottom: 20px !important;
  margin: auto;
  /* display: flex;
  align-items: center;
  justify-content: space-around; */
  padding: 25px;
  padding-bottom: 15px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: none;
}

#dashboardcenterpanel .createpost-body {
  width: 100%;
  height: auto;
  margin-bottom: 30px !important;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;

  /* padding: 25px;  
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

#dashboardcenterpanel .createpost-footer {
  width: 100%;
  height: auto;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding-right: 34px;
  margin-left: 25px;
  /* padding: 25px;  
  border-radius: 5px;
  margin-bottom: 20px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

#dashboardcenterpanel .createpost-footer button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #fc4c02;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

#dashboardcenterpanel .createpost-footer button span {
  font-size: 1rem;
  font-weight: 500;
  margin-left: 10px;
}

#dashboardcenterpanel .createpost-footer button.active::after {
  content: '';
  position: absolute;
  left: 50px;
  top: 31px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fc4c02;
}

#dashboardcenterpanel .e-carousel-navigators button.e-btn {
  background-color: rgba(255, 255, 255, 0.3);
}

#dashboardcenterpanel .e-carousel-navigators .e-previous .e-btn-icon {
  font-size: 18px;
  color: rgb(252, 76, 2);
}

#dashboardcenterpanel .e-carousel-navigators .e-next .e-btn-icon {
  font-size: 18px;
  color: rgb(252, 76, 2);
}

#dashboardcenterpanel
  .e-carousel
  .e-carousel-indicators
  .e-indicator-bars
  .e-indicator-bar.e-active
  .e-indicator
  div {
  background-color: rgb(252, 76, 2);
  border-color: rgb(252, 76, 2);
}

#dashboardcenterpanel
  .e-carousel
  .e-carousel-indicators
  .e-indicator-bars
  .e-indicator-bar
  .e-indicator
  div {
  background-color: lightgray;
  border-color: #e9e9f2;
}

#dashboardcenterpanel
  .e-carousel
  .e-carousel-indicators
  .e-indicator-bars
  .e-indicator-bar {
  padding: 0px !important;
}

#dashboardcenterpanel .e-carousel .imagecard {
  display: flex;
  justify-content: center;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-info img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.post-input {
  width: 100%;
  margin-left: 10px;
}
.post-empname {
  font-size: 18px;
  line-height: 42px;
  padding: 10px;
  letter-spacing: 1px;
  font-weight: 500;
}
.post-input .post-create {
  width: calc(100% - 30px);
  border: 1px solid lightgrey;
  padding: 15px;
  border-radius: 35px;
  outline: none;
  font-size: 1rem;
  background-color: #fff;
}

#dashboardcenterpanel .post-input button.post-create {
  background-color: transparent;
  color: #535b86;
  text-transform: none;
  font-weight: 400;
  text-align: left;
  line-height: 21px;
  box-shadow: none;
}

#dashboardcenterpanel .post-input button.post-create:hover {
  background-color: transparent;
  color: #9ea5b5;
  text-transform: none;
  font-weight: 400;
  border-color: #fc4c02;
}

#dashboardcenterpanel .post-actions {
  /* margin-top: 45px; */
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.post-actions button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.3125rem;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* posted css */
#dashboardcenterpanel .posted-contain {
  width: 80%;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 10px;
  position: relative;
}
.posted-userimg {
  display: flex;
  align-items: center;
  margin-right: 18px;
  height: 60px;
  width: 60px;
  font-weight: 500;
}
.posted-userimg img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}
.posted-userinfo {
  width: 80%;
  height: auto;
}
.posted-username {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.3rem;
}
.posted-days {
  display: block;
  font-size: 0.875rem;
  color: #7c7c7c;
}
.favorite-post {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.posted-like {
  font-size: 22px;
  color: red !important;
  display: inline-block;
  margin-right: 10px;
}
#dashboardcenterpanel .e-card-header-caption {
  padding: 12px;
  width: 95%;
  margin: auto;
}

#dashboardcenterpanel .e-card-header-title-name {
  font-size: 1rem;
  line-height: 1.171875;
  /* margin-top: 6px; */
}
#dashboardcenterpanel .e-card-sub-title {
  font-size: 13px;
  /* line-height: 32px; */
  /* margin-top: 6px; */
}
#dashboardcenterpanel .e-card-content {
  width: 98%;
  margin: auto;
}
#dashboardcenterpanel .e-card-content video {
  width: 100%; /* Make the video fill the width of the container */
  height: auto; /* Adjust height to match the container */
  object-fit: cover; /* Ensures the video covers the container without distortion */
  display: block;
}

#dashboardcenterpanel .usercreatepost {
  /* border: 1px solid rgb(252, 76, 2); */
  color: rgb(252, 76, 2);
  height: 3.75rem;
  width: 3.75rem;
  font-size: 16px;
  font-weight: 500;
}

#dashboardcenterpanel .more-options {
  position: absolute;
  right: 30px;
  padding: 5px;
  cursor: pointer;
  color: #535b86;
}

#dashboardcenterpanel .more-options .ellipsis {
  font-size: 20px;
  font-weight: 500;
}
