.period-selector {
  /* width: 580px !important; */
  /* width: 390px !important;
  min-width: 300px !important;
  margin: auto;
  display: flex; */
  /* justify-content: center; */
  /* align-items: center;
  border-radius: 5px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* color: #fc4c02; */
}

.periodSelector-input {
  border: none !important;
  font-size: 0.875rem;
  /* width: auto; */
  font-weight: 300;
  min-width: 75%;
  padding: 5px;
  background-color: transparent;
}

.periodSelector-icons {
  cursor: pointer;
}

.periodSelector-dropdownIcons {
  /* margin-left: -3%; */
}

.period-selector-disabled {
  pointer-events: none;
  color: gainsboro;
}

input.larger {
  margin: 4px 0px 0px 4px;
  transform: scale(1.3);
}

/* Style the checkbox container */
.checkbox-container {
  width: 3%;
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 18px;
  user-select: none;
  margin: auto;
}

/* Hide the default checkbox */
.checkbox-container input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkbox-container .custom-checkbox {
  position: relative;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 4px;
  transition: all 0.2s;
  display: inline-block;
}

/* When the checkbox is checked, change the background to orange */
.checkbox-container input[type='checkbox']:checked ~ .custom-checkbox {
  background-color: #fc4c02;
  border-color: #fc4c02;
}

/* Add a checkmark */
.checkbox-container .custom-checkbox::after {
  content: '';
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Show the checkmark when checked */
.checkbox-container input[type='checkbox']:checked ~ .custom-checkbox::after {
  display: block;
}
/* ------------ */
.close-toolbar-btn .e-tbar-btn-text {
  font-weight: 500 !important;
  color: brown !important;
}

.close-toolbar-ico .e-icons {
  color: brown !important;
}

/* #payslip-periodcontextmenu {
  width: 18vw;
} */
/* .e-menu-parent .e-ul {
  width: 170px;
} */

#attendance-selectorcontextmenu.e-contextmenu,
ul.e-menu-parent.e-ul {
  width: 200px;
  border-radius: 5px !important;
  box-shadow: 0 5px 5px -3px rgba(202, 201, 201, 0.22),
    0 8px 10px 1px rgba(133, 132, 132, 0.14),
    0 3px 14px 2px rgba(128, 127, 127, 0.12) !important;
}
.periodIcons {
  cursor: pointer;
  font-size: 18px;
}
.NewPeriodOptionClass {
  width: auto;
  background: #fff !important;
  border-radius: 50px;
  margin: 2px;
  color: #fc4c02 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px !important;
  margin: 10px !important;
  transition: 0.3s linear all;
}
.NewPeriodOptionClass:hover {
  background: #fc4c02 !important;
  color: #fff !important;
}
#attendance-selectorcontextmenu
  .e-contextmenu-wrapper
  .e-menu-parent
  .e-menu-item {
  height: unset !important;
}
#attendance-selectorcontextmenu
  .e-contextmenu-wrapper
  .e-menu-parent
  .e-menu-item:hover {
  background: #fc4c02 !important;
  color: #fff !important;
}

.NewPeriodOptionClass:hover ul .e-menu-item.e-focused {
  color: #fff;
}
#attendance-selectorcontextmenu .e-menu-item.e-selected {
  background-color: #fbf4ee !important;
  color: #fc4c02 !important;
}
#attendance-selectorcontextmenu .e-menu-item.e-selected .e-caret {
  color: #fc4c02 !important;
}
#attendance-selectorcontextmenu .e-menu-item.e-separator {
  margin: 2px 0px !important;
  height: 0px !important;
}
/* sub DropDown */
.e-contextmenu-wrapper .e-menu-parent .e-menu-item {
  height: 42px !important;
}
.e-contextmenu-wrapper .e-menu-parent .e-menu-item:hover,
.e-contextmenu-wrapper .e-menu-parent .e-menu-item.e-focused {
  background-color: #fbf4ee;
  color: #fc4c02;
}
.periodText {
  width: fit-content;
  color: #fc4c02;
  font-size: 0.875rem;
  margin: 0px -5px 0px 10px;
}
