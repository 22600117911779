.Fileupload-section {
  border: 2px dashed #ccc;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  background: #fbeee84d;
  /* margin-top: 10px; */
}

.Fileupload-header {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.Fileupload-section:hover {
  border: 2px dashed #fc4c02;
}

.fa-cloud-upload-alt {
  display: block;
  font-size: 20px;
  color: #ced0db;
}

.Fileupload-drop-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.Fileupload-drop-area .Fileupload-text .Fileupload-body-text {
  width: 250px !important;
}

.Fileupload-drop-area .Fileupload-text .Fileupload-body-text span {
  line-height: 30px !important;
}

.Fileupload-text {
  color: #666;
  font-size: 14px;
}

.Filebrowse-link {
  color: #fc4c02;
  font-weight: 600;
  cursor: pointer;
}

.Fileupload-info {
  font-size: 14px;
  color: #999;
  margin-top: 10px;
}

#dlguploadeddialog .e-dlg-header-content {
  border-bottom: 1px solid #e9e9e9;
  margin: 0px 16px;
  border-radius: 0px !important;
}

#dlguploadeddialog .e-dlg-content {
  padding: 27px !important;
  padding-top: 0px !important;
}

#dlguploadeddialog {
  min-height: 325px !important;
}

#dlguploadGriddialog {
  min-height: 500px !important;
  padding: 10px !important;
}

#dlguploadGriddialog .e-dlg-content {
  padding: 0px 27px !important;
}

#dlguploadGriddialog .e-dlg-content>div {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100% - 0.9375rem);
}

#dlguploadGriddialog .e-dlg-header-content {
  border-bottom: 1px solid #e9e9e9;
  margin: 0px 16px;
  border-radius: 0px !important;
}

#dlguploadGriddialog .e-dlg-content .dlgUploadHeaderText,
#dlguploadeddialog .e-dlg-content .dlgGenerateFileHeader {
  font-size: 14px;
}

#dlguploadGriddialog .e-dlg-content .dlguploadHeaderDwlTemplate,
#dlguploadeddialog .e-dlg-content .dlgGenerateFileDwlHeader {
  text-align: right;
}

#dlguploadGriddialog .e-dlg-content .dlguploadHeaderDwlTemplate .dlguploadHeaderDwlbutton,
#dlguploadeddialog .e-dlg-content .dlgGenerateFileDwlHeader .dlgGenerateFileDwlbutton {
  color: #FC4C02;
  border: 1px black;
  background-color: transparent;
}

#dlguploadGriddialog .e-dlg-content .dlguploadGridContent {
  height: calc(100% - 15.863rem);
  overflow: auto;
}

.Gridupload-section {
  border: 2px dashed #ccc;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  background: #fbeee84d;
  height: 106px;
  margin-bottom: 10px;
}

.Gridupload-header {
  font-size: 16px;
  font-weight: 400;
  color: #363636;
  /* margin-bottom: 10px; */
}

.Gridupload-drop-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  /* cursor: pointer; */
}

.Gridupload-section:hover {
  border: 2px dashed #fc4c02;
}

.GridUploadFileName {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.fa-trash-can {
  color: #535B86;
}

.fa-trash-can:hover {
  color: #fc4c02;
  cursor: pointer;
}

#dlguploadGriddialog .btnCancel {
  color: #363636 !important;
  padding: 14px 0px 12px 0px !important;
  border-radius: 50px !important;
  border: none !important;
  cursor: pointer !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
  box-shadow: none !important;
  background: transparent !important;
  width: 95px;
}

#dlguploadGriddialog .btnCancel:hover {
  background-color: #E9E9F2 !important;
}

#dlguploadGriddialog .btnUpload {
  background-color: #fc4c02 !important;
  border: none !important;
  color: #FFFFFF !important;
  padding: 14px 20px 12px 18px !important;
  border-radius: 50px !important;
  cursor: pointer;
  text-transform: capitalize !important;
  font-size: 14px !important;
  box-shadow: none !important;
}

#dlguploadGriddialog .btnUpload:hover {
  background-color: #e64a19;
}

.uploadbtnicon {
  color: #fff !important;
}

#payrollemployeelist-grid .e-content {
  overflow: auto !important;
}

#payrollemployeelist-grid .e-rowcell {
  padding: 8px 12px !important;
}

.DownloadCSVIcon {
  font-size: 18px;
  padding-right: 8px;
}

.dialogfootersection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
  padding-top: 10px;
  width: fit-content !important;
}

.dialogfootersection .footerbtn {
  display: inline-block;
  width: 106px;
}

#payrollemployeelist-grid .e-gridheader {
  border-bottom-color: #bdb7b7 !important;
  border-top-color: #fefefe !important;
  font-size: 16px !important;
  color: #fc4c02 !important;
  /* padding: 0px 10px !important; */
  padding-right: unset !important;
}

#payrollemployeelist-grid {
  border-color: #fefefe !important;
}

#payrollemployeelist-grid .e-headercelldiv {
  font-size: 15px !important;
}

#payrollemployeelist-grid .e-rowcell {
  line-height: 32px;
  font-size: 14px;
}

#payrollemployeelist-grid .img-temp {
  color: #fc4c02 !important;
  font-size: 20px;
}

#payrollemployeelist-grid th.e-headercell[aria-sort='ascending'] .e-headertext,
#payrollemployeelist-grid th.e-headercell[aria-sort='descending'] .e-headertext {
  color: #fc4c02 !important;
}

#payrollemployeelist-grid .e-gridheader .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop):not(.e-icon-reorderuparrow):not(.e-icon-reorderdownarrow) {
  color: #fc4c02;
}

#payrollemployeelist-grid.e-gridhover tr[role='row']:not(.e-disable-gridhover):not(.e-editedrow):not(.e-detailrow):hover .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) .command-identity,
#payrollemployeelist-grid td.e-active .command-identity {
  visibility: visible;
}

/* #payrollemployeelist-grid.e-grid .e-headercontent {
  position: sticky; /* Ensures the header remains fixed 
  top: 0; /* Fix the header at the top of the grid 
  z-index: 10; /* Ensures the header is above the scrollable content 
  background-color: #fff; /* Optional: Ensure the header has a solid background 
  border-bottom: 1px solid #ccc; /* Optional: Add a separator for clarity 
} */

/* #payrollemployeelist-grid.e-grid .e-content {
  max-height: 300px;
}

#payrollemployeelist-grid.e-grid .e-gridcontent .e-content .e-movablecontent {
  max-height: 100%;
} */

.StatusContent {
  width: 90%;
  height: 24px;
  padding: 2px 9px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Uploadstatus {
  /* height: 36px; */
  border-radius: 50px;
  text-align: center;
}

.Uploadtext.Successfulcolor {
  color: #1abf9b;
}

.Uploadstatus.Warningcolor {
  background-color: #FCB318;
  height: 100%;
  padding: 15px 15px;
}

.Uploadtext.Warningcolor {
  color: #FFFFFF
}

.Uploadstatus.Successfulcolor {
  background-color: #e0fff8;
  height: 100%;
  padding: 15px 15px;
}

.Uploadtext.NotValidcolor {
  color: #fc4c01;
}

.Uploadstatus.NotValidcolor {
  background-color: #fff9f6;
  height: 100%;
  padding: 15px 15px;
}

.Uploadstatus.NotValidcolor,
.Uploadstatus.Successfulcolor,
.Uploadstatus.Warningcolor {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.Uploadstatus.NotValidcolor i.iconfailed {
  color: #fc4c01;
}

.Uploadstatus.Successfulcolor i.iconsuccess {
  color: #1abf9b;
}

.Uploadstatus.Warningcolor i.iconwarning {
  color: #FFFFFF;
}

.footersection {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  position: absolute !important;
  bottom: 15px !important;
  width: 100% !important;
  padding-right: 60px !important;
}

.SwitchContent {
  width: 'fit-content' !important;
}

#payrollemployeelist-grid .e-gridheader .e-headercontent .e-movableheader tr.e-columnheader th div.e-filtermenudiv.e-icons.e-icon-filter,
.e-gridheader .e-headercontent .e-frozenheader tr.e-columnheader th div.e-filtermenudiv.e-icons.e-icon-filter {
  display: none !important;
}

#showErrorCheckboxVisibility.Visibility {
  visibility: hidden !important;
}

#payrollemployeelist-grid .e-gridheader table thead tr.e-columnheader th.e-headercell .inTimeUploadTemplate,
#payrollemployeelist-grid .e-gridheader table thead tr.e-columnheader th.e-headercell .outTimeUploadTemplate {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

#payrollemployeelist-grid .e-gridheader table thead tr.e-columnheader th.e-headercell .inTimeUploadTemplate i,
#payrollemployeelist-grid .e-gridheader table thead tr.e-columnheader th.e-headercell .outTimeUploadTemplate i {
  font-size: 16px;
}

#payrollemployeelist-grid .e-gridcontent table tbody tr td div.InTimeData,
#payrollemployeelist-grid .e-gridcontent table tbody tr td div.OutTimeData {
  display: flex;
  justify-content: center;
}

#payrollemployeelist-grid .e-gridcontent table tbody tr td div.InTimeData div#inTimeValue,
#payrollemployeelist-grid .e-gridcontent table tbody tr td div.OutTimeData div#outTimeValue {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 87px;
  height: 30px;
  border-radius: 50px;
  border: 1px solid rgb(252, 76, 1);
  padding: 7px 10px 5px;
  color: rgb(252, 76, 1);
}

#payrollemployeelist-grid .e-gridcontent .e-scrollbar .e-movablescrollbar {
  scrollbar-width: thin;
}

#payrollemployeelist-grid .e-gridcontent .e-scrollbar .e-frozenscrollbar.e-frozen-right-scrollbar {
  border-top: 0px !important;
}

#payrollemployeelist-grid .e-gridcontent .e-content .e-movablecontent tr td.e-rowcell,
#payrollemployeelist-grid .e-gridcontent .e-content .e-frozencontent tr td.e-rowcell {
  border-color: transparent !important;
}

#payrollemployeelist-grid .e-gridcontent .e-content .e-movablecontent tr td.e-rowcell {
  border-right: 1px solid #e0e0e0 !important;
}