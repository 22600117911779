/* Base Styles */
#dashboardprofile .sample_container.card_sample {
  margin: auto;
  padding-left: 1px;
  width: 100%;
}

#dashboardprofile .sample_container .e-card-header {
  color: #363636;
}

/* Card Customizations */
#dashboardprofile .sample_container.card_sample .e-custom-card {
  position: relative;
  overflow: visible;
  transition: 0.2s;
  border-width: 1px;
  padding: 1px;
  border-radius: 6px !important;
  box-shadow: none !important;
}

#dashboardprofile .sample_container.card_sample .e-custom-card.bordermargincolor {
  /* border-top: 80px solid lightgray; */
  border-radius: 6px !important;
  box-shadow: none !important;
}

#dashboardprofile .sample_container.card_sample .div-img {
  /* background: #f3f4f9 !important; */
}

#dashboardprofile .sample_container.card_sample .person_logo {
  position: absolute;
  top: 15%;
}

.div-profile-bg-img {
  width: 100%;
  height: auto;
}

#dashboardprofile .e-avatar {
  font-size: 24px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: #fae0d4 !important;
  font-weight: 600;
  letter-spacing: 1px;
}

#dashboardprofile .peron_profile_name {
  margin-top: 40px;
  padding: 10px;
}

#dashboardprofile .e-card-header-title-name {
  font-size: 26px !important;
  letter-spacing: 2px;
  color: #535b86;
  font-weight: 600;
}

#dashboardprofile .e-card-sub-title {
  font-size: 16px !important;
  letter-spacing: 1px;
  line-height: 35px;
  color: #555555;
}

#dashboardprofile .e-card-In-Out-Time {
  font-size: 13px !important;
  letter-spacing: 1px;
  line-height: 40px;
  color: #fc4c02 !important;
  font-weight: 700;
}

/*
#dashboardprofile .e-custom-card.e-card:hover {
  border-width: 1px;
  padding: 1px;
}

#dashboardprofile .e-custom-card :nth-child(2) .e-card-header-title.name {
  margin-top: 20px;
}

#dashboardprofile .e-custom-card :nth-child(2) .e-card-In-Out-Time {
  font-weight: bold;
}

#dashboardprofile .sample_container p.avatar-content {
  line-height: 20px;
  font-family: inherit;
}

#dashboardprofile .sample_container.card_sample .e-custom-card .e-card-header {
  justify-content: left;
  font-size: 16px;
}

#dashboardprofile .sample_container.card_sample .e-custom-card .e-avatar {
  font-size: 40px;
  position: absolute;
  top: calc(0% - 1.5em);
  left: calc(50% - 1.5em);
  background-color: #feddce;
  box-shadow: 0 16px 28px -8px rgba(0, 0, 0, 0.36),
    0 4px 15px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

#dashboardprofile
  .sample_container.card_sample
  .e-card.e-custom-card
  :nth-child(3) {
  padding: 5px 0px 10px 0px ---;
} */

/* #dashboardprofile
  .sample_container.card_sample
  .e-custom-card.e-card
  .e-card-header
  .e-card-header-caption
  .e-card-header-title {
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  color: rgba(83, 101, 132, 0.65);
  text-shadow: 0 0 0.1px;
}

#dashboardprofile
  .sample_container.card_sample
  .e-custom-card.e-card
  .e-card-header
  .e-card-header-caption
  .e-card-sub-title {
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  color: rgba(86, 90, 97, 0.65);
  text-shadow: 0 0 0.1px;
}

.sample_container.card_sample .e-custom-card.e-card .e-card-content {
  overflow: visible;
  width: auto;
  margin: -5px 20px 0 20px;
  word-spacing: 1px;
}

#dashboardprofile .sample_container.card_sample .avatar-content {
  color: rgb(94, 94, 94);
  margin: 0 auto;
  text-align: center;
  border: none;
  padding: 0;
  font-size: 14px;
}

#dashboardprofile .avatar-content {
  margin-bottom: 0;
}

#dashboardprofile .sample_container.card_sample .sample_container .name {
  margin-top: 10px;
} */

.setLeavebtn {
  font-size: 14px !important;
  text-transform: capitalize !important;
  border-radius: 50px !important;
  border: 1px solid !important;
  position: relative !important;
  z-index: 1 !important;
  transition: 0.2s ease !important;
  background-color: #fc4c02 !important;
  color: #fff !important;
  padding: 9px 20px !important;
  box-shadow: none;
}

.setLeavebtn:hover {
  color: #fc4c02 !important;
  background-color: #fefefe !important;
}

@media screen and (max-width: 768px) {
  #dashboardprofile .sample_container.card_sample .e-custom-card {
    padding-right: 10px;
  }

  #dashboardprofile .sample_container.card_sample {
    width: 90%;
    margin: 20px auto;
  }

  .setLeavebtn {
    padding: 9px 20px !important;
  }
}

i.fa-regular.fa-pen-to-square {
  position: absolute;
  padding: 5px;
  border-radius: 8px;
  font-size: 22px;
  color: #fc4c02;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
