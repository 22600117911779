#payrollemployeelist-grid {
  width: 100%;
  overflow: auto;
}

#payrollemployeelist-grid .e-gridcontent {
  width: 100%;
}

#payrollemployeelist-grid.minwidth .e-gridcontent {
  width: 300px;
}

#tabpayrolllist > .e-content {
  padding-left: 18px;
  padding-right: 12px;
  height: 100% !important;
}

#PivotView #PivotView_grid {
  width: 100% !important;
}

#PivotView .e-gridcontent {
  /* height: calc(100vh - 567px); */
  overflow: auto;
}

#PivotView .e-content {
  height: calc(100vh - 404px) !important;
}

#PivotView .close-toolbar-ico .e-icons {
  color: black !important;
  background-color: transparent !important;
}

.e-pivotview .e-pivot-toolbar {
  padding: 0px !important;
  margin: 0px !important;
  border: none;
}

.e-pivotview .e-collapse::before {
  content: '\f077 ' !important;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #fc4c02;
  font-size: 1rem;
}

.e-pivotview .e-expand::before {
  content: '\e36a ' !important;
  font-weight: 900;
  color: #fc4c02;
  font-size: 1rem;
}

.e-pivotview .e-toolbar-chart::before {
  content: '\f200';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #fc4c02;
  font-size: 1rem;
}

.e-pivotview .e-toolbar-fieldlist::before {
  font-size: 1.125rem;
}

#PivotView.e-pivotview #PivotViewchart_menu {
  margin-top: -4px;
}

.divpayrollparent .summaryTotalRecords {
  position: absolute;
  right: 3rem;
  top: 0.75rem;
  z-index: 1;
  font-size: 0.8125rem;
  font-weight: 400;
  letter-spacing: 0.02px;
  color: #363636;
  border-right: 1px solid #e9e9f2;
  padding-right: 8px;
}
.divpayrollparent .summaryTotalRecords .summaryRowCount {
  color: #fc4c01;
  font-size: 0.875rem;
}

.divpayrollparent .exclusionTotalRecords {
  position: absolute;
  right: 3rem;
  top: 0.75rem;
  z-index: 1;
  font-size: 0.8125rem;
  font-weight: 400;
  letter-spacing: 0.02px;
  color: #363636;
  border-right: 1px solid #e9e9f2;
  padding-right: 8px;
}
.divpayrollparent .exclusionTotalRecords .exclusionRowCount {
  color: #fc4c01;
  font-size: 0.875rem;
}

.divpayrollparent .payrollexclusion-grid {
  height: 100%;
  border: none;
  display: flex;
  flex-direction: column;
}

.divpayrollparent .payrollexclusion-grid .e-gridcontent {
  height: calc(100% - 111px);
  margin-top: 12px;
  border-top: 1px solid #e4e5e9;
}

.divpayrollparent .payrollexclusion-grid .e-gridcontent .e-content {
  scrollbar-width: thin;
}

.divpayrollparent .payrollexclusion-grid .e-toolbar {
  background-color: transparent;
  border: none;
  margin-bottom: 0.875rem;
}

.divpayrollparent .payrollexclusion-grid .employee-list-grid {
  display: flex;
  padding: 10px;
  position: relative;
  align-items: center;
  height: 2rem;
}

.divpayrollparent .payrollexclusion-grid .employee-list-grid .empImg {
  /* width: 1.875rem;
  height: 1.875rem; */
  margin: auto 3px auto 0px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.divpayrollparent .payrollexclusion-grid .employee-list-grid .empImg img {
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50px;
  border: 2px solid white;
  box-shadow: 2px 2px 10px 2px rgb(219, 219, 219);
  object-fit: cover;
}

.divpayrollparent .payrollexclusion-grid .emp-list-details {
  width: 100%;
  padding: 0px 10px;
  padding-left: 2.625rem;
}

.divpayrollparent .payrollexclusion-grid .emp-list-details span {
  font-size: 0.875rem;
  font-weight: 400;
  color: #363636;
}

.divpayrollparent
  .payrollexclusion-grid
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-item {
  border: 1px solid #fc4c01 !important;
  border-radius: 50px !important;
  margin: 0px 5px;
  padding: 2px 1rem;
  min-height: 2.375rem;
}

.divpayrollparent
  .payrollexclusion-grid
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-item
  .e-tbar-btn-text {
  font-size: 0.875rem;
  font-weight: 500;
  color: #fc4c01;
}

.divpayrollparent
  .payrollexclusion-grid
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-item
  .e-tbar-btn-text:hover {
  color: #fff !important;
}

.divpayrollparent
  .payrollexclusion-grid
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-item:hover,
.divpayrollparent
  .payrollexclusion-grid
  .e-toolbar
  .e-toolbar-items
  .e-toolbar-item:hover
  .e-tbar-btn {
  background-color: #fc4c02 !important;
}

.divpayrollparent .payrollexclusion-grid .e-gridheader {
  border: none;
}

.divpayrollparent .payrollexclusion-grid .e-content .e-rowcell {
  padding-left: 10px;
  padding-right: 10px;
}

.divpayrollparent
  .payrollexclusion-grid
  .e-content
  .e-rowcell:not([data-colindex='0'], [data-colindex='1']) {
  padding-left: 8px;
}

.divpayrollparent .payrollexclusion-grid .e-checkbox-wrapper .e-frame {
  border-radius: 12px;
  background-color: #fc4c01;
}

.divpayrollparent
  .payrollexclusion-grid
  .e-checkbox-wrapper
  .e-frame:not(.e-check, .e-stop) {
  border: none;
  background-color: #e9e9f2;
}

.divpayrollparent .payrollexclusion-grid .e-checkbox-wrapper .e-frame.e-stop {
  background-color: #fc4c01;
  color: #fff;
}

.divpayrollparent
  .payrollexclusion-grid
  .e-headercontent
  .e-headercell
  .e-headertext {
  font-size: 0.8125rem;
  font-weight: 500;
  color: #fc4c01;
}
.divpayrollparent #payrolllist-splitter {
  margin-top: 0.375rem;
}
.payroll-header-label {
  width: 100%;
  letter-spacing: 2px;
  background: linear-gradient(
    to right,
    #615e5d0a 0%,
    #a5a3a03c 51%,
    #48464516 100%
  );
  padding: 16px 20px;
  color: #000;
  font-size: 20px;
  font-weight: 600;
  border-radius: 8px 8px 0 0;
  margin: 12px 0px;
}

#divpayroll .period-selector {
  width: 480px;
  min-width: 300px;
  background-color: #f4f1f154;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 20px;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
}

#divpayroll .periodSelector-icons {
  cursor: pointer;
  color: #fc4c02;
  transition: color 0.3s;
}

#divpayroll .periodSelector-input {
  border: none;
  background: none;
  width: 100%;
  font-size: 14px;
  padding: 0 10px;
  outline: none;
}

#divpayroll .periodSelector-dropdownIcons {
  color: #fc4c02;
  cursor: pointer;
}

@media (max-width: 768px) {
  #PivotView .e-gridcontent {
    height: calc(100vh - 395px);
  }

  .payrollemployeelist-grid-class {
    width: 100% !important;
    height: calc(-530px + 100vh) !important;
    scrollbar-width: none;
  }
}
