/* Base Styles */
#dashboardrightpanel .sample_container.card_sample {
  /* Base styles here if any */
}

/* Card Customizations */
#dashboardrightpanel .sample_container.card_sample .e-custom-card {
  position: relative;
  overflow: visible;
  transition: 0.2s;
  /* border-width: 1px; */

  padding: 1px;
}

#dashboardrightpanel
  .sample_container.card_sample
  .e-custom-card.bordermargincolor {
  border-top: 80px solid gray;
}

#dashboardrightpanel .e-panel-header {
  width: 92%;
  min-height: 70px;
  margin: auto;
  padding: 0px 18px;
  border-bottom: 1px solid #E9E9F2;
}

#dashboardrightpanel .e-custom-card.e-card:hover {
  border-width: 1px;
  padding: 1px;
}

#dashboardrightpanel .e-custom-card :nth-child(2) .e-card-header-title.name {
  margin-top: 20px;
}

#dashboardrightpanel .sample_container p.avatar-content {
  line-height: 20px;
  font-family: 'Roboto', sans-serif;
}

#dashboardrightpanel
  .sample_container.card_sample
  .e-custom-card
  .e-card-header {
  justify-content: left;
}

#dashboardrightpanel .sample_container.card_sample .e-custom-card .e-avatar {
  font-size: 40px;
  position: absolute;
  top: calc(0% - 1.5em);
  left: calc(50% - 1.5em);
  box-shadow: 0 16px 28px -8px rgba(0, 0, 0, 0.36),
    0 4px 15px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.e-dashboardlayout.e-control .e-panel.e-panel-transition {
  width: 100% !important;
  margin-bottom: 10%;
}

#dashboardrightpanel
  .sample_container.card_sample
  .e-card.e-custom-card
  :nth-child(3) {
  /* Base styles here if any */
}

#dashboardrightpanel
  .sample_container.card_sample
  .e-custom-card.e-card
  .e-card-header
  .e-card-header-caption
  .e-card-header-title {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  color: rgba(83, 101, 132, 0.65);
  text-shadow: 0 0 0.1px;
}

#dashboardrightpanel
  .sample_container.card_sample
  .e-custom-card.e-card
  .e-card-header
  .e-card-header-caption
  .e-card-sub-title {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: rgba(86, 90, 97, 0.65);
  text-shadow: 0 0 0.1px;
}

.sample_container.card_sample .e-custom-card.e-card .e-card-content {
  overflow: visible;
  width: auto;
  margin: -5px 20px 0 0;
  word-spacing: 1px;
  padding-left: 20px;
}

#dashboardrightpanel .sample_container.card_sample .avatar-content {
  color: rgb(94, 94, 94);
  margin: 0 auto;
  text-align: center;
  border: none;
  padding: 0;
  font-size: 14px;
}

#dashboardrightpanel .avatar-content {
  margin-bottom: 0;
}

#dashboardrightpanel .sample_container.card_sample .sample_container .name {
  margin-top: 10px;
}

#dashboardrightpanel {
  width: 100%;
  margin: auto;
}

#rightpanel_dashboard {
  margin-bottom: 20px;
}

#rightpanel_dashboard .e-panel-content {
  overflow: visible;
}

/* Team Member CSS */
#teammembers #memberCount {
  padding: 1px;
  padding-top: 2px;
}

#teammembers .e-badge.spancount {
  margin-left: 10px;
}

#teammembers #team-member {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .sample_container.card_sample .e-custom-card.e-card .e-card-content {
    padding-left: 20px;
  }

  #dashboardrightpanel {
    width: 90%;
  }

  .e-dashboardlayout.e-control .e-panel.e-panel-transition {
    height: 400px !important;
  }

  #rightpanel_dashboard .e-panel-content {
    height: calc(100% - 93.5938px) !important;
  }

  .stickydivprev {
    top: 35% !important;
  }

  .stickydiv {
    top: 70% !important;
  }

  #rightpanel_dashboard {
    /* height: 1450px !important; */
    height: 1250px !important;
    margin: 20px auto;
    margin-bottom: 50% !important;
  }
}

.e-dashboardlayout.e-control .e-panel .e-panel-container .e-panel-header {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 16px;
  letter-spacing: 1px !important;
}

.e-dashboardlayout.e-control
  .e-panel
  .e-panel-container
  .e-panel-header
  .fa-regular {
  font-size: 20px;
}

.e-dashboardlayout.e-control
  .e-panel
  .e-panel-container
  .e-panel-header
  .fa-solid {
  font-size: 18px;
}

/* upcoming header */
.upcoming-event-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .show-down-arrow {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.show-down-arrow1 {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.show-down-arrow3 {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.show-down-arrow4 {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.show-down-arrow5 {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.54) !important;
} */

#accordion-reminder {
  height: auto;
  overflow: hidden;
  transition: height 0.3s ease;
}

#accordion-reminder:not(.expanded) {
  height: 0;
}

#divupcomingeventlist {
  height: auto;
  overflow: hidden;
  transition: height 0.3s ease;
}

#divupcomingeventlist:not(.expanded) {
  height: 0;
}

#divteammemberlist {
  height: auto;
  /* Allow content to display fully by default */
  overflow: hidden;
  transition: height 0.3s ease;
  /* Smooth height transition */
}

#divteammemberlist:not(.expanded) {
  height: 0;
  /* Collapse when not expanded */
}

.rightpanel-card {
  width: 100% !important;
  background: #fefefe;
  overflow: auto !important;
  padding: 13px 10px 13px 10px;
  margin-bottom: 30px !important;
  box-shadow: none !important;
  border-radius: 6px !important;
}
.rightpanel-card .card-header-div {
  width: 95%;
  margin: auto;
  padding: 10px 20px 20px 20px !important;
  border-bottom: 1px solid #e9e9f2;
}
.rightpanel-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 20px 20px !important;
  font-weight: 500;
  font-size: 18px !important;
  color: #363636 !important;
  letter-spacing: 1px !important;
  border-bottom: 1px solid #E9E9F2;
}

.rightpanel-card-content {
  height: 350px !important;
}