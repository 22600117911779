#divChangepassword .row {
  padding-top: 8px;
  padding-bottom: 8px;
}

#divChangepassword .e-footer-content-temp {
  padding: 8px;
  width: 100%;
  text-align: right;
}

#divChangepassword .showchangepassword {
  position: absolute;
  background-color: transparent;
  border: none;
  right: 0.25rem;
  top: 24px;
  z-index: 10;
}

#divChangepassword #chngnewpassword {
  padding-right: 20px;
}

#divChangepassword .inputpassword::-ms-reveal,
#divChangepassword .inputpassword::-ms-clear {
  display: none;
}
