.disablediv {
  pointer-events: none;
  opacity: 0.4;
}

.cellborder {
  border-color: green !important;
  border-radius: 5px;
}

.leave-head-class {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 30px;
}

.e-grid .e-headercell,
.e-grid .e-detailheadercell {
  padding: 10px !important;
}

.estatus {
  position: relative;
  height: 19px;
  border-radius: 5px;
  text-align: center;
}

.estatus.rejectedcolor {
  background-color: #ffd7cc;
  width: 64px;
}

.estatus.approvedcolor {
  background-color: #ccffcc;
  width: 64px;
}

.estatus.pendingcolor {
  background-color: #0dcaf07d;
  width: 64px;
}

.estatus.cancelcolor {
  background-color: #fee2d5;
  width: 64px;
}

.estatustxt.approvedcolor {
  color: #00cc00;
}

.estatustxt.rejectedcolor {
  color: #e60000;
}

.estatustxt.pendingcolor {
  color: #ffffff;
}

.estatustxt.cancelcolor {
  color: #ffffff;
}

/* grouping styles */
.e-grid .e-groupdroparea {
  padding: 14px 15px;
}

.e-grid .e-groupheadercell {
  background-color: #fc4c02;
  border-color: #e0e0e0;
  border-radius: 12px;
  color: #fff;
}

.e-grid .e-groupheadercell:hover {
  background-color: #fc4c02;
  border-color: #000;
}

.e-grid .e-groupdroparea.e-grouped {
  background-color: #e0e0e0;
}

.e-grid .e-groupdroparea.e-hover {
  background-color: #e0e0e0;
}

.e-grid .e-groupdroparea {
  background-color: #e0e0e0;
  border-top-color: #e0e0e0;
  color: #000;
}

/* tab component */
/* .e-tab .e-content {
  margin-top: 10px;
} */

/* .e-tab .e-tab-header .e-toolbar-item::after,
.e-tab .e-tab-header .e-toolbar-item::before {
  content: " " !important;
  pointer-events: none !important;
  display: block !important;
  height: 80% !important;
  width: 100% !important;
  opacity: 0 !important;
}
   .e-tab .e-tab-header .e-toolbar-items .e-toolbar-item.e-active::after {
  box-shadow: -5.3px 0.48em 0 -3px #fc4c02 !important;
  right: -100% !important;
  border-radius: 0 0 0 10px !important;
  opacity: 1 !important;
}

.e-tab .e-tab-header .e-toolbar-items .e-toolbar-item.e-active::before {
  box-shadow: 5.3px 0.48em 0 -3px #fc4c02 !important;
  left: -100% !important;
  border-radius: 0 0 10px 0 !important;
  opacity: 1 !important;
}
 .e-tab.e-focused>.e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-icon,
.e-tab.e-focused>.e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-text {
  color: #fefefe;
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item .e-tab-icon {
  color: #fc4c02;
  border-radius: 50px;
  border: 1px solid #fc4c02;
  height: 36px;
  margin: 0;
  position: relative;
  top: 2px;
  min-height: 36px;
  width: 120px;
  padding: 18px 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.2s linear, color 0.2s linear;
}
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
  color: #eee;
  background: #fc4c02;
  border-radius: 50px;
  height: 36px;
  margin: 0;
  position: relative;
  top: 2px;
  min-height: 36px;
  width: 120px;
  padding: 18px 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}*/

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item:hover,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item:hover,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item:hover {
  color: #fefefe !important;
}

/*.e-tab .e-tab-header .e-toolbar-item:hover,
.e-tab .e-tab-header .e-toolbar-item:hover {
  color: #fefefe;
  background: #fc4c02;
  border-radius: 50px;
  height: 36px;
  margin: 0;
  position: relative;
  top: 2px;
  min-height: 36px;
  width: 120px;
  padding: 18px 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}*/

/* leave Apply btn css */
.leave-header-label {
  width: 100%;
  letter-spacing: 2px;
  color: #000;
  font-size: 24px;
  font-weight: 600;
  border-radius: 8px;
}

.e-flat-leave-apply {
  padding: 8px 15px;
  box-shadow: none;
  background: #fefefe;
  border: 1px solid #E9E9F2;
  border-radius: 5px;
}

.e-flat-leave-apply:hover {
  background: #fc4d0233;
  color: #000000d9;
  box-shadow: none;
  border: 1px solid #b7b2b2;
}

/* ----- */
/* .e-tab .e-tab-header .e-toolbar-item.e-active:hover .e-tab-wrap .e-tab-text {
  color: #fefefe;
} */
/* 
.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
  height: 36px;
  margin: 0;
  min-height: 36px;
  width: 120px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 10px;
} */

/*toolbar button text popup*/
/* .e-tab .e-tab-header .e-toolbar-pop {
  padding: 10px;
  overflow: auto;
  max-height: 220px !important;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover {
  background: transparent;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item:not(.e-separator) {
  min-width: 34px;
  margin: 10px auto !important;
  padding: 0;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:active .e-tab-text,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:active .e-tab-icon {
  color: #fefefe;
} */

/* .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap .e-tab-text,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap .e-tab-icon {
  color: #fc4c02;
  border-radius: 50px;
  border: 1px solid #fc4c02;
  height: 36px;
  margin: 0;
  position: relative;
  top: 2px;
  min-height: 36px;
  width: 120px;
  padding: 18px 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item:hover .e-tab-text,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item:hover .e-tab-icon {
  color: #ffffff;
} */

/* display daterange picker component */

.e-daterangepicker.e-popup .e-range-header {
  background: rgba(117, 117, 111, 0.253);
}

.e-daterangepicker.e-popup .e-range-header,
.e-bigger.e-small .e-daterangepicker.e-popup .e-range-header {
  padding: 16px 16px 1px 16px;
}

.e-daterangepicker.e-popup {
  border-radius: 5px;
  box-shadow: 1px 1px 10px 2px rgb(99, 97, 97);
}

/* Command Columns Css */
.command-buttons {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: end;
  align-items: center;
}

.e-flat {
  border: none;
  border-radius: 4px;
  padding: 4px 6px;
  font-size: 12px;
  margin: 0px 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.info-btn {
  background-color: transparent;
}

.info-btn:hover {
  color: white;
  background-color: steelblue;
  border-radius: 50%;
}

.trash-btn {
  background-color: transparent;
}

.trash-btn:hover {
  color: white;
  font-size: 11px;
  background-color: #e60000;
  border-radius: 50%;
}

.reject-btn {
  background-color: transparent;
}

.pen-btn {
  font-size: 10px;
  background-color: transparent;
}

.pen-btn:hover {
  color: white;
  background-color: rgba(78, 82, 84, 0.613);
  border-radius: 50%;
}

.reject-btn:hover {
  color: white;
  background-color: #e60000;
  border-radius: 50%;
}

.cancel-btn {
  background-color: transparent;
  padding: 4px 4px;
}

.cancel-btn:hover {
  color: #ffffff;
  background-color: #969591e5;
  border-radius: 50%;
}

.approve-btn {
  background-color: transparent;
}

.approve-btn:hover {
  color: white;
  background-color: #00cc00;
  border-radius: 50%;
}

.e-grid .e-gridheader .e-sortfilter .e-rightalign.e-fltr-icon .e-headercelldiv {
  text-align: left !important;
}

/* Make the Design Responsive */
@media (max-width: 768px) {
  .divleavetypes-class1 {
    width: 30% !important;
  }

  .divleavetypes-class2 {
    width: 70% !important;
  }

  .tblleave-class {
    width: 40% !important;
  }

  .tblleave-class2 {
    width: 45% !important;
  }

  .tblleave-class-min {
    width: 85% !important;
  }

  /* .e-daterangepicker.e-popup {
    margin-left: 20% !important;
  } */
}

@media (max-width: 540px) {
  .e-daterangepicker.e-popup .e-range-header {
    background-color: #fefefe;
  }
 
  .e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn,
  .e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn,
  *.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn,
  *.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn,
  *.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn,
  *.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn {
    border: 1px solid #fc4c02;
    border-radius: 3px !important;
  }

  .e-bigger .e-daterangepicker .e-start-btn.e-active,
  .e-bigger .e-daterangepicker .e-start-btn.e-active:active,
  .e-bigger .e-daterangepicker .e-end-btn.e-active,
  .e-bigger .e-daterangepicker .e-end-btn.e-active:active:not([disabled]),
  .e-bigger .e-daterangepicker .e-start-btn.e-active:hover,
  .e-bigger .e-daterangepicker .e-end-btn.e-active:hover,
  *.e-bigger.e-daterangepicker .e-start-btn.e-active,
  *.e-bigger.e-daterangepicker .e-start-btn.e-active:active,
  *.e-bigger.e-daterangepicker .e-end-btn.e-active,
  *.e-bigger.e-daterangepicker .e-end-btn.e-active:active:not([disabled]),
  *.e-bigger.e-daterangepicker .e-start-btn.e-active:hover,
  *.e-bigger.e-daterangepicker .e-end-btn.e-active:hover,
  *.e-device.e-daterangepicker .e-start-btn.e-active,
  *.e-device.e-daterangepicker .e-start-btn.e-active:active,
  *.e-device.e-daterangepicker .e-end-btn.e-active,
  *.e-device.e-daterangepicker .e-end-btn.e-active:active:not([disabled]),
  *.e-device.e-daterangepicker .e-start-btn.e-active:hover,
  *.e-device.e-daterangepicker .e-end-btn.e-active:hover {
    background-color: #fc4c02;
  }

  /* .e-bigger .e-daterangepicker .e-footer,
  *.e-bigger.e-daterangepicker .e-footer,
  *.e-device.e-daterangepicker .e-footer {
    height: 54px;
  }

  .e-bigger .e-daterangepicker .e-calendar .e-content table,
  *.e-bigger.e-daterangepicker .e-calendar .e-content table,
  *.e-device.e-daterangepicker .e-calendar .e-content table {
    padding: 0 15px 0px;
  } */
}

.e-gridcontent .e-content .e-rowcell.customLeaveCss {
  padding-right: 25px !important;
}

.e-gridcontent .e-content .e-rowcell.customApprovedDayCss {
  padding-right: 30px !important;
}

.e-gridcontent .e-content .e-rowcell.customLeaveAvailableCss {
  padding-right: 33px !important;
}

.e-gridcontent .e-content .e-rowcell.customLeaveBalancesCss {
  padding-right: 25px !important;
}

.e-gridcontent .e-content .e-rowcell.customLeaveBalancesAdjustedCss {
  padding-right: 32px !important;
}