.divwordfooterpanel {
  height: 52px;
  border: 1px solid #E9E9F2;
  inset: unset !important;
  transform: none !important;
  position: relative !important;
}

.divwordfooterpanel .icon {
  font-size: 22px;
  cursor: pointer;
  margin: 5px;
}

.divwordfooterpanel .text {
  font-size: 14px;
  cursor: pointer;
  margin: 5px;
}

.divwordfooterpanel .border-active {
  border: 1px solid black;
}

.divwordfooterpanel .border-inactive {
  border: 1px solid #E9E9F2;
}

.divwordfooterpanel .color-active {
  color: black;
}

.divwordfooterpanel .color-inactive {
  color: lightgray;
}

.deletedoc .e-dlg-header {
  white-space: normal !important;
  padding-left: 20px;
}

#word-treeview {
  height: 100%;
  overflow: auto;
}

#word-treeview .e-list-parent.e-ul {
  /* height: 100%; */
  overflow: hidden;
}

#word-treeview li.e-list-item .e-fullrow {
  width: 96%;
}
