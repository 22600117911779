.divteammemberlist {
  width: calc(100% - 5px);
  padding-top: 5px;
}

.divteammemberlist .e-gridheader {
  display: none;
}

.stickydiv {
  border-radius: 12px !important;
}
.empinfo {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 0px;
  border-bottom: 1px solid #e9e9f2;
  width: 95%;
  margin: auto;
}

.employee-photo {
  width: 3.5rem; /* or whatever size you need */
  height: 3.5rem;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 16px; /* space between photo and text */
}
.employee-photoname {
  width: 3.4375rem; /* or whatever size you need */
  height: 3.4375rem;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 16px; /* space between photo and text */
  border: 1px solid #fc4c02;
  color: #fc4c02;
}
.divteammemberlist
  .teammemberlist-grid.e-gridhover
  tr[role='row']:not(.e-disable-gridhover):not(.e-editedrow):not(
    .e-detailrow
  ):hover
  .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(
    .e-updatedtd
  ):not(.e-indentcell) {
  background-color: #fff !important;
}
.divteammemberlist .teammemberlist-grid td.e-active {
  background: #fff !important;
}
.employee-details {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically if only one label */
  flex-grow: 1;
}

.employee-name {
  font-size: 0.9375rem;
  line-height: 32px;
  letter-spacing: 1px;
}

.employee-job {
  font-size: 13px;
  color: #00000085;
}

.employee-details.no-job .employee-name {
  margin-bottom: 0;
}

.employee-details.no-job .employee-job {
  display: none;
}

.divteammemberlist .teammemberlist-grid {
  border: none;
}

.divteammemberlist .teammemberlist-grid.overflowhidden .e-content {
  overflow-y: hidden !important;
}

.divteammemberlist .teammemberlist-grid .e-content {
  scrollbar-width: thin;
  padding: 10px 0px 8px 5px !important;
}

.divteammemberlist .teammemberlist-grid .e-content:hover {
  scrollbar-width: thin;
}

.divteammemberlist .teammemberlist-grid .e-clipboard {
  display: none !important;
}

.divteammemberlist .teammember-viewall {
  text-align: center;
  cursor: pointer;
  padding: 14px 10px;
  color: #0000008a;
  width: calc(100% - 0px);
  text-transform: uppercase;
  font-size: 14px;
  line-height: 26px;
  background-color: #fefefe;
  /* border-top: 1px solid #f4f3f1; */
  /* position: absolute; */
  /* bottom: -38px; */
  letter-spacing: 2px;
}
.divteammemberlist .teammember-viewall:hover {
  color: #fc4c02;
}
@media screen and (max-width: 768px) {
  .divteammemberlist .teammemberlist-grid.overflowhidden .e-content {
    height: 275px !important;
  }
  .empinfo {
    padding: 5px 0px !important;
  }
}
