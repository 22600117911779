/* Base Styles */
.dashboard-reminder .font-awsm {
  font-family: 'Font Awesome 6 Free';
  font-size: 18px;
  font-weight: 900;
}

.dashboard-reminder .e-card.custom-card {
  position: relative;
  overflow: visible;
  transition: 0.2s;
  border-width: 1px;
  padding: 10px 5px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 30px;
  box-shadow: none;
}

.dashboard-reminder .lightborder {
}

.e-card .e-card-content + :not(.e-card-content) {
}

.dashboard-reminder .float-right {
  float: right;
  margin-top: 10px;
}

#accordion-reminder .e-card-content {
  color: rgb(0, 0, 0, 0.54);
  line-height: 24px;
}

.dashboard-reminder .sample_container.badge-accordion {
  scrollbar-width: thin;
  height: calc(100% - 45px);
}

.dashboard-reminder .sample_container.badge-accordion:hover {
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #f1f1f1;
}

.e-card-footer {
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
}

.dashboard-reminder .reminder-viewall {
  text-align: center;
  cursor: pointer;
  padding: 14px 10px;
  color: #0000008a;
  width: calc(100% - 0px);
  text-transform: uppercase;
  font-size: 14px;
  line-height: 26px;
  background-color: #fefefe;
}
.dashboard-reminder .reminder-viewall:hover {
  color: #fc4c02;
}

.dashboard-reminder .sample_container.overflowhidden {
  overflow-y: hidden !important;
}

@media (max-width: 768px) {
  .dashboard-reminder .e-card.custom-card {
    padding: 26px 10px;
  }
  .e-panel-content {
    height: calc(100% - 38.0142px);
  }
  .e-card .e-card-content {
    font-size: 16px !important;
    line-height: 30px !important;
  }
  .e-accordion .e-acrdn-item .e-acrdn-panel .e-acrdn-content {
    padding: 22px 30px !important;
    line-height: 24px !important;
  }
  .dashboard-reminder .reminder-viewall {
    padding: 10px !important;
    margin-top: 0px !important;
  }
  .main-aruti-btn {
    font-size: 16px !important;
    padding: 10px 22px !important;
  }
}
@media (max-width: 410px) {
  .dashboard-reminder .e-card.custom-card {
    padding: 8px 0px;
  }
}

/* ----------------------------------------------------------------------------------------- */

/* Main container */

/* Reminder card styling */
.reminder-template {
  /* width: 450px; */
  /* width: 100%; */
}
#accordion-reminder
  .e-accordion
  .e-acrdn-item.e-selected
  > .e-acrdn-panel
  .e-acrdn-content {
  display: block !important;
  width: 95% !important;
  margin: auto !important;
}
#accordion-reminder .e-acrdn-item .e-acrdn-header .e-acrdn-header-content {
  font-size: 18px !important;
}
#accordion-reminder {
  border-radius: 12px;
}

#accordion-reminder
  .e-accordion
  .e-acrdn-header
  .e-acrdn-header-content
  .totalLeaveCount {
  color: #535b86;
  border: 1px solid #fc4c01;
  background: #fff4ef;
  padding: 6px;
  border-radius: 100px;
}

#accordion-reminder .e-accordion .e-acrdn-item .e-acrdn-panel .e-acrdn-content {
  padding: 0px !important;
}
.reminder-card {
  width: 100%;
  background-color: #fffcfb;
  border-radius: 8px;
  box-shadow: 0px 2px 3px 0px #00000026;
  margin-bottom: 15px;
  overflow: hidden;
  padding: 10px;
  /* margin: 18px 0px; */
  margin-bottom: 30px !important;
  box-sizing: border-box;
}
.reminder-card:hover {
  border: 1px solid #e9e9f2;
}

.reminder-card .leave-info {
  /* margin: 15px 0px; */
  margin-bottom: 10px;
  justify-content: space-around;
  display: flex;
  align-items: center;
}

.leave-info .emp-leave-info {
  width: 65%;
  height: auto;
}

.day-before {
  font-size: 14px;
  color: #fc4c02;
  margin-bottom: -2px;
}
.emp-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.emp-img {
  width: 60px; /* Adjust size as needed */
  height: 60px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emp-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  background: #eee;
}
.non-personimg {
  border-radius: 50%;
    background: #FFF9F6;
    border: 1px solid #fc4c02;
  padding: 17px 18px;
  font-size: 22px;
    color: #fc4c02;
}
.emp-post-name {
  margin-left: 10px; /* Add spacing between image and text */
  flex: 1;
}

.emp-post-name p {
  margin: 0px 0px;
  font-weight: 500;
  font-size: 16px;
  color: #363636;
}

.emp-post-name span {
  font-size: 14px;
  color: #535b86;
}

/* Date Section */
.from-to-dates {
  margin-left: 10px;
  font-size: 12px;
}
.emp-leave-date {
  width: 30%;
}

.from-to-dates p {
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
  color: #555;
  letter-spacing: 1px;
}
.from-date2 {
  font-weight: 500 !important;
  font-size: 13px !important;
}

.from-to-dates span {
  font-size: 13px;
  color: #827b7b;
}

/* Footer Section */
.reminder-card-footer {
  width: 90%;
  margin: auto;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #E9E9F2;
}

.emp-leave-type p {
  font-size: 12px;
  color: #535b86;
  margin: 0px 0px;
  letter-spacing: 0.02em;
}
.emp-leave-type span {
  font-size: 16px;
  /* letter-spacing: 1px; */
  color: #363636;
  font-weight: 500;
}

.reminder-footer-btn {
  display: flex;
  gap: 8px;
}

#accordion-reminder .approve-btn,
#accordion-reminder .decline-btn {
  font-size: 14px;
  border: none;
  margin-top: 12px;
  padding: 10px 16px;
  border-radius: 50px;
  text-transform: capitalize !important;
}
#accordion-reminder .approve-btn:hover,
#accordion-reminder .decline-btn:hover {
  font-size: 14px;
  border: none;
  margin-top: 12px;
  padding: 10px 16px;
  border-radius: 50px;
  text-transform: capitalize !important;
}
#accordion-reminder .approve-btn,
#accordion-reminder .approve-btn:hover {
  background-color: #fc4c02;
  color: #fff;
}

#accordion-reminder .decline-btn {
  color: #363636;
  background-color: transparent;
}
#accordion-reminder .decline-btn:hover {
  background-color: #e9e9f2;
}
.vertical-line {
  position: relative;
  width: 4px; /* Width of the line */
  height: 150px; /* Height of the line */
  background-color: #ddd; /* Color of the dotted line */
  border-radius: 50px; /* To round the edges */
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vertical-line::before,
.vertical-line::after {
  content: '';
  width: 12px; /* Size of the circles */
  height: 12px;
  background-color: #ddd; /* Color of the circles */
  border-radius: 50%;
  position: absolute;
}

.vertical-line::before {
  top: 0; /* Top circle */
}

.vertical-line::after {
  bottom: 0; /* Bottom circle */
}

.vertical-line {
  background-image: radial-gradient(#ddd 1px, transparent 1px);
  background-repeat: repeat-y;
  background-size: 4px 8px; /* Spacing of the dotted line */
}

.dotted-line {
  width: 4px; /* Width of the line */
  height: 150px; /* Total height of the line */
  position: relative;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dotted-line::before,
.dotted-line::after {
  content: '';
  width: 12px; /* Size of the circles */
  height: 12px;
  background-color: #ddd; /* Color of the circles */
  border-radius: 50%;
  position: absolute;
  left: -7px;
}

.dotted-line::before {
  top: 0; /* Position top circle */
}

.dotted-line::after {
  bottom: 0; /* Position bottom circle */
}

.dotted-line {
  border-left: 2px dashed #ddd;
  height: 60px;
}

#accordion-reminder
  .e-accordion
  .e-acrdn-item.e-select.e-active
  > .e-acrdn-header,
#accordion-reminder
  .e-accordion
  .e-acrdn-item.e-select.e-item-focus
  > .e-acrdn-header {
  box-shadow: none !important;
  background: #f3f4f9 !important;
  padding: 10px 20px !important;
  margin: 10px 12px !important;
  border-radius: 8px !important;
}
#accordion-reminder
  .e-accordion
  .e-acrdn-item
  .e-acrdn-header
  .e-toggle-icon
  .e-tgl-collapse-icon.e-icons {
  position: relative;
  top: 10px;
  right: 10px;
}
