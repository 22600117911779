/* People Add - Edit Dialog Box */

#peopleAddEditDialogTab .e-tab-header.e-toolbar {
  height: 50px !important;
}

#peopleAddEditDialogTab .e-toolbar-items {
  height: 58px;
}

#peopleAddEditDialogTab .e-toolbar-items .e-toolbar-item {
  /* width: 100%; */
  /* display: flex; */
  justify-content: center;
  color: #fc4c01 !important;
  background-color: #ffffff !important;
  border: none !important;
  border-bottom: 2px solid #e9e9f2 !important;
}

#peopleAddEditDialogTab
  .e-toolbar-items
  .e-toolbar-item
  .e-tab-text
  .fa-regular.fa-check {
  display: none;
}

#peopleAddEditDialogTab .e-toolbar-items .e-toolbar-item.e-active {
  border-bottom: 2px solid #fc4c01 !important;
}

#peopleAddEditDialogTab
  .e-toolbar-items
  .e-toolbar-item.e-active
  .e-tab-text
  .fa-regular.fa-check {
  display: inline-block;
}

#peopleAddEditDialogTab .e-toolbar-items .e-toolbar-item .e-tab-wrap {
  width: 100% !important;
  margin: 0px !important;
  margin-bottom: 25px !important;
}

#peopleAddEditDialogTab .e-toolbar-items .e-toolbar-item .e-text-wrap {
  display: inline-flex;
  justify-content: center;
}

#peopleAddEditDialogTab
  .e-toolbar-items
  .e-toolbar-item.e-active
  .e-tab-wrap
  .e-text-wrap
  .e-tab-text {
  color: #fc4c01 !important;
}

#peopleAddEditDialogTab
  .e-toolbar-items
  .e-toolbar-item
  .e-tab-wrap:hover
  .e-text-wrap
  .e-tab-text {
  color: #fc4c01 !important;
}

#peopleAddEditDialogTab
  .e-toolbar-items
  .e-toolbar-item
  .e-tab-wrap
  .e-text-wrap
  .e-tab-text {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 20px;
}

#peopleAddEditDialogTab
  .e-toolbar-items
  .e-toolbar-item
  .e-tab-wrap
  .e-text-wrap
  .e-tab-text
  .fa-regular.fa-check {
  width: 24px;
  height: 24px;
  padding-top: 4px;
  border-radius: 50%;
  text-align: center;
  font-size: 19px;
  color: #ffffff;
  background-color: #fc4c01;
}

#peopleAddEditDialogTab
  .e-toolbar-items
  .e-toolbar-item
  .e-tab-wrap
  .e-text-wrap
  .e-tab-text
  .fa-circle-user.tabicon,
#peopleAddEditDialogTab
  .e-toolbar-items
  .e-toolbar-item
  .e-tab-wrap
  .e-text-wrap
  .e-tab-text
  .fa-user-tie.tabicon {
  font-size: 22px;
}

#peopleAddEditDialogTab .e-toolbar-items .e-toolbar-item.e-active::before {
  content: '';
  position: absolute;
  bottom: 5px;
  width: 13px;
  height: 0px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #f56a00;
}

#peopleAddEditDialogTab.empVisible .e-toolbar-items .e-toolbar-item {
  width: 50% !important;
}

#peopleAddEditDialogTab.empVisible .e-toolpop .e-toolbar-items .e-toolbar-item {
  width: 100% !important;
}

#peopleAddEditDialogTab .e-toolbar-items .e-toolbar-item {
  width: 100%;
  text-align: center;
}

#dlgAddEditRecorddialog
  .e-dlg-content
  .e-footer-content
  button.e-btn.flat-button {
  height: 40px !important;
  border-radius: 50px;
  padding: 12px 20px !important;
  opacity: 1 !important;
}

#dlgAddEditRecorddialog .e-dlg-content .e-footer-content button#btnCancel {
  color: #000000 !important;
  border: none !important;
}

#dlgAddEditRecorddialog
  .e-dlg-content
  .e-footer-content
  button#btnCancel:hover {
  background: #e9e9f2 !important;
  color: #000000 !important;
}

#dlgAddEditRecorddialog .e-dlg-content .e-footer-content button#btnAccept {
  background: #fc4c01 !important;
  color: #ffffff !important;
  border: none !important;
}

#dlgAddEditRecorddialog
  .e-dlg-content
  .e-footer-content
  button#btnAccept:hover {
  box-shadow: 0px 5px 4px 0px #fc4c0152;
  background: #fc4c01 !important;
  color: #ffffff !important;
}
