/* Suhail - layout fix - 20250203 - start */
.payslip-contsiner {
  height: 100%;
  display: flex;
  flex-direction: column;
}
/* Suhail - layout fix - 20250203 - end */
.employee-payslip {
  /* height: calc(100vh - 532px) !important; */
  border-right: 2px dashed #e9e9f2 !important;
  overflow: auto !important;
  scrollbar-width: thin;
  min-width: 430px;
  padding-left: 0px !important;
}
.employee-payslip tr[aria-expanded] td {
  line-height: 26px;
}
#netpaygrid td {
  line-height: 26px;
  border-width: 2px 0px 2px 0px;
  border-style: dashed !important;
  border-color: #fc4c02 !important;
}

#netpaygrid .e-rowcell:first-child {
  border-right: none !important;
}
.employee-payslip .e-treecolumn-container .e-none {
  display: inline-block !important;
}
.employee-payslip .e-grid {
  border: none !important;
}
.employee-payslip .e-grid .e-gridheader {
  border: none !important;
}
#bankdetailgrid_gridcontrol .e-content {
  overflow: auto !important;
  padding: 0px !important;
}
#earninggrid_gridcontrol .e-gridheader,
#netpaygrid .e-gridheader,
#deductiongrid_gridcontrol .e-gridheader {
  display: none !important;
}
#earninggrid_gridcontrol,
#netpaygrid,
#deductiongrid_gridcontrol {
  margin: 5px 0px !important;
}

#earninggrid_gridcontrol .e-content,
#netpaygrid .e-content,
#deductiongrid_gridcontrol .e-content {
  padding: 0px !important;
}
#earninggrid_gridcontrol .e-rowcell:first-child,
#netpaygrid .e-rowcell:first-child,
#deductiongrid_gridcontrol .e-rowcell:first-child,
#bankdetailgrid_gridcontrol .e-rowcell:first-child {
  padding-left: unset !important;
}
#earninggrid_gridcontrol .payslip-cell:first-child,
#netpaygrid .payslip-cell:first-child,
#deductiongrid_gridcontrol .payslip-cell:first-child,
#bankdetailgrid_gridcontrol .payslip-cell:first-child {
  padding-left: 20px !important;
}

.payslip-cell {
  background: #fff9f6 !important;
  padding: 14px !important;
  color: #fc4c02 !important;
  /* font-size: 16px !important; */
}
.payslip-cell-net {
  padding: 14px !important;
  color: #363636 !important;
  /* font-size: 15px !important; */
}
#bankdetailgrid_gridcontrol .e-rowcell {
  /* font-size: 14px !important; */
}
.employee-payslip .e-grid .e-rowcell {
  /* padding: 5px 10px !important; */
  font-size: 0.875rem !important;
  border-style: none !important;
}
.bankGrid-header {
  font-size: 0.875rem !important;
  color: #fc4c02 !important;
}
.employee-payslip .e-grid .e-rowcell {
  font-size: 0.875rem;
}

.employee-payslip .e-grid .e-rowcell.payslip-cell {
  font-size: 1rem !important;
}

/* .e-grid:not(.e-row-responsive) .e-gridcontent tr.e-row:first-child .e-rowcell {
  padding: 8px !important;
} */

/* .employee-payslip
  .e-grid:not(.e-grid-min-height)
  .e-gridcontent
  tr
  td:not(.e-indentcell):first-child:empty,
.e-grid:not(.e-grid-min-height) .e-gridcontent tr.e-row .e-rowcell:empty {
  height: 29px !important;
} */

.employee-payslip .e-treecolumn-container .e-none {
  width: 20px !important;
}

.employee-payslip .e-headertext {
  font-size: 0.875rem;
  font-weight: 500;
  color: black;
}

/* employer grid */

.employer-payslip {
  min-width: 285px;
}

.employer-payslip .e-treecolumn-container .e-none {
  display: inline-block !important;
}

.employer-payslip .e-grid {
  border: none !important;
}

.employer-payslip .e-grid .e-gridheader {
  border: none !important;
  border-bottom: 1px solid rgb(191, 191, 191) !important;
  margin-top: 18px !important;
}

.employer-payslip .e-grid .e-rowcell {
  border-style: none !important;
}

.employer-payslip .e-grid .e-rowcell.border-top {
  border-top: 1px solid rgb(191, 191, 191) !important;
}

.employer-payslip .e-headertext {
  font-size: 0.875rem;
  font-weight: 500;
  color: #fc4c02;
}

/* payee style started */

.paye-style .e-grid {
  border: none !important;
  padding: 10px !important;
  margin: 10px !important;
  padding-right: 50px !important;
}

.paye-style .e-grid .e-rowcell {
  border-style: none !important;
}

.paye-style .e-grid .e-gridheader {
  border: none !important;
}

/* #payslip-style {
  height: calc(100vh - 554px);
   overflow: auto;
} */

.payeHeader-style {
  font-size: 0.875rem;
  font-weight: 470;
}

/* payslab-style */
.payslab-style .e-grid {
  border: 1.4px solid black !important;
}

.payslab-style .e-rowcell {
  border-top: 1.4px solid black !important;
}

.payslab-style .e-headercell {
  background-color: #bfbfbf !important;
  color: white;
}

.payslab-style .e-headertext {
  font-size: 0.875rem !important;
}

.payeeName {
  font-size: 0.875rem !important;
  font-weight: bold !important;
}

.touch-cursor {
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAACxMAAAsTAQCanBgAAAJwSURBVEhLnZRJqA5QFMevmSgJz2yDzIrMRFE2hkKs9EiZ1ygbNhILFqY8KRRFFBkyKyyJEkpZCivFzgK/373vvL4xw79+vXO/933nnnvO/95OqbGGwgZYAF3hF/hdeQnn4DX8tXrAfrgDy9vXleoMs8HEF6AF/qh+cA/W5VXRGLDyXbAVZoDJlRs8g0l51URWZtK5eZXSZLgFVtYKS2ANHIEn4GmULTP5iLxqII8fla6AuzAyr+rVB9pgb16lNBFuQ9283NWeqilg0ujtVDgON+E8LINIYPWeRlmY/6vSHoijefyodDtcBXuoM4bBITgD9rkbPIXeMBiuQ5WiQgdlT9U0MGkjO+4GB6m2QFTtjCygQw/a/zr9+NIJaDbt7vC4hGk02G91DMaVsBxJ8ys9+amEuR3vSphlu+y17ZkDtkF9hvDyV9CyWSaO436HviXMXxpUwqzhYMu+wEL4CI/Avo4FW2K1XaBDD8ENZsJhP0AO82AJs3bA6hJWybZot7VwAL6B+XLCV+CNeg7TQZ9qr4HgjfPHb8AEtfoBH6A/zAdbthSy9G64wctxqoR5023goLxdl6CRTsIVsIA6XYRZJUz7wJbEgEL2tJG06lmw2jp7OlmrGpJXKa0H34TNMApsj72r3SxkQhN78p5+UCkfHpNPyKtyo9zgNFwD7Wef/aE+r31S1SrworTUlq5/7fELOAraK6SltOEAWATacyPEPQjNg7ZGV9bPfFA2gVW9BxPqUx3g9BfDTvgJ2izUCy5D3MamsqfjwXfahPpUTysL8MVbmVcl6Q2IB+2f5AArh2N8H9z4v5M2k1f/LVQkTek3/Z5voc6r0wAAAAAASUVORK5CYII=),
    auto;
}

.payslip-container {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* pointer-events: none; */
}
/* payslip Design chenge */
.paySlip-container {
  /* height: calc(100vh - 455px); */
  height: 100%; /* Suhail - layout fix - 20250203 */
  overflow: auto;
  scrollbar-width: thin;
}
.paySlip-container.fromDialogCss {
  height: calc(100% - 37px);
}
.paySlip-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}
.paySlip-period {
  width: 48%;
  /* display: flex; */
  /* justify-content: flex-start; */
  /* align-items: center; */
}
.paySlip-header-ions {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.paySlip-header-ions i {
  font-size: 20px;
  display: inline-block;
  margin: auto 10px;
  cursor: pointer;
}

.e-treegrid .e-treegridexpand {
  transform: rotate(180deg);
}
.e-treegrid .e-treegridexpand::before {
  content: '\e36a' !important;
  color: #fc4c02 !important;
  font-size: 16px;
}

.e-treegrid .e-treegridcollapse::before {
  content: '\e36a' !important;
  color: #fc4c02 !important;
  font-size: 16px;
}
