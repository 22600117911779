#divcreatepost .posted-userinfo {
  position: absolute;
  left: 75px;
  top: 0px;
  width: 80%;
  height: auto;
}

#divcreatepost .posted-contain {
  position: relative;
}

#divcreatepost .posted-body {
  margin-top: 12px;
}

#divcreatepost .posted-body .post-title .e-content {
  height: 178px;
  font-size: 24px;
}

#divcreatepost .posted-body.has-image .post-title .e-content {
  height: 107px;
}

#divcreatepost .posted-body .post-title .e-rte-placeholder {
  font-size: 24px !important;
}

#divcreatepost .posted-body .posted-actions {
  margin-top: 14px;
  display: flex;
  align-items: center;
}

#divcreatepost .posted-body .posted-actions button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-left: 5px;
  box-shadow: none;
  padding-left: 4px;
  padding-right: 4px;
}

#divcreatepost .posted-body .preview-div {
  display: flex;
  gap: 10px;
  justify-content: start;
  align-items: center;
  position: relative;
  transition: transform 0.3s ease-in-out;
}
#divcreatepost .posted-body .preview-div .divimg {
  position: relative;
  max-width: calc(50% - 5px);
  display: flex;
}

#divcreatepost .posted-body .preview-div .divimg button.close {
  position: absolute;
  top: 5px;
  right: 5px;
}

#divcreatepost .posted-body .preview-div .slide-left {
  transform: translateX(-100%);
}

#divcreatepost .posted-body .preview-div .slide-right {
  transform: translateX(100%);
}

#divcreatepost .posted-body .preview-div .preview-image {
  /* max-width: calc(50% - 5px); */
  width: 100%;
  height: auto;
  border: 1px solid #ddd;
}

#divcreatepost .posted-body .preview-div button.e-round {
  background-color: #555555;
}

#divcreatepost .posted-body .preview-div button.e-round .right-arrow {
  color: #ffffff;
}

#divcreatepost .posted-body .preview-div .navigation {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
}

#divcreatepost .posted-body .preview-div .navigation button:disabled {
  background-color: transparent;
}

#divcreatepost .posted-body .posted-actions button.smile {
  margin-left: auto;
}

#divcreatepost .posted-footer {
  margin-top: 12px;
  margin-bottom: 2px;
  padding-top: 12px;
  display: flex;
  justify-content: end;
  padding-right: 5px;
  display: flex;
  align-items: center;
  border-top: 1px solid #E9E9F2;
}

#divcreatepost .posted-footer button {
  /* padding-top: 14px !important;
  padding-bottom: 14px !important; */
  padding-left: 24px;
  padding-right: 24px;
}
#divcreatepost .posted-footer button.discard {
  color: #000 !important;
}

#divcreatepost .posted-footer button.posted-post {
  background-color: transparent;
  color: #9ea5b5;
  text-transform: none;
  font-weight: 400;
  text-align: left;
  line-height: 21px;
  box-shadow: none;
  border-radius: 50px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 14px;
  padding-bottom: 14px;
  background-color: #fc4c01;
  color: #fff;
}

#divcreatepost .posted-footer button.posted-post .right-arrow {
  color: #fff;
  padding-left: 10px;
}

#divcreatepost .e-toolbar-wrapper {
  height: 42px !important;
}

#defaultDialog-createpost {
  max-width: 600px;
}
