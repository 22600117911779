.divwordpdfpanel {
  height: 52px;
  border: 1px solid #E9E9F2;
  inset: unset !important;
  transform: none !important;
  position: relative !important;
}

#relatedpdfDialog .e-dlg-content > div:first-child {
  height: 100%;
}

.pdfjspdfviewer-nodlg {
  height: calc(100vh - 162px);
}

.pdfjspdfviewer-dlg {
  height: calc(90vh - 116px);
}

.divwordpdfpanel .icon {
  font-size: 22px;
  cursor: pointer;
  margin: 5px;
}

.divwordpdfpanel .text {
  font-size: 14px;
  cursor: pointer;
  margin: 5px;
}

.divwordpdfpanel .border-active {
  border: 1px solid black;
}

.divwordpdfpanel .border-inactive {
  border: 1px solid #E9E9F2;
}

.divwordpdfpanel .color-active {
  color: black;
}

.divwordpdfpanel .color-inactive {
  color: lightgray;
}

.button-circle {
  font-size: 50px;
}

.divaddnew-content {
  min-width: 112px !important;
  border: none !important;
}

.divaddnew-content .dropdown-item {
  cursor: pointer;
  width: 46px;
  float: left;
  clear: none;
  border: 1px solid #E9E9F2;
  margin: 5px;
  padding: 10px;
}

.divaddnew-content .dropdown-item .icon {
  font-size: 30px;
}

.deletedoc .e-dlg-header {
  white-space: normal !important;
  padding-left: 20px;
}

#documenteditor_titlebar ~ #documenteditor_container_body {
  height: calc(100% - 36px);
}
#documenteditor_titlebar.d-none ~ #documenteditor_container_body {
  height: calc(100%);
}
#defaultDialog-dossier_dialog-content > div:first-child {
  height: calc(100% - 60px);
}
#documenteditor_titlebar.d-none
  ~ #documenteditor_container_body
  #wordcontainer_editor_pageContainer {
  width: 100% !important;
}
#dataviewerdocumentviewer {
  height: calc(
    100vh - 107px
  ); /* SET 161 px when to show word and pdf switch option */
}

.e-documenteditor-optionspane,
#wordcontainer_editor_viewerContainer {
  width: 100% !important;
}
