.contract-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
/* main height */
.contract-listView {
  padding: 0px 10px;
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}
#contract-list-container {
  /* height: calc(100vh - 531px) !important; */
  height: 100%;
  overflow: auto;
  padding-bottom: 1rem !important;
  scrollbar-width: thin;
}
#divAddressContent .form-row {
  margin: 20px 10px;
}

#contract-template .e-input-group:not(.e-float-icon-left),
#contract-template .e-input-group.e-control-wrapper:not(.e-float-icon-left) {
  border-width: 0 0 0 0 !important;
}

.contract-feildIcon {
  color: #535b86;
  margin-right: 15px;
  padding-top: 5px;
  font-size: 16px;
}

.contract-info {
  width: 100%;
}
/* header contract */
.heading-contract {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #948989;
}
.heading-contract i {
  color: #948989;
}
.contract-container .e-acrdn-item .e-acrdn-header .e-acrdn-header-content {
  /* width: 90% !important; */
  width: 100% !important;
  padding: 3px;
}
.accordion--grid-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 70%;
}
.accordion--grid-content p {
  margin: 10px auto;
  width: 200px;
  text-align: center;
}

.contract-container .e-acrdn-item .e-acrdn-panel {
  box-shadow: 0px 2px 4px #eee;
  border-radius: 0px 0px 8px 8px;
  margin-bottom: 15px;
}
.heading-contract .header-city-obj {
  border: 1px solid #fc4c02;
  display: inline-block;
  padding: 0px 10px;
  border-radius: 20px;
  margin: 0px 10px;
  line-height: 26px;
  font-size: 0.875rem;
  color: #fc4c02;
}

.contract-container
  .e-acrdn-item
  .e-acrdn-header
  .e-toggle-icon
  .e-tgl-collapse-icon.e-icons {
  display: none !important;
}
.last-contract-div {
  margin-left: auto;
}
/* dialog */
#Dialog-Contract .e-dlg-header-content {
  padding: 25px 5px !important;
  border-bottom: 1px solid #e5dede !important;
  margin: 15px 28px 0px 28px !important;
  font-size: 1.2rem !important;
  border-radius: 0px !important;
}
#Dialog-Contract .dlg-headerIcon {
  font-size: 22px;
  color: #535b86;
  position: relative;
  top: 2px;
  margin-right: 8px;
}
#Dialog-Contract .e-btn .e-btn-icon.e-icon-dlg-close {
  font-size: 18px !important;
  width: 36px !important;
  color: #535b86;
}
#Dialog-Contract .e-dlg-content {
  padding: 12px 36px 25px 36px !important;
}
#Dialog-Contract .footer-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 25px;
}
#Dialog-Contract .cancel-button {
  background-color: transparent;
  color: #333;
  padding: 14px 18px;
  border-radius: 20px;
  cursor: pointer;
  border: none;
}
#Dialog-Contract .cancel-button:hover {
  background-color: #ddd;
}
#Dialog-Contract .save-button {
  background-color: #fc4c02;
  border: none;
  color: #fff;
  padding: 13px 24px;
  border-radius: 20px;
  cursor: pointer;
}
.demo {
  display: none;
  width: 88px;
  height: 30px;
  position: absolute;
  right: 0;
}
.acordion-second-box {
  display: flex;
  align-items: center;
  width: 20%;
  height: auto;
  justify-content: flex-end;
  visibility: hidden;
}
.accordion-header-icons {
  width: 30px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 15px;
}

/* header */
.contract-accordion-header {
  border-bottom: 1px solid #e9e9f2;

  height: auto;
  padding: 10px;
}
.contract-accordion-header div {
  width: 70%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.contract-accordion-header p {
  color: #fc4c02;
  font-size: 0.875rem;
}

/* renderSubItems */
#contract-template {
  width: 100%;
  padding: 0.625rem;
  border-radius: 5px;
  background-color: #fff;
}
.contract-temp-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #a5a0a0;
  font-size: 1rem;
  padding: 10px 20px;
  box-shadow: 0px 3px 1px 0px #f5efef;
  border-radius: 5px;
}
.contract-temp-head .job-div {
  display: flex;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
}
.contract-temp-head .job-div span {
  margin: 0px 5px;
}
.contract-temp-head .job-div i {
  color: #fc4c02;
  margin: 0px 5px;
}
.contract-temp-head .job-div span.jobname {
  min-width: 163px;
}
.contract-temp-row {
  display: flex;
  margin: 0.5rem auto;
}

.contract-temp-box {
  display: flex;
  align-items: center;
  width: 320px;
  padding: 10px;
  border-radius: 5px;
}

.contract-temp-box:last-child {
  border: none; /* Removes the border from the last empty box */
}

.contract-feildIcon {
  font-size: 20px;
  color: #535b86;
  margin-right: 10px;
}

.contract-info {
  display: flex;
  flex-direction: column;
}

.contract-label label {
  font-size: 0.875rem;
  color: #a5a0a0;
  margin-bottom: 5px;
}

.contract-data {
  font-size: 1rem;
  font-weight: normal;
  color: #595858;
  /* pointer-events: none; */
}
.job-div {
  /* pointer-events: none; */
}
#contract-template .e-input-group-icon.e-ddl-icon.e-search-icon {
  display: none !important;
}
.contract-data .e-input-group.e-control-wrapper .e-input-group-icon.e-ddt-icon,
.job-div .e-input-group.e-control-wrapper .e-input-group-icon.e-ddt-icon {
  display: none;
}

#field-container .row-grp {
  margin: 14px 0px !important;
}
#field-container .salary-text {
  margin-top: 10px !important;
}
/* Responsive Design */
@media (max-width: 768px) {
  .contract-temp-row {
    flex-wrap: wrap;
    gap: 10px;
  }

  .contract-temp-box {
    width: 48%; /* Two items per row for smaller screens */
  }
}

@media (max-width: 480px) {
  .contract-temp-box {
    width: 100%; /* Full width for very small screens */
  }
}
.field-row {
  margin: 5px 0px;
}

/* serchbox */
.contract-list-start {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.search-header {
  width: 375px;
  height: auto;
  padding: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.search-header i {
  position: absolute;
  right: 10px;
  font-size: 16px !important;
}
.aa {
  font-size: 14px !important;
  color: #535b86 !important;
}

/* dlg footer */
.contract-footer-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

.contract-cancel-button {
  background-color: transparent;
  color: #333;
  padding: 14px 18px;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  margin-right: 10px;
}
.contract-cancel-button:hover {
  background-color: #ddd;
}
.contract-save-button {
  background-color: #fc4c02;
  border: none;
  color: #fff;
  padding: 13px 24px;
  border-radius: 50px;
  cursor: pointer;
}

#contract-list-container
  .e-acrdn-item.e-select.e-selected.e-expand-state
  > .e-acrdn-header,
#contract-list-container
  .e-acrdn-item.e-select.e-expand-state
  > .e-acrdn-header {
  border-left: 1px solid #fc4c02 !important;
  border-radius: 0px !important;
}
#contract-list-container .e-acrdn-item .e-acrdn-header .e-acrdn-header-content {
  font-size: 0.875rem !important;
}

#contract-list-container
  .e-acrdn-item
  .e-acrdn-header:hover
  .acordion-second-box,
#contract-list-container.e-accordion
  .e-acrdn-item.e-select.e-item-focus
  > .e-acrdn-header
  .acordion-second-box {
  visibility: visible;
}

#contract-list-container
  .e-acrdn-item.e-selected
  > .e-acrdn-panel
  .e-acrdn-content {
  border-left: 1px solid #efbba4 !important;
  padding: 0.625rem;
}

#contract-list-container
  .e-acrdn-item.e-select.e-active
  > .e-acrdn-header
  .acordion-second-box {
  visibility: visible;
}

/* sub dialog */

#dialoggAllocationNew {
  padding: 13px;
}
#dialoggAllocationNew .e-dlg-content {
  padding-top: 28px;
  padding-left: 10px;
  padding-right: 10px;
}
#dialoggAllocationNew .e-dlg-header-content {
  border-bottom: 1px solid #ddd;
  border-radius: 2px !important;
}
#dialoggAllocationNew .sub-form-group {
  margin-top: 10px;
}

#dialoggAllocationNew .sub-form-group input {
  border-bottom: 1px solid #e9e9f2;
}
/* dialog */

#Dialog-Address .dlg-headerIcon {
  font-size: 22px;
  color: #535b86;
  position: relative;
  top: 2px;
  margin-right: 8px;
}
#Dialog-Address .e-btn .e-btn-icon.e-icon-dlg-close {
  font-size: 18px !important;
  width: 36px !important;
  color: #535b86;
}
#Dialog-Address .e-dlg-content {
  padding: 12px 36px 25px 36px !important;
}

/* dropDown Tree Design */

#ddljob_popup,
#ddlbranch_popup,
#ddldepartment_popup,
#ddlgrade_popup {
  box-shadow: 0 5px 5px -3px rgba(57, 56, 56, 0.2),
    0 8px 10px 0px rgba(64, 63, 63, 0.14), 0 3px 14px 0px rgba(52, 51, 51, 0.12) !important;
}
#ddljob_popup .e-popup-content,
#ddlbranch_popup .e-popup-content,
#ddldepartment_popup .e-popup-content,
#ddlgrade_popup .e-popup-content {
  scrollbar-width: thin;
}

#ddljob_popup .e-treeview > .e-ul,
#ddlbranch_popup .e-treeview > .e-ul,
#ddldepartment_popup .e-treeview > .e-ul,
#ddlgrade_popup .e-treeview > .e-ul {
  background: #fff !important;
  text-align: start;
}
#ddljob_popup .e-list-item,
#ddlbranch_popup .e-list-item,
#ddldepartment_popup .e-list-item,
#ddlgrade_popup .e-list-item {
  padding: 5px !important;
  border-radius: 50px !important;
}
#ddljob_popup .e-filter-wrap,
#ddlbranch_popup .e-filter-wrap,
#ddldepartment_popup .e-filter-wrap,
#ddlgrade_popup .e-filter-wrap {
  margin-bottom: 10px !important;
}
#ddljob_popup .e-text-content,
#ddlbranch_popup .e-text-content,
#ddldepartment_popup .e-text-content,
#ddlgrade_popup .e-text-content {
  padding: 0px !important;
}

#ddljob_popup
  .e-list-item.NewOptionClass
  .e-text-content
  .e-list-text
  .newOptIcon,
#ddlbranch_popup
  .e-list-item.NewOptionClass
  .e-text-content
  .e-list-text
  .newOptIcon,
#ddldepartment_popup
  .e-list-item.NewOptionClass
  .e-text-content
  .e-list-text
  .newOptIcon,
#ddlgrade_popup
  .e-list-item.NewOptionClass
  .e-text-content
  .e-list-text
  .newOptIcon {
  font-size: 1rem !important;
  position: absolute;
  left: 0px !important;
  width: 22px !important;
  top: 6px !important;
}

#ddljob_popup
  .e-list-item.NewOptionClass
  .e-text-content
  .e-list-text
  .newOptText,
#ddlbranch_popup
  .e-list-item.NewOptionClass
  .e-text-content
  .e-list-text
  .newOptText,
#ddldepartment_popup
  .e-list-item.NewOptionClass
  .e-text-content
  .e-list-text
  .newOptText,
#ddlgrade_popup
  .e-list-item.NewOptionClass
  .e-text-content
  .e-list-text
  .newOptText {
  padding-left: 17px !important;
}

#ddljob_popup .e-treeview .e-list-item div.e-icons.interaction {
  position: absolute;
  left: 5px;
  top: 2px;
}

#contractType_popup .e-list-item {
  line-height: 42px !important;
}

#contractType_popup .e-list-item:hover {
  background-color: #fbf4ee;
  color: #fc4c02;
}

#contractType_popup .NewOptionClass {
  padding: 3px 12px 3px 0px !important;
  line-height: 0px !important;
  position: relative;
}

#contractType_popup .NewOptionClass .newOptIcon {
  font-size: 1rem !important;
  position: absolute;
  left: -10px !important;
  width: 22px !important;
}

#contractType_popup .NewOptionClass .newOptText {
  padding-left: 12px;
}
/*Expand-Collaspe icon  accordion*/
.heading-contract::before {
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 14px;
  margin-right: 8px;
  display: inline-block;
}

.e-acrdn-header[aria-expanded='true'] .heading-contract::before {
  content: '\f106';
}
