.identity-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
/* grid */
#identityGrid .e-content {
  /* height: calc(100vh - 512px) !important; */
  height: 100%;
  overflow: auto;
  scrollbar-width: thin;
}

/* grid list design */
.identity-dlg-btn {
  border: 2px solid #fc4c02;
  padding: 8px 12px;
  color: #fc4c02;
  border-radius: 20px;
  line-height: 20px;
  box-shadow: none !important;
  text-transform: capitalize;
  background: #fefefe;
  letter-spacing: 1px;
  margin-bottom: 10px;
  box-shadow: none;
  transition: background 0.3s ease;
  font-size: 1.1rem;
}
/* .e-btn:active {
  border: 1px solid #fc4c02 !important;
  background-color: #fefefe !important;
  color: #fc4c02 !important;
} */
.identity-dlg-btn:hover {
  background: #fc4c02;
  color: #fefefe !important;
}
.identity-dlg-btn-addbtn {
  margin-right: 10px;
  color: #fc4c02;
}
.identity-dlg-btn:hover .identity-dlg-btn-addbtn {
  color: #fefefe !important;
}
#identityGrid .e-gridheader {
  border-bottom-color: #e9e9f2 !important;
  border-top-color: #fefefe !important;
  font-size: 1.1rem !important;
  color: #fc4c02 !important;
  /* padding: 5px !important; */
}
#identityGrid {
  border-color: #fefefe !important;
}
#identityGrid .e-headercelldiv {
  font-size: 1rem !important;
}
#identityGrid .e-rowcell {
  line-height: 2.1rem;
  font-size: 0.875rem;
  color: #363636 !important;
}
#identityGrid .img-temp {
  color: #fc4c02 !important;
  font-size: 1.2rem;
}

#identityGrid th.e-headercell[aria-sort='ascending'] .e-headertext,
#identityGrid th.e-headercell[aria-sort='descending'] .e-headertext {
  color: #fc4c02 !important;
}
#identityGrid
  .e-gridheader
  .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop):not(
    .e-icon-reorderuparrow
  ):not(.e-icon-reorderdownarrow) {
  color: #fc4c02;
}
#identityGrid.e-gridhover
  tr[role='row']:not(.e-disable-gridhover):not(.e-editedrow):not(
    .e-detailrow
  ):hover
  .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(
    .e-updatedtd
  ):not(.e-indentcell)
  .command-identity,
#identityGrid td.e-active .command-identity {
  visibility: visible;
}
/* Dialog Design */
#viewdialog .identity-container {
  padding: 20px;
}
/* add-edit dialog */
#Dialog-Identity .form-row {
  display: flex;
  gap: 20px;
  margin: 20px 5px;
}

#Dialog-Identity .form-group {
  flex: 1;
}

#Dialog-Identity .upload-section {
  border: 1px dashed #e9e9f2;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  background: #fbeee84d;
}
#Dialog-Identity .upload-section:hover {
  border: 1px solid #fc4c02;
}

#Dialog-Identity .upload-header {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

#Dialog-Identity .upload-drop-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

#Dialog-Identity .upload-text {
  color: #666;
  font-size: 1rem;
}

#Dialog-Identity .browse-link {
  color: #007bff;
  font-weight: 600;
  cursor: pointer;
}

#Dialog-Identity .upload-info {
  font-size: 0.875rem;
  color: #999;
  margin-top: 10px;
}

#Dialog-Identity .footer-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 25px;
}

#Dialog-Identity .cancel-button {
  background-color: transparent;
  color: #333;
  padding: 14px 18px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
}
#Dialog-Identity .cancel-button:hover {
  background-color: #ddd;
}
#Dialog-Identity .save-button {
  background-color: #fc4c02;
  border: none;
  color: #fff;
  padding: 13px 24px;
  border-radius: 20px;
  cursor: pointer;
}

#Dialog-Identity .save-button span.e-animate-end {
  position: relative !important;
}

#Dialog-Identity .save-button span.e-spinner {
  position: absolute;
  top: 15px;
  left: 30px;
}

#Dialog-Identity .save-button span.e-spinner svg path {
  stroke: #fff;
}

#Dialog-Identity .save-button:hover {
  background-color: #e64a19;
}

#Dialog-Identity .cancel-button:hover {
  border-color: #bbb;
}

#Dialog-Identity .e-btn {
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: none;
}
#Dialog-Identity .e-btn.e-spin-center .e-spinner {
  width: inherit !important;
  height: inherit !important;
}

/* show image dialog */

#ShowIdentity-Identity .e-dlg-header-content {
  padding: 0px;
  padding-top: 1.7rem;
  border-bottom: 1px solid #e9e9f2 !important;
  margin: 0px 25px;
  border-radius: 0px !important;
  margin-bottom: 1rem !important;
  padding-bottom: 1.7rem;
}
#ShowIdentity-Identity .e-dlg-header {
  font-size: 1.4rem !important;
}
#ShowIdentity-Identity .image-container {
  display: inline-block;
  text-align: center;
}

#ShowIdentity-Identity .dlg-headerIcon {
  font-size: 22px;
  color: #535b86;
  position: relative;
  top: 2px;
  margin-right: 8px;
}
#ShowIdentity-Identity .e-btn .e-btn-icon.e-icon-dlg-close {
  font-size: 18px !important;
  width: 36px !important;
  color: #535b86;
}

/* ---------------------------------------------------- */
.DlgShowIdentity {
  padding: 1.5rem;
  background: #e9e9f2;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
#mousezoomableImage {
  margin: 1.2rem;
  position: relative;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

#mousezoomableImage img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

#mousezoomableImage::after {
  display: var(--display, none);
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: var(--url);
  background-size: var(--zoom-level, 130%);
  background-position: var(--zoom-x) var(--zoom-y);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  pointer-events: none;
}

/* Zoom buttons */
#ShowIdentity-Identity .zoom-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 1.5rem;
  position: relative;
  z-index: 10;
}

#ShowIdentity-Identity .lock-button {
  font-size: 1rem;
  border: none;
  background: none;
  color: #363636;
  margin-right: 5px;
}
#ShowIdentity-Identity .zoom-button {
  font-size: 1.5rem;
  padding: 2px 10px;
  margin: 0 5px;
  cursor: pointer;
  background-color: #fc4c02;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.2s;
}
/* ------------------------------------------------------------ */
/* command template */
.command-identity {
  display: flex;
  align-items: center;
  gap: 10px;
  visibility: hidden;
}
.identity-command-btn {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.identity-command-btn .far {
  color: #535b86;
}
.identity-command-btn .far:hover {
  color: #fc4c02;
}
#Dialog-Identity .e-dlg-header-content {
  padding: 30px 5px 16px 5px !important;
  border-bottom: 1px solid #e9e9f2 !important;
  margin: 0px 28px !important;
  border-radius: 0px !important;
}
#Dialog-Identity .dlg-headerIcon {
  font-size: 22px;
  color: #535b86;
  position: relative;
  top: 2px;
  margin-right: 8px;
}
#Dialog-Identity .e-btn .e-btn-icon.e-icon-dlg-close {
  font-size: 18px !important;
  width: 36px !important;
  color: #535b86;
}
#Dialog-Identity .e-dlg-content {
  padding: 12px 36px 25px 36px !important;
}

#DocumentType_popup .e-list-item {
  line-height: 42px !important;
}

#DocumentType_popup .e-list-item:hover {
  background-color: #fbf4ee;
  color: #fc4c02;
}

#DocumentType_popup .NewOptionClass {
  padding: 3px 12px 3px 0px !important;
  line-height: 0px !important;
}
#defalutDialogAddDocument .e-dlg-header-content {
  border-bottom: 1px solid #e9e9f2 !important;
  margin: 0px 28px !important;
  border-radius: 0px !important;
}

#defalutDialogAddDocument .e-dlg-header-content .e-dlg-header .dlg-headerIcon {
  color: #535b86;
}

#defalutDialogAddDocument .e-dlg-content {
  padding: 19px 36px !important;
}

#defalutDialogAddDocument .e-footer-content .e-control.e-btn {
  height: 40px !important;
  border-radius: 50px;
  padding: 12px 20px !important;
  opacity: 1 !important;
  color: #000000 !important;
}

#defalutDialogAddDocument .e-footer-content .e-control.e-btn:hover {
  background: #e9e9f2 !important;
  color: #000000 !important;
}

#defalutDialogAddDocument .e-footer-content .e-control.e-btn.e-primary {
  background: #fc4c01 !important;
  color: #ffffff !important;
}

#defalutDialogAddDocument .e-footer-content .e-control.e-btn.e-primary:hover {
  box-shadow: 0px 5px 4px 0px #fc4c0152;
  background: #fc4c01 !important;
  color: #ffffff !important;
}

/* edit identity row */
.edit-identity-container {
  background: #fff9f6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.1rem;
  border-radius: 4px !important;
}
.edit-identity-container .edit-identity-row {
  display: flex;
  justify-items: center;
}
.edit-identity-row span {
  font-size: 0.875rem;
}
.edit-identity-row .fa-pen,
.edit-identity-row .fa-trash {
  color: #535b86;
  font-size: 1rem;
  cursor: pointer;
}
.edit-identity-row .fa-pen:hover,
.edit-identity-row .fa-trash:hover {
  color: #fc4c02;
}
.edit-identity-row .fa-image {
  color: #fc4c02;
  font-size: 1rem;
}
