/* Dialog Container */
#field-peoplecontainer {
  padding: 15px;
  margin: 5px auto;
}

/* Selected Address Type */
#field-peoplecontainer .field-peopleselected-type {
  margin: 10px 0 20px;
  font-size: 14px;
  text-align: right;
}

#field-peoplecontainer .field-peopleaddress-type {
  color: #fc4c02;
  margin-left: 10px;
}

/* Form Styling */
#field-peoplecontainer .field-peopleaddress-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

#field-peoplecontainer .field-peoplerow {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 5px;
}

#field-peoplecontainer .field-peopleform-group {
  display: flex;
  flex-direction: column;
  width: 99%;
  margin: auto;
}

#field-peoplecontainer .field-peoplefull-width {
  width: 100%;
}

#field-peoplecontainer .e-input,
#field-peoplecontainer .e-dropdownlist {
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
  padding: 8px 0;
}

/* Footer Buttons */
#field-peoplecontainer .field-peoplefooter-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}

#field-peoplecontainer .field-peopleback-button {
  background-color: #fefefe;
  border: 1px solid #fc4c02;
  color: #fc4c02;
  padding: 12px 20px;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 10px;
}

#field-peoplecontainer .field-peoplecancel-button {
  background-color: transparent;
  color: #333;
  padding: 14px 18px;
  border-radius: 20px;
  cursor: pointer;
  border: none;
  margin-right: 10px;
}
#field-peoplecontainer .field-peoplecancel-button:hover {
  background-color: #ddd;
}

#field-peoplecontainer .field-peoplesave-button {
  background-color: #fc4c02;
  border: none;
  color: #fff;
  padding: 13px 24px;
  border-radius: 20px;
  cursor: pointer;
}
#field-peoplecontainer .e-float-input:not(.e-input-group),
#field-peoplecontainer .e-float-input.e-control-wrapper:not(.e-input-group) {
  margin-top: 1.1rem !important;
}
#Dialog-Fields .e-dlg-header-content {
  padding: 25px 5px !important;
  border-bottom: 1px solid #e5dede !important;
  margin: 0px 28px !important;
  font-size: 20px !important;
  border-radius: 0px !important;
}
#Dialog-Fields .dlg-headerIcon {
  font-size: 22px;
  color: #535b86;
  position: relative;
  top: 2px;
  margin-right: 8px;
}
#Dialog-Fields .e-btn .e-btn-icon.e-icon-dlg-close {
  font-size: 18px !important;
  width: 36px !important;
  color: #535b86;
}
#Dialog-Fields .e-dlg-content {
  padding: 12px 36px 10px 36px !important;
}
.AtLeastOne {
  color: red;
  font-size: 14px;
  box-sizing: border-box;
}
.fieldDialog-headerTitle {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#field-peoplecontainer #lblInvalidMobile,
#field-peoplecontainer #lblInvalidEmail {
  position: absolute;
  bottom: -0.75rem;
  left: 0px;
  color: #fc4c02;
}
