.divAllocation .cancel-button {
  background-color: transparent;
  color: #333;
  padding: 14px 18px;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  margin-right: 10px;
}

.divAllocation .save-button {
  background-color: #fc4c02;
  border: none;
  color: #fff;
  padding: 13px 24px;
  border-radius: 50px;
  cursor: pointer;
}
