table.calendar {
	border-collapse: collapse;
	font-family: 'Roboto', sans-serif;
	width: 100%;
	user-select: none;
	overflow: auto;
}

table.calendar td.day-number {
	text-align: center;
	cursor: default;
	border-left: none;
	font-family: 'Roboto', sans-serif;
	font-size: 14px !important;
	width: 9% !important;
}

table.calendar td.gray-day {
	color: gray !important;
	cursor: pointer;
}

table.calendar td.holiday-number {
	text-align: center;
	cursor: default;
	border-left: none;
	font-family: 'Roboto', sans-serif;
	font-size: 14px !important;
	font-weight: bold !important;
	color: red !important;
}

table.calendar thead {
	background-color: #eee;
	color: hsl(0, 0%, 0%);
	margin-bottom: 3px;
	border-bottom: 2px solid white;
	text-align: center;
}

table.calendar thead th {
	font-weight: normal;
	padding: 8px;
	background: #fff;
	color: #363636;
	font-size: 14px !important;
	border: 1px solid #e9e9f2;
	border-top: none;
	border-bottom: 2px solid #e9e9f2
}

/* table.calendar thead th.bolder {
    font-weight: bold;
} 

table.calendar tbody {
	font-family: 'Roboto', sans-serif;
}
*/

table.calendar td {
	text-align: center;
    /* padding: 10px; */
    /* width: 67px; */
    height: 34px;
    cursor: pointer;
    border: 1px solid #e9e9f2;
    background-color: white;
    font-family: 'Roboto', sans-serif;
}

table.calendar tr:last-child td {
	border-bottom: none;
}

table.calendar td.month-name {
	/* font-weight: bold; */
	text-align: left;
	cursor: default;
	border-left: none;
	font-size: 14px !important;
	width: 10%;
	font-family: 'Roboto', sans-serif;
}

table.calendar td.prev-month,
table.calendar td.next-month {
	color: transparent !important;
	cursor: default;
	pointer-events: none;
	user-select: none;
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=");
}

table.calendar td.week-separator {
	pointer-events: none;
	padding: 0;
	width: 5% !important;
	min-width: 0;
	background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAABZJREFUCB1jYEADmzdv/o8iRA0BoIEAKngPeSAlnXcAAAAASUVORK5CYII=");
}

/* table.calendar td.bolder {
    font-weight: bold;
} */

table.calendar td.weekendcolor {
	/* background-color: rgb(251, 224, 184); */
	background-color: #F3F4F9;
}

table.calendar td.saturdaycolor {
	/* background-color: rgb(224, 221, 221); */
	background-color: #F3F4F9;
}

table.calendar td.selected {
	background-color: rgba(246, 78, 17, 0.52);
	color: white;
	border-radius: 50%;
}

table.calendar td.range {
	background-color: rgb(81, 163, 230) !important;
	/* font-weight: bold; */
	color: white;
}

table.calendar td.range-left {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
	overflow: hidden;
	background: steelblue;
}

table.calendar td.range-right {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
	overflow: hidden;
	background: steelblue;
}

div.calendar-controls {
	margin: 5px auto;
	display: table;
	font-size: 16px;
	line-height: 35px;
}

div.calendar-controls div {
	display: inline;
}

div.calendar-controls .current-year {
	margin: 0 30px;
}

div.calendar-controls .control {
	/* font-weight: bolder; */
	color: #323232;
	font-size: 14px;
	cursor: pointer;
}

div.calendar-controls .today {
	position: absolute;
	right: 15px;
	line-height: 35px;
	font-size: 14px;
	text-transform: uppercase;
}

div#calendar {
	position: relative;
	border-radius: 5px;
	border: 1px solid #5a5a5a;
	background-color: white;
	overflow: hidden;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* h2,
h3,
h4,
h5 {
	text-align: center;
	color: #404040;
}

h1 {
	text-align: center;
	color: #b10909;
}
 */
div.options {
	border: 1px solid #b9b9b9;
	border-radius: 5px;
	padding: 10px 15px;
	margin-top: 30px;
}

div.options select {
	margin-left: 10px;
}

.calendar-content {}