/* Dialog Container */
#field-container {
  padding: 15px;
  margin: 5px auto;
}

/* Form Styling */
#field-container .field-legal-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 63%;
}

#field-container .field-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

#field-container .field-form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#field-container .field-full-width {
  width: 100%;
}

#field-container .field-save-button {
  background-color: #fc4c02;
  border: none;
  color: #fff;
  padding: 13px 24px;
  border-radius: 20px;
  cursor: pointer;
}
