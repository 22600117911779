/* grid */
#identityGridComponent .e-content {
  height: calc(100vh - 620px) !important;
  overflow: auto;
  scrollbar-width: thin;
}

/* #dataviewer-tabcomponent .e-content {
  height: calc(131vh - 620px) !important;
  overflow: auto;
  scrollbar-width: thin;
} */
#Dialog-Document {
  height: calc(100vh - 375px) !important;
  scrollbar-width: thin;
  padding-left: 15px !important;
  overflow: auto !important;
}
/* grid list design */
.identity-dlg-btn {
  border: 2px solid #fc4c02;
  padding: 8px 12px;
  color: #fc4c02;
  border-radius: 20px;
  line-height: 20px;
  box-shadow: none !important;
  text-transform: capitalize;
  background: #fefefe;
  letter-spacing: 1px;
  margin-bottom: 10px;
  box-shadow: none;
  transition: background 0.3s ease;
  font-size: 15px;
}
.e-btn:active {
  border: 1px solid #fc4c02 !important;
  background-color: #fefefe !important;
  color: #fc4c02 !important;
}
.identity-dlg-btn:hover {
  background: #fc4c02;
  color: #fefefe !important;
}
.identity-dlg-btn-addbtn {
  margin-right: 10px;
  color: #fc4c02;
}
.identity-dlg-btn:hover .identity-dlg-btn-addbtn {
  color: #fefefe !important;
}
#identityGridComponent .e-gridheader {
  border-bottom-color: #bdb7b7 !important;
  border-top-color: #fefefe !important;
  font-size: 16px !important;
  color: #fc4c02 !important;
  /* padding: 5px !important; */
}
#identityGridComponent {
  border-color: #fefefe !important;
}
#identityGridComponent .e-headercelldiv {
  font-size: 15px !important;
}
#identityGridComponent .e-rowcell {
  line-height: 40px;
  font-size: 14px;
}
#identityGridComponent .img-temp {
  color: #fc4c02 !important;
  font-size: 20px;
}
#identityGridComponent th.e-headercell[aria-sort='ascending'] .e-headertext,
#identityGridComponent th.e-headercell[aria-sort='descending'] .e-headertext {
  color: #fc4c02 !important;
}
#identityGridComponent
  .e-gridheader
  .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop):not(
    .e-icon-reorderuparrow
  ):not(.e-icon-reorderdownarrow) {
  color: #fc4c02;
}
/* Dialog Design */
#viewdialog .identity-container {
  padding: 20px;
}
/* add-edit dialog */
#Dialog-Document .form-row {
  display: flex;
  gap: 20px;
  margin: 20px 12px;
}

#Dialog-Document .form-group {
  flex: 1;
}

#Dialog-Document .upload-section {
  border: 1px dashed #ccc;
  text-align: left;
  padding: 30px;
  border-radius: 8px;
  position: relative;
  background: #fbeee84d;
  width: 63%;
}

#Dialog-Document .upload-header {
  font-size: 14px;
  font-weight: 600;
  color: #706f6f;
  margin-left: 50px;
  margin-top: -30px;
}

#Dialog-Document .upload-filename {
  font-size: 14px;
  font-weight: 600;
  color: #fc4c02;
  margin-left: 10px;
}

#Dialog-Document .upload-drop-area {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  cursor: pointer;
}

#Dialog-Document .upload-text {
  color: #666;
  font-size: 14px;
}

#Dialog-Document .browse-link {
  color: #007bff;
  font-weight: 600;
  cursor: pointer;
}

#Dialog-Document .upload-info {
  font-size: 13px;
  color: #999;
  /* margin-top: 10px; */
  margin-left: 50px;
}

#Dialog-Document .footer-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 25px;
}

#Dialog-Document .cancel-button {
  background-color: transparent;
  color: #333;
  padding: 14px 18px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
}
#Dialog-Document .cancel-button:hover {
  background-color: #ddd;
}
#Dialog-Document .save-button {
  background-color: #fc4c02;
  border: none;
  color: #fff;
  padding: 13px 24px;
  border-radius: 20px;
  cursor: pointer;
}

#Dialog-Document .save-button:hover {
  background-color: #e64a19;
}

#Dialog-Document .cancel-button:hover {
  border-color: #bbb;
}

/* show image dialog */
.showDoc-dialog {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 560px);
}
/* alt img */
.IdPictureImg {
  border: 2px solid #cfc9c9;
  background: #37343424;
  border-radius: 10px;
  width: 375px;
}
/* command template */
.command-identity {
  display: flex;
  align-items: center;
  gap: 10px;
}
.identity-command-btn {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
#Dialog-Document .e-dlg-header-content {
  padding: 25px 5px !important;
  border-bottom: 1px solid #e5dede !important;
  margin: 0px 28px !important;
  font-size: 20px !important;
  border-radius: 0px !important;
}
#Dialog-Document .dlg-headerIcon {
  font-size: 22px;
  color: #535b86;
  position: relative;
  top: 2px;
  margin-right: 8px;
}
#Dialog-Document .e-btn .e-btn-icon.e-icon-dlg-close {
  font-size: 18px !important;
  width: 36px !important;
  color: #535b86;
}
#Dialog-Document .e-dlg-content {
  padding: 12px 36px 25px 36px !important;
  height: 727px;
}

#Dialog-Document .upload-icon {
  font-size: 27px;
  color: #c8cddd;
  /* position: relative;
  top: 2px;
  margin-right: 8px; */
  /* margin-top: 50px; */
}

#pdfviewer-dialog .pdf-section {
  width: 100%;
  height: 43rem;
  border: 0.5rem solid #E9E9F2;
}

#pdfviewer-dialog .e-dlg-content {
  padding: 12px 36px 25px 36px !important;
  height: 100rem;
}

#Dialog-Document .document-identity {
  display: flex;
  align-items: center;
  gap: 10px;
  /* margin-left: 775px; */
  margin-left: calc(90% + 0px);
  margin-top: -20px;
  font-size: 20px;
}
#Dialog-Document .document-command-btn {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
#Dialog-Document .upload-documentname {
  padding: 25px 0px 15px 0px !important;
  font-size: 15px;
}

#pdfviewer-dialog .begin-buttons {
  display: flex;
  gap: 10px;
}

#pdfviewer-dialog .dialog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 710px;
  margin: auto;
}
