/* Suhail - layout fix - 20250203 - start */
#attendance #Tooltip {
  height: calc(100% - 44px);
}
#attendance #Tooltip .e-grid {
  height: 100%;
}
#attendance #Tooltip .e-grid .e-gridcontent {
  height: calc(100% - 137px) !important;
}
/* Suhail - layout fix - 20250203 - end */
#Timesheet {
  border: none;
}

#Timesheet .e-columnheader {
  display: none;
}

#GrdTimesheet .e-gridcontent .e-content {
  padding: 0px !important;
}

#Timesheet .e-gridheader.e-droppable {
  border: none;
}

#Timesheet .e-gridcontent .e-content table tbody tr {
  height: 65px;
}

#Timesheet
  .e-gridcontent
  .e-content
  table
  tbody
  tr
  td
  div.e-float-input.e-control-wrapper {
  margin-top: 0px !important;
}

#Timesheet
  .e-gridcontent
  .e-content
  table
  tbody
  tr
  td.e-rowcell.e-templatecell {
  padding-bottom: 0px !important;
  border: none;
}

#Timesheet #Timesheet_toolbarItems .e-toolbar-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#GrdTimesheet
  .e-gridcontent
  .e-content
  table
  tbody
  tr
  td.e-rowcell.e-templatecell
  .InTimeData,
#GrdTimesheet
  .e-gridcontent
  .e-content
  table
  tbody
  tr
  td.e-rowcell.e-templatecell
  .OutTimeData {
  display: flex;
  justify-content: center;
}

#btnDelete:hover {
  color: #fc4c01;
}

#GrdTimesheet
  .e-gridcontent
  .e-content
  table
  tbody
  tr
  td.e-rowcell.e-templatecell
  .InTimeData
  > div,
#GrdTimesheet
  .e-gridcontent
  .e-content
  table
  tbody
  tr
  td.e-rowcell.e-templatecell
  .OutTimeData
  > div {
  width: 75px;
  height: 24px;
  padding: 2px 10px;
  border: 1px solid #fc4c01;
  border-radius: 50px;
  background: #fff9f6;
  color: #fc4c01;
}

#GrdTimesheet .e-gridheader .e-headercontent .e-columnheader .inTimeTemplate,
#GrdTimesheet .e-gridheader .e-headercontent .e-columnheader .outTimeTemplate,
#GrdTimesheet .e-gridheader .e-headercontent .e-columnheader .totalWorkTemplate,
#GrdTimesheet .e-gridheader .e-headercontent .e-columnheader .breakTimeTemplate,
#GrdTimesheet
  .e-gridheader
  .e-headercontent
  .e-columnheader
  .shortTimeTemplate {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

#GrdTimesheet_toolbarItems {
  /* border-top: 1px solid #e0e0e0; */
  background-color: #ffffff;
}

#attendance .e-toolbar-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

#attendance .e-toolbar-items .toolbar-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* .e-datepicker .e-calendar div.e-header.e-year {
    display: none;
}

#attendance .e-toolbar-items .PeriodSelector .monthDatepickerTemplate,
#attendance .e-toolbar-items .PeriodSelector .yearDatepickerTemplate {
    width: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

#attendance .e-toolbar-items .PeriodSelector .monthDatepickerTemplate span.e-input-group.e-date-wrapper:hover,
#attendance .e-toolbar-items .PeriodSelector .yearDatepickerTemplate span.e-input-group.e-date-wrapper:hover,
#attendance .e-toolbar-items .PeriodSelector .monthDatepickerTemplate span.e-input-group.e-date-wrapper::before,
#attendance .e-toolbar-items .PeriodSelector .yearDatepickerTemplate span.e-input-group.e-date-wrapper::before,
#attendance .e-toolbar-items .PeriodSelector .monthDatepickerTemplate span.e-input-group.e-date-wrapper::after,
#attendance .e-toolbar-items .PeriodSelector .yearDatepickerTemplate span.e-input-group.e-date-wrapper::after {
    color: black !important;
    background: none !important;
}

#attendance .e-toolbar-items .PeriodSelector span.e-input-group.e-date-wrapper {
    width: 75% !important;
    border: none !important;
    margin-top: 5px;
}

#attendance .e-toolbar-items .PeriodSelector span.e-input-group input.e-control.e-datepicker {
    text-align: center;
    cursor: pointer;
}

#attendance .e-toolbar-items .PeriodSelector span.e-date-icon.e-icons {
    display: none;
} */

#attendance .e-toolbar-items .attendance-PeriodSelector {
  display: flex;
  align-items: center;
  margin-left: 0px;
}

#attendance .e-toolbar-items .attendance-PeriodSelector i.fa-calendar {
  cursor: pointer;
  position: relative;
  right: 175px;
  font-size: 16px;
  margin-bottom: 6px;
}

#attendance .e-toolbar-items .attendance-PeriodSelector i.fa-chevron-down {
  cursor: pointer;
  position: relative;
  right: 62px;
  font-size: 15px;
  margin-bottom: 7px;
}

#attendance
  .e-toolbar-items
  .attendance-PeriodSelector
  span.e-input-group.e-date-wrapper,
#attendance
  .e-toolbar-items
  .attendance-PeriodSelector
  span.e-input-group.e-date-wrapper
  input.e-datepicker.e-input {
  display: flex;
  flex-direction: row-reverse;
  margin-left: 15px;
  gap: 5px;
  border: none !important;
  width: 100% !important;
  font-weight: 500;
  cursor: pointer;
}

#attendance
  .e-toolbar-items
  .attendance-PeriodSelector
  span.e-input-group.e-date-wrapper
  span.e-date-icon.e-icons {
  /* margin-bottom: 2px !important; */
  display: none;
}

#attendance
  .e-toolbar-items
  .attendance-PeriodSelector
  span.e-input-group.e-date-wrapper::before,
#attendance
  .e-toolbar-items
  .attendance-PeriodSelector
  span.e-input-group.e-date-wrapper::after,
#attendance
  .e-toolbar-items
  .attendance-PeriodSelector
  span.e-input-group.e-date-wrapper:hover
  input,
#attendance
  .e-toolbar-items
  .attendance-PeriodSelector
  span.e-input-group.e-date-wrapper.e-input-focus
  input,
#attendance
  .e-toolbar-items
  .attendance-PeriodSelector
  span.e-input-group.e-date-wrapper:hover
  ~ i.fa-chevron-down,
#attendance
  .e-toolbar-items
  .attendance-PeriodSelector
  span.e-input-group.e-date-wrapper.e-input-focus
  ~ i.fa-chevron-down {
  color: #fc4c01 !important;
  background: none !important;
}

#attendance
  .e-toolbar-items
  .attendance-PeriodSelector
  span.e-input-group.e-date-wrapper
  span.e-input-group-icon.e-date-icon.e-icons.e-active {
  color: #363636;
}

#GrdTimesheet_toolbarItems .e-toolbar-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

#GrdTimesheet_toolbarItems .e-toolbar-items .toolbar-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.e-grid .e-headercell.customHeaderCss {
  font-weight: bolder !important;
}

.e-grid .e-headercell.customHeaderCss .e-headertext {
  font-weight: bolder !important;
}

.attendance-PeriodSelector {
  margin-left: 15px;
  float: left !important;
}

.DataDisplaySelector {
  margin-left: 15px;
  float: left !important;
}

/* .table-container {    
    height: calc(100vh - 255px);
}

.table-container {
    padding: 0.1em;
} */

/* .text-center {
    border-bottom: 1px solid black;
}

.content-c-align {
    text-align: center !important;
}

.itable {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    user-select: none;
}

.itable {
    border-spacing: 0em;
}

.itable .thead {
    flex: 0 0 auto;
    width: calc(100% - 1.2em);
}

@supports (-webkit-appearance: none) and (not (overflow: -webkit-marquee)) and (not (-ms-ime-align: auto)) and (not (-moz-appearance: none)) {
    .itable .thead {
        flex: 0 0 auto;
        width: calc(100% - 1.4em);
    }
}

.blank-space {
    height: 3px;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
}

.itable .tbody {
    flex: 1 1 auto;
    display: block;
    overflow-y: scroll;
}

.itable .tbody .tr {
    width: 100%;
}

@-moz-document url-prefix() {
    .itable .tbody .tr {
        width: calc(100% - 1.2em);
    }
}

.itable .thead,
.itable .tbody .tr {
    display: table;
    table-layout: fixed;
}

.itable th {
    padding: 0.1em;
    padding: 6px !important;
    border: 1px solid black;
    text-align: center !important;
    font-weight: normal !important;
}

.itable tr {
    padding: 0.1em;
}

.itable td {
    padding: 6px !important;
    padding: 0.1em;
    border: 1px solid rgb(85, 85, 85);
}

.itable .td1 {
    width: 18.7%;
}

.itable .td2 {
    width: 7.5%;
    border-right: 1px solid black;
}

.itable .td3 {
    width: 7.5%;
    border-bottom: 1px solid black;
}

.itable .td4 {
    width: 36%;
    border-bottom: 1px solid black;
}

.itable .td5 {
    width: 34%;
    border-bottom: 1px solid darkgrey;
}

.itable .td6 {
    width: 6%;
    border-right: 1px solid darkgray;
}

.itable .td7 {
    width: 6%;
    border-right: 1px solid black;
}

.itable .td8 {
    width: 6%;
    border-right: 1px solid black;
}

.itable .td9 {
    width: 6%;
    border-right: 1px solid black;
}

.itable .td10 {
    width: 17%;
    text-align: right;
    border-right: 1px solid darkgray;
}

.itable .td11 {
    width: 17%;
    text-align: right;
    border-right: 1px solid black;
}

.itable .bodytd1 {
    width: 4%;
    text-align: center !important;
    border-right: 1px solid darkgray;
}

.itable .bodytd2 {
    width: 4.5%;
    text-align: left;
    border-right: 1px solid black;
}

.itable .bodytd3 {
    width: 24%;
    text-align: center !important;
    border-right: 1px solid black;
}

.itable .bodytd4 {
    width: 5%;
    text-align: center !important;
    cursor: pointer;
    user-select: none;
    border-right: 1px solid black;
}

.itable .bodytd5 {
    width: 6%;
    text-align: center;
    cursor: pointer;
    user-select: none;
    border-right: 1px solid darkgray;
}

.itable .bodytd6 {
    width: 6%;
    text-align: center;
    border-right: 1px solid darkgray;
}

.itable .bodytd7 {
    width: 6%;
    text-align: center;
    border-right: 1px solid darkgray;
}

.itable .bodytd8 {
    width: 6%;
    text-align: center;
    border-right: 1px solid black;
}

.itable .bodytd9 {
    width: 6%;
    text-align: center;
    border-right: 1px solid darkgray;
}

.itable .bodytd10 {
    width: 6%;
    text-align: center;
    border-right: 1px solid black;
}

.summary {
    height: 45px !important;
    width: 98.7% !important;

    @-moz-document url-prefix() {
            {
            width: calc(100% - 1.2em) !important;
        }
    }

    border: 1px solid black !important;
    margin-top: 2px !important;
}

@supports (-webkit-appearance: none) and (not (overflow: -webkit-marquee)) and (not (-ms-ime-align: auto)) and (not (-moz-appearance: none)) {
    .summary {
        height: 45px !important;
        width: calc(100% - 1.45em) !important;

        @-moz-document url-prefix() {
                {
                width: calc(100% - 1.2em) !important;
            }
        }

        border: 1px solid black !important;
        margin-top: 2px !important;
    }
}

.summary-table {
    height: 100%;
    width: 100%;
}

.summary-td1 {
    width: 22.5%;
    text-align: right;
    font-weight: 600;
    padding-right: 5px;
    background-color: #faf0e6b5;
    border-right: 1px solid black;
}

.summary-td2 {
    width: 6%;
    text-align: center;
    border-right: 1px solid darkgray;
}

.summary-td3 {
    width: 6%;
    text-align: center;
    border-right: 1px solid darkgray;
}

.summary-td4 {
    width: 6%;
    text-align: center;
    border-right: 1px solid darkgray;
}

.summary-td5 {
    width: 6%;
    text-align: center;
}

.summary-td6 {
    width: 6%;
    text-align: center;
    border-right: 1px solid darkgray;
}

.summary-td7 {
    width: 6%;
    text-align: center;
    border-right: 1px solid darkgray;
} */

#TimeAdd:hover,
#TimeEdit:hover,
#TimeDelete:hover {
  color: #fc4c02;
}

#attendance .e-grid .e-gridcontent .e-content {
  overflow-y: auto !important;
  scrollbar-width: thin !important;
  margin-top: 0px !important;
}

#attendance .e-grid .e-gridheader {
  padding-right: 0px !important;
  color: #fc4c01 !important;
}

#attendance .e-grid .e-footerpadding {
  padding-right: 0px !important;
}

#attendance .e-grid .e-summaryrow .e-summarycell {
  background-color: #fa8858;
  color: white !important;
  font-weight: 500 !important;
  text-align: right !important;
}

#attendance .e-grid .e-summarycell {
  padding-right: 7px !important;
}

.e-datepicker.e-custom-popup
  .e-calendar
  .e-footer-container
  button.e-btn.e-primary {
  border: 2px solid #fc4c01;
  border-radius: 50px;
}

.e-datepicker.e-custom-popup
  .e-calendar
  .e-footer-container
  button.e-btn.e-primary:hover {
  background: #fc4c01;
  color: #ffffff;
}

#defaultDialogAttendance .e-dlg-header-content {
  border-bottom: 1px solid #e9e9e9 !important;
  margin: 0px 28px !important;
  border-radius: 0px !important;
}

#defaultDialogAttendance .e-dlg-content {
  padding: 19px 36px !important;
}

#defaultDialogAttendance .e-dlg-header-content .e-dlg-header {
  margin-top: 3px;
}

#defaultDialogAttendance
  .e-dlg-header-content
  .e-dlg-header
  .attendanceHeaderText {
  display: flex;
  align-items: center;
  gap: 10px;
}

#defaultDialogAttendance
  .e-dlg-header-content
  .e-dlg-header
  .attendanceHeaderText
  .dlg-AttendanceheaderIcon {
  font-size: 22px;
  color: #535b86;
}

#defaultDialogAttendance .e-footer-content .e-btn:active {
  border: none !important;
}

#defaultDialogAttendance .e-footer-content .e-control.e-btn {
  height: 40px !important;
  border-radius: 50px;
  padding: 12px 20px !important;
  opacity: 1 !important;
  color: #000000 !important;
}

#defaultDialogAttendance .e-footer-content .e-control.e-btn:hover {
  background: #e9e9f2 !important;
  color: #000000 !important;
}

#defaultDialogAttendance .e-footer-content .e-control.e-btn.e-primary {
  background: #fc4c01 !important;
  color: #ffffff !important;
}

#defaultDialogAttendance .e-footer-content .e-control.e-btn.e-primary:hover {
  box-shadow: 0px 5px 4px 0px #fc4c0152;
  background: #fc4c01 !important;
  color: #ffffff !important;
}

#defaultDialogAttendance
  .e-dlg-content
  #Timesheet
  .e-gridcontent
  .e-content
  tr
  td
  div.customTimeCss:hover {
  border-bottom: 1px solid #fc4c01 !important;
}

#defaultDialogAttendance
  .e-dlg-content
  #Timesheet
  .e-gridcontent
  .e-content
  tr:hover
  td {
  background: #ffffff !important;
}

#defaultDialogAttendance
  .e-dlg-content
  #Timesheet
  .e-gridcontent
  .e-content
  td.e-active {
  background: #ffffff !important;
}

#cntxAttendanceMenu li.e-menu-item.e-focused {
  background-color: #fbf4ee;
  color: #fc4c02;
}

#cntxAttendanceMenu li.e-menu-item.e-focused span.e-menu-icon.e-icons {
  color: #fc4c02 !important;
}

.custom-contextmenu .e-contextmenu {
  width: 10rem !important;
  box-shadow: 0px 4px 4px 0px #00000040 !important;
}

.custom-contextmenu
  .e-contextmenu.e-menu-parent
  .e-menu-item:hover
  span.e-icons.e-caret {
  color: #fc4c02 !important;
}

.custom-contextmenu
  .e-contextmenu.e-menu-parent
  > li.e-menu-item.e-menu-caret-icon {
  border-bottom: 1px solid #f3f4f9 !important;
}

.custom-contextmenu ul.e-menu-parent.e-ul .e-menu-item {
  border-bottom: 1px solid #f3f4f9;
}

.custom-contextmenu ul.e-menu-parent.e-ul {
  width: 142px !important;
}

@media screen and (max-width: 1460px) {
  #defaultDialogAttendance {
    width: 55vw !important;
  }
}

@media screen and (max-width: 1240px) {
  #defaultDialogAttendance {
    width: 65vw !important;
  }
}

@media screen and (max-width: 1120px) {
  #defaultDialogAttendance {
    width: 80vw !important;
  }
}

@media screen and (max-width: 1024px) {
  #defaultDialogAttendance {
    width: 85vw !important;
  }
}

@media screen and (max-width: 768px) {
  #defaultDialogAttendance {
    width: 90vw !important;
  }
}
