/* #divCompanyList #divcompanylist-grid {
  width: 25%;
} */

#divCompanyList .companylist-treegrid .e-treecolumn-container .e-none {
  display: inline-block !important;
  width: 15px !important;
}

#divCompanyList .companylist-treegrid .e-grid .e-gridheader {
  padding-right: 0px !important;
}
.company-header {
  padding: 12px 14px;
  letter-spacing: 1.5px;
  font-weight: 600;
  font-size: 20px;
  /* background: linear-gradient(
    to right,
    #615e5d0a 0%,
    #a5a3a03c 51%,
    #48464516 100%
  ); */
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  margin: 10px auto;
  margin-bottom: 10px;
}

.e-dialog.defaultDialog-companyaddedit {
  min-width: 460px;
}

@media screen and (max-width: 1460px) {
  .e-dialog.defaultDialog-companyaddedit {
    width: 60vw !important;
  }
}

@media screen and (max-width: 1240px) {
  .e-dialog.defaultDialog-companyaddedit {
    width: 70vw !important;
  }
}

@media screen and (max-width: 1120px) {
  .e-dialog.defaultDialog-companyaddedit {
    width: 80vw !important;
  }
}

@media screen and (max-width: 1024px) {
  .e-dialog.defaultDialog-companyaddedit {
    width: 90vw !important;
  }
}

@media screen and (max-width: 768px) {
  .e-dialog.defaultDialog-companyaddedit {
    width: 98vw !important;
  }
}

.defaultDialog-companyaddedit #defaultDialog-companychange_dialog-content {
  overflow: hidden;
}
