.address-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
/* main height */
.address-listView {
  /* height: calc(100vh - 470px); */
  height: 100%;
  overflow: auto;
  padding: 0px 10px;
  scrollbar-width: thin;
}
#divAddressContent .form-row {
  margin: 20px 10px;
}

.address-temp-row {
  display: flex;
}
.address-temp-box {
  /* width: 255px; */
  width: 20%;
  margin: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.address-container .e-acrdn-item .e-acrdn-panel .e-acrdn-content > * {
  width: 100%;
}
.address-temp-box .e-input-group:not(.e-float-icon-left),
.address-temp-box .e-input-group.e-control-wrapper:not(.e-float-icon-left) {
  border-width: 0 0 0 0 !important;
}

.address-feildIcon {
  color: #535b86;
  width: 30px;
  font-size: 16px;
}
.address-label {
  text-transform: uppercase;
  color: #9b9494;
  letter-spacing: 1px;
}
.address-info {
  width: 100%;
}
/* header address */
.heading-address {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #363636;
}
.heading-address i {
  color: #535b86;
}
.heading-address .add-value {
  font-size: 1rem !important;
  color: #363636 !important;
}
.address-container .e-acrdn-item .e-acrdn-header .e-acrdn-header-content {
  /* width: 90% !important; */
  width: 100% !important;
  padding: 5px;
  font-size: 1rem !important;
}
.address-container input.e-input,
.address-container .e-input-group input.e-input,
.address-container .e-input-group.e-control-wrapper input.e-input {
  padding: 0px !important;
  font-size: 0.875rem;
}

.address-container .e-acrdn-item .e-acrdn-panel {
  box-shadow: 0px 2px 4px #eee;
  border-radius: 0px 0px 8px 8px;
  margin-bottom: 15px;
}
.heading-address .header-city-obj {
  border: 1px solid #fc4c02;
  display: inline-block;
  padding: 0px 10px;
  border-radius: 20px;
  margin: 0px 10px;
  line-height: 26px;
  font-size: 0.875rem;
  color: #fc4c02;
}

.address-container
  .e-acrdn-item
  .e-acrdn-header
  .e-toggle-icon
  .e-tgl-collapse-icon.e-icons {
  display: none !important;
}
.last-address-div {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

/* dialog */
#Dialog-Address .e-dlg-header-content {
  padding: 25px 5px !important;
  border-bottom: 1px solid #e5dede !important;
  margin: 0px 28px !important;
  font-size: 1.2rem !important;
  border-radius: 0px !important;
}
#Dialog-Address .dlg-headerIcon {
  font-size: 22px;
  color: #535b86;
  position: relative;
  top: 2px;
  margin-right: 8px;
}
#Dialog-Address .e-btn .e-btn-icon.e-icon-dlg-close {
  font-size: 18px !important;
  width: 36px !important;
  color: #535b86;
}
#Dialog-Address .e-dlg-content {
  padding: 12px 36px 25px 36px !important;
}
#Dialog-Address .footer-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 25px;
}
#Dialog-Address .cancel-button {
  background-color: transparent;
  color: #333;
  padding: 14px 18px;
  border-radius: 50px;
  cursor: pointer;
  border: none;
}
#Dialog-Address .cancel-button:hover {
  background-color: #ddd;
}
#Dialog-Address .save-button {
  background-color: #fc4c02;
  border: none;
  color: #fff;
  padding: 13px 24px;
  border-radius: 50px;
  cursor: pointer;
}
.demo {
  display: none;
  width: 88px;
  height: 30px;
  position: absolute;
  right: 0;
}
.accordion-header-icons {
  width: 30px;
  height: 15px;
  display: flex;
  margin: 10px 15px;
  justify-content: center;
  align-items: center;
}
.accordion-header-icons .far {
  color: #535b86;
}
.accordion-header-icons .far:hover {
  color: #fc4c02;
}
.accordion-header-icons .fa-pen-to-square {
  margin-left: 20px;
}

.prevNext-Address {
  border: 1px solid;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.prevNext-Address p {
  color: #333;
  margin: 0;
  font-size: 1rem;
  padding: 10px 15px;
  letter-spacing: 1px;
}
.prevNext-Address p:hover {
  color: #fc4c02;
}
.pager-contain {
  width: 275px;
  margin-left: auto;
}
.pager-contain div.e-parentmsgbar {
  display: none !important;
}
/* render Sun Items */
.address-temp-box i {
  margin-right: 15px;
  display: inline-block;
  margin-top: 5px;
}

.address-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.address-label label {
  font-weight: 400;
  font-size: 0.775rem;
}

.address-data {
  width: 100%;
  position: relative;
  /* pointer-events: none; */
}

.address-data #lblInvalidMobile,
.address-data #lblInvalidEmail {
  position: absolute;
  top: 1.375rem;
  left: 0px;
  color: #fc4c02;
}

.address-data-input {
  background: #fff;
  border: none;
  color: #363636 !important;
}
.textbox-empty {
  color: #aaa;
}

.textbox-empty::placeholder {
  color: #bbb;
}

.textbox-filled {
  color: #333;
}
.custom-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.custom-pagination .btn-prev,
.custom-pagination .btn-next {
  background-color: #fff;
  border: none;
  box-shadow: none;
  margin: 0px 3px;
}
.pagerContain .pager-number {
  border: 1px solid #fc4c02;
  color: #fff;
  background: #fc4c02;
  padding: 2px 6px;
  font-size: 0.875rem;
  margin: 0px 8px;
  border-radius: 50px;
}
.address-container .e-pager {
  display: flex !important;
  border-color: #fff !important;
  justify-content: flex-end !important;
}
.address-container .e-pager div.e-parentmsgbar {
  display: none;
}
.address-container .e-pager .e-pagercontainer .e-lastpage,
.address-container .e-pager .e-pagercontainer .e-lastpagedisabled,
.address-container .e-pager .e-pagercontainer .e-firstpagedisabled,
.address-container .e-pager .e-lastpage,
.address-container .e-pager .e-firstpage {
  display: none;
}
.address-container .e-pager .e-currentitem {
  background: #fc4c02 !important;
}

.address-container .textbox-class {
  border-bottom: none !important;
}

.address-container .e-input-group-icon.e-ddl-icon.e-search-icon {
  display: none !important;
}
#defalutDialogAddAddress .e-dlg-header-content {
  border-bottom: 1px solid #e5dede !important;
  margin: 0px 28px !important;
  border-radius: 0px !important;
}

#defalutDialogAddAddress .e-dlg-header-content .e-dlg-header .dlg-headerIcon {
  color: #535b86;
}

#defalutDialogAddAddress .e-dlg-content {
  padding: 19px 36px !important;
}

#defalutDialogAddAddress .e-footer-content .e-control.e-btn {
  height: 42px !important;
  border-radius: 50px;
  padding: 12px 16px !important;
  font-weight: 400 !important;
  opacity: 1 !important;
  color: #363636 !important;
}

#defalutDialogAddAddress .e-footer-content .e-control.e-btn:hover {
  background: #e9e9f2 !important;
  color: #363636 !important;
}

#defalutDialogAddAddress .e-footer-content .e-control.e-btn.e-primary {
  background: #fc4c02 !important;
  color: #ffffff !important;
}

#defalutDialogAddAddress .e-footer-content .e-control.e-btn.e-primary:hover {
  background: #fc4c02 !important;
  color: #ffffff !important;
}

#AddressType_popup .e-list-item {
  line-height: 42px !important;
}

#AddressType_popup .e-list-item:hover {
  background-color: #fbf4ee;
  color: #fc4c02;
}

#AddressType_popup .NewOptionClass {
  padding: 3px 12px 3px 0px !important;
  line-height: 0px !important;
  position: relative;
}

#AddressType_popup .NewOptionClass .newOptIcon {
  font-size: 1rem !important;
  position: absolute;
  left: -10px !important;
  width: 22px !important;
}

#AddressType_popup .NewOptionClass .newOptText {
  padding-left: 12px;
}
/*Expand-Collaspe icon  accordion*/
.heading-address::before {
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 14px;
  margin-right: 8px;
  display: inline-block;
}

.e-acrdn-header[aria-expanded='true'] .heading-address::before {
  content: '\f106';
}
