.selectorpanel-grid .e-grid,
.selectorpanel-grid .e-grid.e-horizontallines .e-rowcell.e-lastrowcell,
.selectorpanel-grid .e-grid.e-verticallines .e-rowcell.e-lastrowcell,
.selectorpanel-grid .e-grid.e-hidelines .e-rowcell.e-lastrowcell {
  /* border-width: 0px; */
  border-color: #fefefe !important;
}

.selectorpanel-grid .e-gridheader {
  /* display: none; */
  border-bottom-color: #e9e9f2 !important;
  border-top-color: #fefefe !important;
  /* font-size: 16px !important; */
  color: #fc4c02 !important;
  /* padding: 5px !important; */
}

#treegrid-selector .e-content {
  padding: unset !important;
  scrollbar-width: thin;
}

#treegrid-selector .e-gridheader {
  display: none !important;
}

.selectorpanel-grid .e-grid .e-headercelldiv {
  color: #fc4c01 !important;
  font-size: 1rem;
  font-weight: 500;
}

.selectorpanel-grid .e-gridcontent td .far {
  font-size: 16px;
}

.selectorpanel-grid .e-rowcell {
  line-height: 40px !important;
  font-size: 0.875rem !important;
  padding: unset !important;
}

.selectorpanel-grid #treegrid-selector .e-rowcell {
  line-height: 20px !important;
  padding: 8px !important;
}

.selectorpanel-grid
  .e-grid
  th.e-headercell[aria-sort='ascending']
  .e-headertext,
.selectorpanel-grid
  .e-grid
  th.e-headercell[aria-sort='descending']
  .e-headertext,
.selectorpanel-grid
  .e-grid
  th.e-headercell[aria-sort='ascending']
  .e-sortfilterdiv,
.selectorpanel-grid
  .e-grid
  th.e-headercell[aria-sort='descending']
  .e-sortfilterdiv {
  color: #fc4c01 !important;
  font-size: 1rem;
  font-weight: 500;
}

.selectorpanel-grid .e-rhandler.e-rcursor {
  display: none;
}

/* ---------------    People Header       ----------------- */
.people-grid-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 13px;
  margin-bottom: 10px;
  border-top: 1px solid rgb(234, 233, 233);
  border-bottom: 1px solid rgb(234, 233, 233);
}

.people-title {
  width: 70%;
  display: flex;
  align-items: center;
}

.people-title i {
  font-size: 1rem;
  margin-right: 6px;
  display: inline-block;
}

.people-title span {
  font-size: 1rem;
  letter-spacing: 1px;
  display: inline-block;
}

.people-flt-div {
  width: 18%;
  border: 2px solid #e9e9f2;
  background: #e9e9f2;
  border-radius: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.people-flt-div .emp-filter {
  display: inline-block;
  padding: 7px 5px;
  color: #535b86;
  cursor: pointer;
}

.people-flt-div .emp-filter:hover {
  color: #fc4c02;
}

.active-peopleList {
  background-color: #fc4c02;
  border-radius: 50px;
  padding: 5px !important;
  font-size: 12px;
  color: #fff !important;
}

/* ---------------    People Search       ----------------- */
#PeopleSearch {
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: #f3f4f9 !important;
  margin-bottom: 10px;
}

#PeopleSearch #selectorsearchmain {
  border: none !important;
  background-color: unset !important;
  width: 80%;
  display: inline-block;
}

.search-people-list .fa-light {
  display: inline-block;
  padding: 5px;
  cursor: pointer;
  color: #535b86;
}

.search-people-list .fa-light:hover {
  color: #fc4c02;
}

/* dlg Css */
#DlgEmpFilter {
  min-width: 115px !important;
  width: 55% !important;
  right: 15px !important;
  max-height: 120px !important;
  left: unset !important;
  top: 115px !important;
  border-radius: 4px !important;
  box-shadow: 0px 0px 10px 0px #b4b4b4 !important;
}

#DlgEmpFilter .e-dlg-content {
  padding: 0px !important;
}

.empFlt p {
  padding: 5px;
  text-align: left;
  margin-bottom: 0px !important;
  /*border-bottom: 1px solid lightgray;
  line-height: 25px !important;*/
}

.empFlt p:hover {
  color: #fc4c02;
}

/* people list design */

.employee-list-grid {
  display: flex;
  padding: 10px;
  position: relative;
  align-items: center;
}

.employee-list-grid .empImg {
  /* width: 3.4375rem;
  height: 3.4375rem; */
  margin: auto 3px auto 0px;
  position: absolute;
  top: 14px;
  left: 0px;
}

.employee-list-grid .empImg img {
  width: 3.4375rem;
  height: 3.4375rem;
  border-radius: 50px;
  border: 2px solid white;
  box-shadow: 2px 2px 10px 2px rgb(219, 219, 219);
  object-fit: cover;
}

.emp-list-details {
  width: 100%;
  padding: 0px 10px;
  padding-left: 55px;
}

.emp-list-details .pe-code {
  color: #fc4c02;
}

.employee-list-grid .emp-list-details span {
  display: block;
  font-size: 13px;
  line-height: 22px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.emp-list-email {
  position: absolute;
  right: 0px;
}

.emp-list-email span {
  font-size: 12px;
}

.delete-emp-icon {
  position: absolute;
  right: 8px;

  display: none;
}

.delete-emp-icon i {
  padding: 5px;
  cursor: pointer;
  color: #555555;
  font-size: 20px;
}

#treegrid-selector.e-gridhover
  tr[role='row']:not(.e-disable-gridhover):not(.e-editedrow):not(
    .e-detailrow
  ):hover
  .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(
    .e-updatedtd
  ):not(.e-indentcell)
  .delete-emp-icon,
#treegrid-selector td.e-active .delete-emp-icon {
  display: block;
}

.highlight:hover {
  color: #fc4c02;
  cursor: pointer;
}

.highlight {
  width: 45px;
  color: #363636 !important;
}

.noneHighLight {
  width: 45px;
  visibility: hidden;
  pointer-events: none;
}

.e-tooltip-wrap.e-popup {
  border-radius: 6px !important;
  padding: 3px !important;
}

.e-tooltip-wrap .e-tip-content {
  font-size: 13px !important;
}

#list-chk {
  height: 38px;
  padding: 4px;
  display: flex;
  align-items: center;
  pointer-events: auto;
}

#list-chk:hover {
  background: #fff9f6;
}

#list-chk:hover .e-checkbox-wrapper .e-icons.e-frame {
  background: #fff9f6 !important;
}

#list-chk:hover .e-checkbox-wrapper .e-label {
  color: #fc4c01 !important;
}

#list-chk .e-checkbox-wrapper .e-frame {
  border: none !important;
}

#list-chk .e-checkbox-wrapper .e-frame.e-check {
  background-color: #fff !important;
  color: #fc4c02 !important;
}

/* grid footer */
.peopleGrid-footer {
  border-top: 1px solid rgb(235, 233, 233);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  width: 95%;
  margin: auto;
  position: relative;
}

.peopleList-Record {
  font-size: 14px;
  color: #373435;
}

.peopleList-Record span {
  color: #fc4c02;
  font-size: 16px;
}

.peopleList-icon {
  width: 50px;
  background: #dddddd;
  color: #535b86;
  border-radius: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  cursor: pointer;
  position: absolute;
  right: 22px;
  bottom: 6px;
  z-index: 2;
}

.peopleList-icon:hover {
  color: #fc4c02;
}

.noneHighLightFilter {
  padding: 12px;
  visibility: hidden;
  pointer-events: none;
}

#DlgNewEmployee {
  min-width: 170px !important;
  width: 10rem !important;
  right: 0px !important;
  max-height: 120px !important;
  left: unset !important;
  top: -98px !important;
  border-radius: 4px !important;
  box-shadow: 0px 0px 10px 0px #b4b4b4 !important;
}

#DlgNewEmployee::after {
  content: '';
  position: absolute;
  right: 15px;
  bottom: -8px;
  width: 15px;
  height: 0px;
  border-style: solid;
  border-width: 0px 10px 10px 10px;
  border-color: transparent transparent #fff transparent;
  z-index: 9999;
  transform: rotate(180deg);
}

#DlgNewEmployee .e-dlg-content {
  padding: 0px !important;
}

.empNewDlg div#listNewEmp {
  text-align: left;
  margin-bottom: 0px !important;
  border-bottom: 1px solid lightgrey;
  background: #fffcfb;
  padding: 15px;
  color: #fc4c02 !important;
}

.empNewDlg div#listImpEmp {
  text-align: left;
  margin-bottom: 0px !important;
  background: #ffffff;
  padding: 15px;
}

#listImpEmp i {
  margin-right: 5px;
}

/* #listImpEmp:hover, */
#listImpEmp:hover {
  color: #fc4c02 !important;
}

#cntxPeopleListMenu.e-contextmenu {
  min-width: 93px !important;
  height: 37px !important;
  border: 2px solid #e9e9f2;
}

#cntxPeopleListMenu.e-contextmenu li.e-menu-item.e-focused {
  background: #ffffff;
  color: #363636;
}

#cntxPeopleListMenu.e-contextmenu li.e-menu-item:hover,
#cntxPeopleListMenu.e-contextmenu li.e-menu-item:hover .e-menu-icon.e-icons {
  background: #ffffff;
  color: #fc4c01;
}
