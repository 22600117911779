.button-group {
  /* margin-bottom: 20px; */
  padding: 10px;
}
.button-group-container {
  /* height: calc(100vh - 400px); */
  height: 100%;
  overflow: auto;
}
.btn-row {
  display: flex;
}
.button-group h3 {
  font-size: 16px;
  display: inline-block;
  margin: 10px;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.button-group i {
  color: #fc4c02;
  font-size: 22px;
  margin-right: 10px;
  display: inline-block;
}

.button-group button.active {
  border: 1px solid #fc4c02;
  color: #fc4c02;
}
.button-group button {
  display: flex;
  align-items: center;
  border: 2px solid #ccc;
  background: #fff;
  padding: 10px 14px;
  margin: 5px;
  cursor: pointer;
  position: relative;
  border-radius: 4px;
  transition: all 0.3s ease;
  border-radius: 50px;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #ccc;
}

.selected {
  border-color: #fc4c02;
  background-color: #fc4c02;
}

.check-icon {
  color: white;
  font-size: 12px;
  position: absolute;
}
